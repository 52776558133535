const Star = ({ fill, scale }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: "1em",
        height: "1em",
        transform: `scale(${scale})`,
        margin: "0.3em",
      }}
    >
      {/* Background star */}
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "lightgray",
          clipPath:
            "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
        }}
      />
      {/* Filled part */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          clipPath:
            "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
        }}
      >
        <div
          style={{
            width: ` ${fill * 100}%`,
            height: "100%",
            background: "gold",
          }}
        />
      </div>
    </div>
  );
};

export default Star;
