import { useEffect, useState } from "react";
import commonStyle from "../../../commonCss.module.css";
import { ICONS } from "../../../Assets/Icons";
import SortByYear from "../Components/SortByYear/SortByYear";
import Styles from "./SalesAnalysis.module.css";
import SalesAnalysisChart from "./SalesAnalysisChart";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";
import { useSelector } from "react-redux";

const SalesAnalysis = () => {

  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  const [selectedYear, setSelectedYear] = useState(2024);
  const [dropdownOption, setDropdownOption] = useState("This year");
  const [fetchedData, setFetchedData] = useState(null);
  const [hoveredInfo, setHoveredInfo] = useState(null);

  const showInformation = (section) => {
    if (hoveredInfo !== null && section !== hoveredInfo) {
      setHoveredInfo(section);
    } else if (hoveredInfo) {
      setHoveredInfo(null);
    } else {
      setHoveredInfo(section);
    }
  };

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const fetchData = async () => {
    try {

      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/sales/by_subCategories/by_year_month_week`,
        {
          year: selectedYear,
          period: dropdownOption.split(" ")[1],
          userId,
          sectorId,
        }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      );

      setFetchedData(() => response.data.data.result);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (userId && sectorId) {
      fetchData();
    }
  }, [selectedYear, dropdownOption, userId, sectorId]);

  return (
    <>
      <SortByYear
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        dropdownOption={dropdownOption}
        setDropdownOption={setDropdownOption}
      />
      <div className={Styles.container}>
        <div className={Styles.left_header_onMobile}>
          <h2>
            Sales by Category &nbsp;
            <span
              onClick={() => showInformation("salesCategory")}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{
                  display: hoveredInfo === "salesCategory" ? "flex" : "none",
                }}
              >
                View total sales breakdown by category.
              </span>
            </span>
          </h2>
        </div>
        <div className={Styles.left_container}>
          <div className={Styles.left_header}>
            <h2>
              Sales by Category &nbsp;
              <span
                onMouseEnter={() => setHoveredInfo("salesCategory")}
                onMouseLeave={() => setHoveredInfo(null)}
                className={commonStyle.expanInfo}
              >
                {ICONS.infoIcon}
                <span
                  className={commonStyle.informationContent}
                  style={{
                    display: hoveredInfo === "salesCategory" ? "flex" : "none",
                  }}
                >
                  View total sales breakdown by category.
                </span>
              </span>
            </h2>
          </div>
          <div className={Styles.left_box}>
            <SalesAnalysisChart data={fetchedData} />
          </div>
        </div>
        <div className={Styles.right_header_onMobile}>
          <h2>
            Top Selling Services{" "}
            <span
              onClick={() => showInformation("sellingServices")}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{
                  display: hoveredInfo === "sellingServices" ? "flex" : "none",
                }}
              >
                View total sales breakdown by category.
              </span>
            </span>
          </h2>
        </div>
        <div className={Styles.right_container}>
          <div className={Styles.right_header}>
            <h2>
              Top Selling Services &nbsp;
              <span
                onMouseEnter={() => setHoveredInfo("sellingServices")}
                onMouseLeave={() => setHoveredInfo(null)}
                className={commonStyle.expanInfo}
              >
                {ICONS.infoIcon}
                <span
                  className={commonStyle.informationContent}
                  style={{
                    display:
                      hoveredInfo === "sellingServices" ? "flex" : "none",
                  }}
                >
                  View the top-selling services overall in your sector.
                </span>
              </span>
            </h2>
          </div>
          {fetchedData?.length > 0 ? (
            fetchedData?.map((item) => (
              <div key={item.subCategory} className={Styles.right_box}>
                <p className={Styles.right_box_name}>{item.subCategory}</p>
                <div className={Styles.right_box_data}>
                  {item.currentPeriodSales - item.previousPeriodSales < 0
                    ? ICONS.redVector
                    : ICONS.greenVector}
                  <p>
                    ₹ {item.currentPeriodSales}
                    <span
                      className={
                        item.currentPeriodSales - item.previousPeriodSales >= 0
                          ? Styles.positive
                          : Styles.negative
                      }
                    >
                      {item.currentPeriodSales - item.previousPeriodSales >= 0
                        ? "↑"
                        : "↓"}
                    </span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className={Styles.emptybox}>No sales yet !</div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesAnalysis;
