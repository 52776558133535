import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import NavigationBar from "./NavigationBar/NavigationBar";
import styles from "./ServiceProviderDashboard.module.css";
import Dashboard from "./Home/Home";
import Orders from "./Orders/Orders";
import Messages from "./Messages/Messages";
import HelpAndSupport from "./Support/HelpAndSupport";
import RaiseTicket from "./Support/Components/RaiseTicketPage/RaiseTicket";
import Notifiactions from "./Notifiactions/Notifiactions";
import Settings from "./Profile_Settings/Settings";
import MobileProfile from "./Mobile_Profile/Mobile_Profile";
import ServiceManagement from './ServiceManagement/ServiceManagement';
import AddService from "./ServiceManagement/Sections/AddService/AddService";
import ProtectedRoute from './ProtectedRoute'; // import your ProtectedRoute component
import MobileBar from "./NavigationBar/MobileBar/MobileBar";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from "../store";
import axios2 from "../axios2";

const ServiceProviderDashboard = () => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const dispatch = useDispatch();

  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  const state = useSelector((state) => state.auth);
  console.log(state, 'state')

  localStorage.setItem('state', JSON.stringify(state))

  const fetchCurrSectorInfo = async () => {
    try {
      const res = await axios2.post(`${baseUrl}/api/v1/sectors/user-category`, {
        userId
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      console.log(res, 'res');
      localStorage.setItem('authDetails', res.data.data.userCategoryMapping[0]);
      dispatch(authActions.setCurrSectorId(res.data.data.userCategoryMapping[0].sectorId));
      dispatch(authActions.setCurrCategoryId(res.data.data.userCategoryMapping[0].categoryId));
      dispatch(authActions.setCurrCategoryName(res.data.data.userCategoryMapping[0].category.categoryName));

    } catch (error) {
      console.log("Error fetching current Sector info :", error);
    }
  }

  const fetchUserNumber = async () => {
    try {
      const res = await axios2.post(`${baseUrl}/api/v1/profile/me`, {
        userId
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      dispatch(authActions.setUserMobileNumber(res.data.data.user.mobileNumber));
      dispatch(authActions.setUserType(res.data.data.user.userType));
    } catch (error) {
      console.log("Error fetching user mobile number :", error);
    }
  }

  useEffect(() => {
    fetchCurrSectorInfo();
    fetchUserNumber();
  }, []);

  return (
    <div className={styles.dashboard}>
      <NavigationBar />
      <MobileBar />
      <div className={styles.right_section}>
        <Routes>
          <Route path="/" element={<Navigate to="home" replace />} />
          <Route path="home" element={<ProtectedRoute element={Dashboard} />} />
          <Route path="order" element={<ProtectedRoute element={Orders} />} />
          <Route path="message" element={<ProtectedRoute element={Messages} />} />
          <Route path="support" element={<ProtectedRoute element={HelpAndSupport} />} />
          <Route path="serviceManagement" element={<ProtectedRoute element={ServiceManagement} />} />
          <Route path="addService" element={<ProtectedRoute element={AddService} />} />
          <Route path="allTickets" element={<ProtectedRoute element={RaiseTicket} />} />
          <Route path="notification" element={<ProtectedRoute element={Notifiactions} />} />
          <Route path="settings/*" element={<ProtectedRoute element={Settings} />} />
          <Route path="mobileProfile/*" element={<ProtectedRoute element={MobileProfile} />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export default ServiceProviderDashboard;
