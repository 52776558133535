import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Chart as ChartJS, registerables } from "chart.js";
import axios2 from "../../../../axios2";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

ChartJS.register(...registerables);

const MyMixedChart = ({
  selectedYear,
  dropdownOption,
  setCurrentMonthData,
}) => {
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  const [fetchedData, setFetchedData] = useState(null);
  const [suggestedMaxY, setSuggestedMaxY] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const fetchMonthAvgComparison = async (monthIndex) => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/compare_avg_order_price_monthly`,
        {
          year: selectedYear,
          month: monthIndex + 1,
          userId,
          sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setCurrentMonthData(response.data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const createDataObject = (fetchedArray) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dataObject = fetchedArray.reduce(
      (acc, crr) => {
        return (acc = {
          ...acc,
          labels: [...acc.labels, months[crr.month - 1]],
          monthlyAvgPrice: [...acc.monthlyAvgPrice, crr.averagePrice],
        });
      },
      { labels: [], monthlyAvgPrice: [] }
    );

    setFetchedData(() => dataObject);
  };

  const fetchData = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/avg_order_price_yearly`,
        {
          year: selectedYear,
          period: dropdownOption.split(" ")[1],
          userId,
          sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      createDataObject(response.data.data.monthlyAverages);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const data = {
    labels: fetchedData?.labels,
    datasets: [
      {
        type: "line",
        label: "Dataset 1",
        borderColor: "#165dc9",
        pointBackgroundColor: "#FFFFFF",
        pointBorderColor: "#e0e0e0",
        pointRadius: 4,
        borderWidth: 2,
        fill: false,
        marginTop: 10,
        data: fetchedData?.monthlyAvgPrice,
        order: 1,
      },
      {
        type: "bar",
        label: "Dataset 2",
        backgroundColor: (context) => {
          const index = context?.dataIndex;
          const datasetIndex = context?.datasetIndex;
          if (
            selectedBar?.datasetIndex === datasetIndex &&
            selectedBar?.index === index
          ) {
            return "#165dc9";
          }
          return "#E8EFFA";
        },
        data: fetchedData?.monthlyAvgPrice,
        borderColor: "white",
        borderWidth: 0,
        borderRadius: 8,
        hoverBackgroundColor: "#165dc9",
        // barThickness: 30, // Set bar thickness
        // maxBarThickness: 45,
        order: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1.2,
    onClick: (event, elements) => {
      const datasetIndex = elements[0]?.datasetIndex;
      const index = elements[0]?.index;
      fetchMonthAvgComparison(elements[0]?.index);
      setSelectedBar({ datasetIndex, index });
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => "", // Remove the title
          label: function (context) {
            const value = context.raw;
            return `₹${value}`;
          },
        },
        bodyColor: "black",
        backgroundColor: "white",
        borderColor: "#165dc9",
        borderWidth: 0.5,
        displayColors: false, // Remove the color box
        bodyFont: {
          size: 14,
        },
        boxPadding: 10,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "black",
        },
        grid: {
          color: "#CCC",
          display: false,
        },
      },
      y: {
        type: "linear",
        ticks: {
          color: "black",
          callback: function (value) {
            return `₹ ${value}`; // Add ₹ symbol to the tick label
          },
        },
        grid: {
          color: "#CCC",
        },
        beginAtZero: true,
        suggestedMax: suggestedMaxY,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 0,
      },
    },
    barThickness: "flex",
    maxBarThickness: 40,
  };

  useEffect(() => {
    if (userId && sectorId) {
      fetchData();
    }
  }, [selectedYear, dropdownOption, userId, sectorId]);

  useEffect(() => {
    if (fetchedData?.monthlyAvgPrice) {
      const maxDataValue = Math.max(...fetchedData?.monthlyAvgPrice);
      // Calculate a suggested maximum value for the y-axis
      const suggestedMaxY = maxDataValue + 0.2 * maxDataValue;
      setSuggestedMaxY(suggestedMaxY);
      fetchMonthAvgComparison(fetchedData?.labels?.length - 1);
    }
  }, [fetchedData]);

  return (
    <div>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default MyMixedChart;
