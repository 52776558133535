import { NavLink } from "react-router-dom";
import { ICONS } from "../../Assets/Icons";
import Modal from "../../utils/modal/Modal";
import Logout from "../Profile_Settings/Components/Logout/Logout";
import Styles from "./NavigationBar.module.css";
import { useState } from "react";

const NavigationBar = () => {

  const [show, setShow] = useState(false);

  const isActiveFunc = ({ isActive }) => {
    return isActive
      ? `${Styles.selectedOption} ${Styles.option}`
      : `${Styles.option}`;
  };

  return (
    <div className={Styles.navbar}>
      <NavLink>{ICONS.hamburgerMenuIcon}</NavLink>

      <div className={Styles.navbar_section2}>
        <NavLink to="home" className={isActiveFunc}>
          {ICONS.homeIcon}
        </NavLink>
        <NavLink to="order" className={isActiveFunc}>
          {ICONS.orderIcon}
        </NavLink>
        {/* <NavLink to="message" className={isActiveFunc}>
          {ICONS.messageIcon}
        </NavLink> */}
        <NavLink to="serviceManagement" className={isActiveFunc}>
          {ICONS.serviceManagmentIconWhite}
        </NavLink>
      </div>

      <div className={Styles.navbar_section3}>
        <NavLink to="support" className={isActiveFunc}>
          {ICONS.supportIcon}
        </NavLink>
        <NavLink to="settings" className={isActiveFunc}>
          {ICONS.settingsIcon}
        </NavLink>
      </div>
      <Modal
        show={show}
        onClose={() => setShow(!show)}
        children={<Logout setShow={setShow} />}
      />
    </div>
  );
};

export default NavigationBar;
