import { useState } from "react";
import Header from "../Components/Header/Header";
import NavButton from "../Components/NavButton/NavButton";
import JobRequest from "./Sections/JobRequest/JobRequest";
import Accepted from "./Sections/Accepted/Accepted";
import Rejected from "./Sections/Rejected/Rejected";
import Completed from "./Sections/Completed/Completed";

import styles from "../ServiceProviderDashboard.module.css";
import Styles from "./Orders.module.css";
import SearchBar from "../Components/SearchBar/SearchBar";
import { ICONS } from "../../Assets/Icons";
import MobileFilters from "./Components/MobileFilters/MobileFilters";

const Orders = () => {
  const [show, setShow] = useState("Job Request");
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const [renderAccepted, setRenderAccepted] = useState(0);
  const [renderRejected, setRenderRejected] = useState(0);
  const [renderCompleted, setRenderCompleted] = useState(0);

  const [mobileDateRange, setMobileDateRange] = useState([new Date('2020-03-11'), new Date('2030-01-01')]);
  const [mobileOrderTypes, setMobileOrderTypes] = useState([]);
  const [mobileAmount, setMobileAmount] = useState([0, 10000]);

  return (
    <div>
      <Header title="Order Management" />

      <div className={styles.mobileNavbar}>
        <span>Order Management</span>
        {/* <SearchBar placeholder="Search by order no..." width="90%" /> */}
        <span
          onClick={() => {
            setShowMobileFilters(!showMobileFilters);
          }}
          className={styles.filter_icon_mobile}
        >
          {ICONS.filterIconMobileWhite}
        </span>
      </div>

      <MobileFilters
        setMobileDateRange={setMobileDateRange}
        setMobileOrderTypes={setMobileOrderTypes}
        setMobileAmount={setMobileAmount}
        showMobileFilters={showMobileFilters}
        setShowMobileFilters={setShowMobileFilters}
      />

      <NavButton
        show={show}
        setShow={setShow}
        options={["Job Request", "Accepted", "Rejected", "Completed"]}
      />

      <div className={Styles.dashboard_section}>
        <div style={{ display: show === "Job Request" ? "block" : "none" }}>
          <JobRequest
            mobileDateRange={mobileDateRange}
            mobileOrderTypes={mobileOrderTypes}
            mobileAmount={mobileAmount}
            setRenderAccepted={setRenderAccepted}
            setRenderRejected={setRenderRejected}
          />
        </div>

        <div style={{ display: show === "Accepted" ? "block" : "none" }}>
          <Accepted
            mobileDateRange={mobileDateRange}
            mobileOrderTypes={mobileOrderTypes}
            mobileAmount={mobileAmount}
            renderAccepted={renderAccepted}
            setRenderRejected={setRenderRejected}
            setRenderCompleted={setRenderCompleted}
          />
        </div>

        <div style={{ display: show === "Rejected" ? "block" : "none" }}>
          <Rejected
            mobileDateRange={mobileDateRange}
            mobileOrderTypes={mobileOrderTypes}
            mobileAmount={mobileAmount}
            renderRejected={renderRejected}
          />
        </div>

        <div style={{ display: show === "Completed" ? "block" : "none" }}>
          <Completed
            mobileDateRange={mobileDateRange}
            mobileOrderTypes={mobileOrderTypes}
            mobileAmount={mobileAmount}
            renderCompleted={renderCompleted}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
