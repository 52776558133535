import styles from "./AddService.module.css";
import commonStyles from '../../../Profile_Settings/Styles/ProfileCommonCss.module.css';
import { useState } from "react";
import { ICONS } from "../../../../Assets/Icons";
import Header from "../../../Components/Header/Header";
import Button from "../../../Components/Button/Button";
// import AddServiceComponent from "./components/AddServiceComponent/AddServiceComponent";
import AddServiceComponent from "./components/AddServiceComponent/AddServiceResuable";
import SaveChangesButton from "../../../Profile_Settings/Components/SaveChangesButton/SaveChangesButton";
import { Link } from "react-router-dom";
import mobile_profile_style from '../../../Mobile_Profile/Mobile_Profile.module.css'
import profileCommonCss from '../../../Profile_Settings/Styles/ProfileCommonCss.module.css'
import { useSelector } from "react-redux";

const AddService = () => {

    const [emptyValue, setEmptyValue] = useState('');
    const [portfolio, setPortfolio] = useState([]);
    const [fileError, setFileError] = useState("");

    const stateStored = localStorage.getItem('state')
    const state = JSON.parse(stateStored)

    const [serviceToBeAdded, setServiceToBeAdded] = useState({
        sectorId: state.currSectorId,
        categoryId: state.currCategoryId,
        isActive: true,
        subCategoryId: '',
        serviceIds: [],
        answers: [],
        price: '',
        per: '',
        bio: '',
    });

    const clearAllFunction = () => {
        setServiceToBeAdded({
            sector: "construction",
            category: null,
            choosenService: null,
            experience: null,
            workType: null,
            workStartTimeLine: null,
            interestedWork: null,
            rate: null,
            unit: null,
            serviceOffered: null,
            description: null,
            // photos: [],
        })
    }

    console.log(serviceToBeAdded)
    console.log(emptyValue, 'emptyValue')

    return (
        <div className={styles.addService_page}>
            <div className={mobile_profile_style.parent}>
                <div className={`${profileCommonCss.mobileNavbar} ${profileCommonCss.hideOnDesktop}`}>
                    <Link
                        to="../../serviceManagement"
                        className={styles.backButton}
                    >
                        {ICONS.ArrowBackIcon}
                    </Link>
                    <span>Add Service</span>
                </div>
            </div>
            <div className={commonStyles.edit_save_button_section}>
                <SaveChangesButton
                    formDetails={serviceToBeAdded}
                    edit={true}
                    emptyValue={emptyValue}
                    setEmptyValue={setEmptyValue}
                />
            </div>

            <Header title="Service Management" />
            <section className={styles.add_section_sub_header}>
                <p className={styles.section_heading}>
                    <span><Link to='../../serviceManagement'>{ICONS.LeftArrowIcon}</Link></span> Add Service
                </p>
            </section>

            <AddServiceComponent
                // setServiceAarray={setServiceAarray}
                setServiceToBeAdded={setServiceToBeAdded}
                edit={true}
                serviceToBeAdded={serviceToBeAdded}
                portfolio={portfolio}
                setPortfolio={setPortfolio}
                setFileError={setFileError}
                // addMoreServiceFunction={addMoreServiceFunction}
                clearAllFunction={clearAllFunction}
                emptyValue={emptyValue}
                setEmptyValue={setEmptyValue}
                displayBrowseOption={true}
            />

            <div className={`${profileCommonCss.mobileView_footerBtn} ${profileCommonCss.hideOnDesktop}`}>
                <SaveChangesButton
                    width='100%'
                    formDetails={serviceToBeAdded}
                    edit={true}
                    setEmptyValue={setEmptyValue}
                />
            </div>

        </div>
    );
};

export default AddService;
