import { useEffect, useState } from "react";
import styles from "./SaveChangesButton.module.css";

const SaveChangesButton = ({ width, emptyValue, formDetails, edit, setEmptyValue }) => {
    // console.log(formDetails);

    // const [isEmpty, setIsEmpty] = useState(null);

    console.log(formDetails, emptyValue, 'formDetails')

    const handleEditFunction = () => {
        const keys = Object.keys(formDetails);

        console.log(keys, "keys");

        const recursion = (obj) => {
            if (!obj || typeof obj !== 'object') {
                return false;
            }

            const arr = Object.keys(obj).map((key) => {
                const value = obj[key];
                console.log(typeof value, value, key, "Checking value");

                if (Array.isArray(value)) {
                    return value.length === 0 || value.includes("") || value.includes(null) ? true : false;
                }

                if (typeof value === 'object') {
                    return recursion(value);
                }

                return value === "" || value === null;
            });

             console.log(arr, "arr service");
            return arr.includes(true);
        };

        const funcCall = recursion(formDetails);

        if (funcCall) {
            setEmptyValue(true);
        } else {
            setEmptyValue(false);
        }
    };

    useEffect(() => {
        // setEmptyValue(() => null);
    }, [formDetails])

    // const handleEditFunction = () => {
    //     const keys = Object.keys(formDetails);

    //     console.log(keys, "keys");

    //     const recursion = (keys) => {
    //         console.log(keys, "keys inside recusion");
    //         const obj = Array.isArray(keys) ? null : keys;
    //         if (obj) {
    //             const innerKeys = Object.keys(obj);
    //             const arr = innerKeys.map((key) =>
    //                 typeof obj[key] === "Array"
    //                     ? obj[key].includes("") ||
    //                         obj[key].includes(null) ||
    //                         obj[key].length === 0
    //                         ? true
    //                         : false
    //                     : obj[key] === ""
    //                         ? true
    //                         : false
    //             );
    //             return arr.includes(true) ? true : false;
    //         }

    //         const arr = keys?.map((key) => {
    //             console.log(
    //                 typeof formDetails[key],
    //                 formDetails[key],
    //                 key,
    //                 "dsdsdsd "
    //             );
    //             return Array?.isArray(formDetails[key])
    //                 ? obj[key].length === 0
    //                     ? true
    //                     : false
    //                 : typeof formDetails[key] === "object"
    //                     ? recursion(formDetails[key])
    //                     : formDetails[key] === ""
    //                         ? true
    //                         : false;
    //         });

    //         console.log(arr, "arr");

    //         return arr;
    //     };
    //     const funcCall = recursion(keys);

    //     if (funcCall.includes(true)) {
    //         setEmptyValue(true);
    //     } else {
    //         setEmptyValue(false);
    //     }
    // };

    // useEffect(() => {
    //     setEmptyValue(isEmpty);
    // }, [isEmpty]);

    return (
        <button
            disabled={edit ? false : true}
            style={{ width: width }}
            onClick={() => handleEditFunction()}
            className={
                edit
                    ? `${styles.save_changes_btn} ${styles.active}`
                    : `${styles.save_changes_btn} ${styles.unActive}`
            }
        >
            Save Changes
        </button>
    );
};

export default SaveChangesButton;
