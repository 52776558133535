import { ICONS } from '../../../../Assets/Icons';
import styles from './Pagination.module.css'


const Pagination = ({ currentPage = 1, totalPages = 1, setCurrPage, pageLength }) => {

    const handlePreviousPage = () => {
        if (currentPage !== 1) {
            setCurrPage(currentPage - 1);
        }
    }

    const handleNextPage = () => {
        if (currentPage !== totalPages) {
            setCurrPage(currentPage + 1);
        }
    }

    return (
        <div className={styles.pagination}
            style={{ display: pageLength ? 'flex' : 'none' }}
        >
            <p>Showing {currentPage} of {totalPages} </p>
            <button onClick={() => handlePreviousPage()} disabled={currentPage === 1 && true} className={`${styles.btn1} ${styles.btn}`}>{ICONS.expandMore}</button>
            <button onClick={() => handleNextPage()} disabled={currentPage === totalPages && true} className={`${styles.btn2} ${styles.btn}`}>{ICONS.expandMore}</button>
        </div>
    )
};

export default Pagination;