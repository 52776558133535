import { ICONS } from "../../../../Assets/Icons";

import styles from "./MobileFilters.module.css";
import commonStyles from "../../../ServiceProviderDashboard.module.css";
import "../../../../utils/utilsCss/utilsCss.css";
import { addDays } from "date-fns";
import FilterByAmount from "../OrderSearchSection/SearchComponents/filterByAmount/filterByAmount";
import FilterByOrder from "../OrderSearchSection/SearchComponents/filterByOrder/filterByOrder";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import Button from "../../../Components/Button/Button";
import { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";

// import 'primeflex/primeflex.css';
// import 'primereact/resources/primereact.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";

const MobileFilters = ({
  setMobileDateRange,
  setMobileOrderTypes,
  setMobileAmount,
  showMobileFilters,
  setShowMobileFilters,
}) => {
  const [dates, setDates] = useState([
    new Date('2020-03-11'), new Date('2030-01-01')
  ]);

  const options = { year: "numeric", month: "short", day: "numeric" };

  const date1 = dates
    ? dates[0]?.toLocaleDateString("en-US", options).replace(",", "")
    : null;
  const date2 = dates
    ? dates[1]?.toLocaleDateString("en-US", options).replace(",", "")
    : null;

  // const [sortBy, setSortBy] = useState("");
  const [showError, setShowError] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);

  const [localOrderMobileType, setLocalMobileOrderType] = useState([]);
  const [localMobileAmount, setLocalMobileAmount] = useState([0, 10000]);
  const [callHandleApplyFilter, setCallHandleApplyFilter] = useState(false);

  const confirmDates = () => {
    if (dates && typeof dates[0] === "object" && typeof dates[1] === "object") {
      setShowDateRange(!showDateRange);
      setShowError(false);
      //api call
    } else {
      setShowError(true);
    }
  };

  const clearDates = () => {
    setDates(null);
    setShowError(false);
  };

  const closeCalander = () => {
    setShowDateRange(!showDateRange);
    setShowMobileFilters(true);
    setShowError(false);
  };

  const openCalander = () => {
    setShowDateRange(true);
  };

  const handleApplyFilter = () => {

    setMobileAmount(localMobileAmount);
    setMobileOrderTypes(localOrderMobileType);
    setMobileDateRange(() => dates);
    setShowMobileFilters(false);
    setCallHandleApplyFilter(false);
  };

  const clearAllFilters = () => {
    setLocalMobileAmount(() => [100, 9000]);
    setLocalMobileOrderType(() => []);
    setDates(() => [new Date('2020-03-11'), new Date('2030-01-01')]);
    setCallHandleApplyFilter(true);
  };

  useEffect(() => {
    handleApplyFilter();
  }, [callHandleApplyFilter]);

  if (!showMobileFilters) {
    return null;
  }

  return (
    <div
      className={commonStyles.DropdownList_Container}
      style={{ height: showDateRange ? "20rem" : "fit-content" }}
    >
      <div className={styles.header}>
        <p>Filter</p>
        <span onClick={() => setShowMobileFilters(false)}>
          {ICONS.closeIcon}
        </span>
      </div>
      <div className={styles.filter_body}>
        {/* <div className={styles.sortBy_container}>
          <p>Sort by</p>
          <RadioButton
            name="sortBy"
            label="Latest to Oldest"
            value={sortBy}
            onChange={(e) => setSortBy(e)}
          />
          <RadioButton
            name="sortBy"
            label="Oldest to Latest"
            value={sortBy}
            onChange={(e) => setSortBy(e)}
          />
        </div> */}

        <FilterByOrder
          mobileView={true}
          localOrderMobileType={localOrderMobileType}
          setLocalMobileOrderType={setLocalMobileOrderType}
        />
        <FilterByAmount
          mobileView={true}
          localMobileAmount={localMobileAmount}
          setLocalMobileAmount={setLocalMobileAmount}
        />

        <div className={styles.calendar_container}>
          <h1>Date Range</h1>
          <div className={styles.datePickOption} onClick={() => openCalander()}>
            {date1 && date2 ? `${date1} - ${date2}` : `Select`}
          </div>
        </div>
      </div>

      <div
        style={{ display: showDateRange ? "block" : "none" }}
        className={commonStyles.DropdownList_Container}
      >
        <header className={styles.header}>
          Date Range
          <span onClick={() => closeCalander()}>{ICONS.closeIcon}</span>
        </header>
        <div className={styles.calendar}>
          <Calendar
            placeholder="Date Range"
            className={styles.pickerStyle}
            value={dates}
            onChange={(e) => setDates(e.target.value)}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            inline
          />
        </div>
        <div className={styles.footer}>
          {showError && <p>Dates are not set !</p>}
          <div className={styles.sub_footer}>
            <Button
              text="Clear"
              height="44px"
              width="30%"
              borderColor="var(--grey-80)"
              onClick={() => clearDates()}
            />
            <Button
              text="Confirm"
              width="70%"
              height="44px"
              color="var(--smoky-black)"
              backgroundColor="var(--yellow)"
              onClick={() => confirmDates()}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        {showError && <p>Dates are not set !</p>}
        <div className={styles.sub_footer}>
          <Button
            onClick={clearAllFilters}
            text="Clear"
            height="44px"
            width="30%"
            borderColor="var(--grey-80)"
          />
          <Button
            onClick={handleApplyFilter}
            text="Apply Filters"
            width="70%"
            height="44px"
            color="var(--smoky-black)"
            backgroundColor="var(--yellow)"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileFilters;
