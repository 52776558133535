import styles from "../Logout/Logout.module.css";
import { ICONS } from "../../../../Assets/Icons";
import Button from "../../../Components/Button/Button";
import axios from "axios";
import axios2 from "../../../../axios2";

const Logout = ({ setShow, onClick }) => {

  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
  const mainBaseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_MAIN_DEV : process.env.REACT_APP_MAIN_PROD;

  const logoutHandler = async () => {
    try {

      const res = await axios2.post(`${baseUrl}/api/v1/auth/logout`, { userId }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      console.log('User logged out successfully :', res);

      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');

      setShow(false);
      window.location.href = `${mainBaseUrl}/auth/signup?button=login`;
    } catch (error) {
      console.log(`Error logging out :`, error);
      alert('Unable to Logout right now');
      setShow(false);
    }
  }

  return (
    <>
      <div className={styles.logout_Container}>
        <div className={styles.logout_popup}>
          <div className={styles.logout_heading}>
            Logout
            <p className={styles.logout_text}>
              Are you sure you want to log out?
            </p>
          </div>
          <div onClick={() => setShow(false)} className={styles.logout_icon}>{ICONS.logoutCloseIcon}</div>
        </div>
        <div className={styles.footer_logout_btn}>
          <Button
            width="100%"
            borderColor="var(--grey-80)"
            padding="14px 18px"
            text="Cancel"
            backgroundColor="var(--white)"
            color="var(--smoky-black)"
            onClick={() => setShow(false)}
          />
          <Button
            width="100%"
            padding="14px 18px"
            text="Log Out"
            backgroundColor="var(--red)"
            color="var(--white)"
            onClick={logoutHandler}
          />
        </div>
      </div>
    </>
  );
};
export default Logout;
