import styles from "../Styles/ProfileCommonCss.module.css";
import Button from "../../Components/Button/Button";
import Inputbox from "../Components/Inputbox/Inputbox";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { ICONS } from "../../../Assets/Icons";
import { Link } from "react-router-dom";
import commonStyle from "../../ServiceProviderDashboard.module.css";
import { useEffect, useState } from "react";
import EditButton from "../Components/EditButton/EditButton";
import SaveChangesButton from "../Components/SaveChangesButton/SaveChangesButton";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";
import DragAndDrop from "../Components/DragAndDropComponent/DragAndDropComponent";

const EducationalDetails = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  // const mobileNumber = useSelector((state) => state.auth.userMobileNumber);
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  const [isLoading, setIsLoading] = useState(null);
  const [fileError, setFileError] = useState("");
  const [licAndCertUploadIds, setLicAndCertUploadIds] = useState([]);
  const [cancelledChequeArray, setCancelledChequeArray] = useState([]);
  const [error, setError] = useState({
    yearOfCompletion: null,
  });
  const [edit, setEdit] = useState(null);
  const [emptyValue, setEmptyValue] = useState(null);
  const [discard, setDiscard] = useState(null);
  const [recievedFile, setRecievedFile] = useState(null);
  const [otherCertifications, setOtherCertifications] = useState({
    otherCertifications: "",
    achievements: "",
  });
  const [educationDetails, setEducationDetails] = useState({
    collegeName: "",
    degree: "",
    yearOfCompletion: "",
  });

  const handleEditDiscard = () => {
    if (edit) {
      setEdit(false);
      setDiscard(true);
      setEmptyValue(null);
    } else {
      setEdit(true);
      setDiscard(false);
      setEmptyValue(null);
    }
  };

  const handleYear = (e) => {
    setEducationDetails({
      ...educationDetails,
      yearOfCompletion: e.target.value,
    });
    setError({
      yearOfCompletion: null,
    });
    const value = e.target.value;
    if (value < 1970 || value > 2031) {
      setError({
        yearOfCompletion: "not a valid year",
      });
    }
  };

  const uploadDoc = async (file) => {
    try {
      setIsLoading(true);
      const generateUrl = await axios2.post(
        `${baseUrl}/api/v1/auth/generate-upload-url`,
        {
          bucketName: "connect-dev-test",
          key: file.key,
          expDate: 15,
          contentType: file.fileObject?.type,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { url } = generateUrl.data.data;
      // console.log(url, "url");

      const uploadFile = await axios.put(url, file.fileObject, {
        headers: {
          "Content-Type": file.fileObject.type,
        },
      });
      // console.log(uploadFile, "uploadFile");
      if (uploadFile.status === 200) {
        const uploadDocDetails = await axios2.post(
          `${baseUrl}/api/v1/auth/list-uploaded-document`,
          {
            bucketName: "connect-dev-test",
            key: file.key,
            contentType: file.fileObject.type,
            documentType: file.documentType,
            documentName: file.fileObject.name,
            documentDescription: file.documentDescription,
            documentSize: file.fileObject.size,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (uploadDocDetails.status === 201) {
          const docDetails = uploadDocDetails.data.data.document;

          setLicAndCertUploadIds((prev) => [...prev, docDetails.id]);
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFullData = async () => {
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/profile/educational/construction`,
        {
          ...educationDetails,
          ...otherCertifications,
          licAndCertUploadIds: licAndCertUploadIds,
          sectorId: sectorId,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(response);

      if (response.status === 201) {
        console.log(response);
        setEdit(false);
        setDiscard(null);
        setEmptyValue(null);
        toast.success('Successfully saved');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const retriveDocuments = async (docId) => {
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/auth/document-retrival`,
        {
          documentId: docId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(response, "retrieved Document");

      if (response.status === 200) {
        const docFile = {
          docId: docId,
          docType: response.data.data.type,
          docName: response.data.data.name,
          docUrl: response.data.data.url,
        };

        setCancelledChequeArray((prev) => {
          const repeatedDoc = prev?.find(
            (cheque) => cheque.docId === docFile.docId
          );
          if (repeatedDoc) {
            return prev;
          } else {
            return [...prev, docFile];
          }
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/profile/your-educational-details/construction`,
        {
          educationDetails,
          userId: userId,
          sectorId: sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, "response");
      if (response.status === 200) {
        const recievedEducationalDetails =
          response.data.data.educationalDetails;
        setRecievedFile(() => recievedEducationalDetails);

        if (response.data.data.educationalDetails.licAndCertUploadIds) {
          response.data.data.educationalDetails.licAndCertUploadIds.forEach(
            (id) => retriveDocuments(id)
          );
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      emptyValue === false &&
      cancelledChequeArray.length > 0 &&
      error.yearOfCompletion === null
    ) {
      const newDocAddedToUpload = cancelledChequeArray.filter(
        (cheque) => cheque.fileObject
      );
      console.log(newDocAddedToUpload, "newDocAddedToUpload");
      if (newDocAddedToUpload.length > 0) {
        newDocAddedToUpload.forEach((cheque) => uploadDoc(cheque));
      }
      if (newDocAddedToUpload.length === 0 && licAndCertUploadIds.length > 0) {
        uploadFullData();
      }
    }
  }, [emptyValue, error]);

  useEffect(() => {
    if (
      emptyValue === false &&
      licAndCertUploadIds?.length > 0 &&
      licAndCertUploadIds?.length === cancelledChequeArray?.length
    ) {
      uploadFullData();
    }
  }, [licAndCertUploadIds]);

  useEffect(() => {
    const existingDocIds = cancelledChequeArray?.reduce(
      (acc, crr) => (crr.docId ? [...acc, crr.docId] : acc),
      []
    );
    console.log(existingDocIds, "existingDocIds");
    setLicAndCertUploadIds(existingDocIds);
    setEmptyValue(null);
  }, [cancelledChequeArray]);

  useEffect(() => {
    if (recievedFile) {
      setEducationDetails({
        collegeName: recievedFile?.collegeName,
        degree: recievedFile?.degree,
        yearOfCompletion: recievedFile?.yearOfCompletion,
      });

      setOtherCertifications({
        otherCertifications: recievedFile?.otherCertifications,
        achievements: recievedFile?.achievements,
      });
    }
  }, [recievedFile]);

  useEffect(() => {
    if (discard) {
      // setEducationDetails({
      //   collegeName: recievedFile?.collegeName,
      //   degree: recievedFile?.degree,
      //   yearOfCompletion: recievedFile?.yearOfCompletion,
      // })

      // setOtherCertifications({
      //   otherCertifications: recievedFile?.otherCertifications,
      //   achievements: recievedFile?.achievements,
      // })

      getData();

      setError({
        yearOfCompletion: null,
      });
    }
  }, [discard]);

  useEffect(() => {
    //api call;
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link
            to="../../mobileProfile/personalProfile"
            className={styles.backButton}
          >
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Educational Details</span>
          <span
            className={commonStyle.filter_icon_mobile}
            onClick={handleEditDiscard}
          >
            {" "}
            {edit ? `Discard` : ICONS.EditDataIcon}
          </span>
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={styles.edit_save_button_section}>
          <EditButton edit={edit} onClick={handleEditDiscard} />
          <SaveChangesButton
            formDetails={educationDetails}
            edit={edit}
            setEmptyValue={setEmptyValue}
          />
        </div>

        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Educational Details</p>
          <p className={styles.mandatory_data}>
            (* Indicates required question)
          </p>
        </div>
        <div className={styles.heading}>Academic Background</div>
        <div className={styles.Main_Container}>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Institute name
              <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={educationDetails.collegeName}
              onChange={(e) =>
                setEducationDetails({
                  ...educationDetails,
                  collegeName: e.target.value,
                })
              }
              type="text"
              placeholder="College name (if applicable) or type “None”"
            />
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Degree <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={educationDetails.degree}
              onChange={(e) =>
                setEducationDetails({
                  ...educationDetails,
                  degree: e.target.value,
                })
              }
              placeholder="Highest qualification or certification (e.g., Diploma, Training)"
            />
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Year of completion <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={educationDetails.yearOfCompletion}
              type="number"
              onChange={(e) => handleYear(e)}
              placeholder="Year of completion (e.g., 2005, 2018)"
            />
            {error.yearOfCompletion && !emptyValue && (
              <p className={styles.error}>{error.yearOfCompletion}</p>
            )}
          </div>
        </div>

        <div className={styles.heading}>Additional Qualifications</div>
        <div className={styles.Main_Container}>
          <div className={styles.form_data}>
            <label className={styles.form_label}>Other Certifications</label>
            <Inputbox
              readOnly={!edit}
              type="text"
              value={otherCertifications.otherCertifications}
              onChange={(e) =>
                setOtherCertifications({
                  ...otherCertifications,
                  otherCertifications: e.target.value,
                })
              }
              placeholder="Additional certifications or qualifications"
            />
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>Achievements</label>
            <Inputbox
              readOnly={!edit}
              type="text"
              value={otherCertifications.achievements}
              onChange={(e) =>
                setOtherCertifications({
                  ...otherCertifications,
                  achievements: e.target.value,
                })
              }
              placeholder="List any relevant achievements or recognitions"
            />
          </div>
        </div>

        <div className={styles.heading}>Licensing & Certificates</div>
        <div className={styles.Main_Container}>
          <div className={styles.cancelledCheque_container}>
            <DragAndDrop
              disabled={!edit}
              file={cancelledChequeArray}
              setFile={setCancelledChequeArray}
              width="266px"
              userId={userId}
              setFileError={setFileError}
              acceptedType={["application/pdf"]}
              keyTitle="cancel-cheque"
              documentType="Other"
              documentDescription="cancelled cheque"
              displayBrowseOption={true}
            />
            {cancelledChequeArray?.length <= 0 && edit && (
              <p className={styles.error}>required*</p>
            )}
            {fileError && !cancelledChequeArray?.length <= 0 && (
              <p className={styles.error}>{fileError}</p>
            )}
          </div>
        </div>

        <div className={`${styles.footer_data} ${styles.hideOnMobile}`}>
          <Link to="../financial">
            <Button
              padding="10px 18px"
              text="Next >"
              backgroundColor="var(--yellow)"
              color="var(--smoky-black)"
              disabled={edit}
            />
          </Link>
        </div>
      </div>
      <div className={`${styles.mobileView_footerBtn} ${styles.hideOnDesktop}`}>
        <SaveChangesButton
          width="100%"
          formDetails={educationDetails}
          edit={edit}
          setEmptyValue={setEmptyValue}
        />
      </div>
    </>
  );
};

export default EducationalDetails;
