import styles from "../ProfileExtendedPopup/ProfilePopup.module.css";
import { ICONS } from "../../../../Assets/Icons";
import { Link } from "react-router-dom";
import Modal from "../../../../utils/modal/Modal";
import Logout from "../Logout/Logout";
import { useState } from "react";

const ProfilePopup = ({ setShow }) => {
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    // setShow(false);
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.ProfilePopup_Container}>
        <div className={styles.ProfilePopup}>
          <Link to="../settings">
            <div
              className={styles.ProfilePopup_data}
              onClick={() => setShow(false)}
            >
              {ICONS.PersonIcon}{" "}
              <span className={styles.text}>Profile Settings</span>
            </div>
          </Link>
          <Link to="../support">
            <div
              className={styles.ProfilePopup_data}
              onClick={() => setShow(false)}
            >
              {ICONS.ContactSupportIcon}{" "}
              <span className={styles.text}>Help & Support</span>{" "}
            </div>
          </Link>
          <div className={styles.ProfilePopup_data} onClick={handleLogout}>
            <span>
              {" "}
              {ICONS.logoutIcon}{" "}
              <span className={styles.logout_text}>Logout</span>{" "}
            </span>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <Logout setShow={setShowModal} />
        </Modal>
      </div>
    </>
  );
};
export default ProfilePopup;
