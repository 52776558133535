import styles from "./ServiceManagement.module.css";
import { useState } from "react";
import Header from "../Components/Header/Header";
import NavButton from "../Components/NavButton/NavButton";
import Button from "../Components/Button/Button";
import SearchSection from "./Components/SearchSection/SearchSection";
import Active from "./Sections/Active/Active";
import Deactive from "./Sections/Deactive/Deactive";
import AddService from "./Sections/AddService/AddService";
import { Link, useNavigate } from "react-router-dom";
import commonStyles from "../ServiceProviderDashboard.module.css";
import SearchBar from "../Components/SearchBar/SearchBar";
import { ICONS } from "../../Assets/Icons";
import { toast } from "react-toastify";
import axios2 from "../../axios2";

const ServiceManagement = () => {
    const accessToken = localStorage.getItem("accessToken");
    const baseUrl =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_DEV
            : process.env.REACT_APP_API_PROD;

    const stateStored = localStorage.getItem("state");
    const state = JSON.parse(stateStored);

    const [show, setShow] = useState("Active");
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate()

    const checkProfileComplete = async () => {
        try {
            setIsLoading(true);
            console.log(state?.userDetails?.userId)
            const response = await axios2.post(
                `${baseUrl}/api/v1/profile/check-for-profile-completion`,
                {
                    userId: state?.userDetails?.userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            console.log(response, "data check response");
            if (response.status === 200) {
                navigate('../addService')
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
                console.log(error);
            } else {
                console.log(error);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Header title="Service Management" />
            <div className={commonStyles.mobileNavbar}>
                <span>Service Management</span>
                {/* <SearchBar placeholder="Search by order no..." width="90%" /> */}
                {/* <span
                    onClick={() => setShowMobileFilters(!showMobileFilters)}
                    className={commonStyles.filter_icon_mobile}
                >
                    {ICONS.filterIconMobileWhite}
                </span> */}
            </div>
            <NavButton
                show={show}
                setShow={setShow}
                options={["Active", "Deactive"]}
                minWidth='2rem'
            />
            <div className={styles.add_service_button_component}>
                {/* <Link to="../addService"> */}
                <Button
                    text="Add Service"
                    backgroundColor="var(--blue)"
                    color="var(--white)"
                    padding="8px 20px"
                    onClick={() => checkProfileComplete()}
                />
                {/* </Link> */}
            </div >

            <div className={styles.add_service_link}>
                <Link className={styles.link} to="../addService">
                    <span>{ICONS.AddIconBlue}</span>Add Service
                </Link>
            </div>

            <div className={styles.dashboard_section}>
                <div style={{ display: show === "Active" ? "block" : "none" }}>
                    <Active show={show} />
                </div>

                <div style={{ display: show === "Deactive" ? "block" : "none" }}>
                    <Deactive show={show} />
                </div>

                {/* <div style={{ display: show === "Add Service" ? "block" : "none" }}>
                    <AddService />
                </div> */}
            </div>
        </div>
    );
};

export default ServiceManagement;
