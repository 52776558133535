import styles from "../Components/NavButton/NavButton.module.css";
import settingsStyles from "./Settings.module.css";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useState } from "react";

import Header from "../Components/Header/Header";
import SearchBar from "../Components/SearchBar/SearchBar";
import PersonalDetails from "./Sections/PersonalDetails";
import EducationDetails from "./Sections/EducationalDetails";
import ProfileSideBar from "./Components/ProfileSideBar/ProfileSideBar";
import FinancialDetails from "./Sections/FinancialDetails";
import ProfessionalDetails from "./Sections/ProfessionalDetails";
import SecuritySettings from "./Sections/SecuritySettings";
import NotificationSetting from "./Sections/NotificationSetting";
import BillingHistory from "./Sections/BillingHistory";
import Modal from "../../utils/modal/Modal";
import Logout from "./Components/Logout/Logout";
import MobileBar from "../NavigationBar/MobileBar/MobileBar";
import RatingAndReview from "../RatingAndReview/RatingAndReview";
import MoreReviews from "../RatingAndReview/Section/MoreReviews/MoreReviews";
import BusinessDetails from "./Sections/BusinessDetails";
import { useSelector } from "react-redux";

const Settings = () => {

  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const userType = stateData.userType;

  const [show, setShow] = useState(false);

  console.log(userType)

  return (
    <>
      <Header title="Profile Settings" />
      <div
        className={`${styles.navButton_container} ${settingsStyles.searchbar}`}
      >
        {/* <SearchBar placeholder="Search Settings..." /> */}
      </div>
      <div className={settingsStyles.settings_page}>
        <div className={settingsStyles.sidebar}>
          <ProfileSideBar setShow={setShow} />
        </div>
        <div className={settingsStyles.allPages}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={userType === "Individual" ? "individual" : "business"}
                  replace
                />
              }
            />
            <Route path="individual" element={<PersonalDetails />} />
            <Route path="business" element={<BusinessDetails />} />
            <Route path="educational" element={<EducationDetails />} />
            <Route path="professional" element={<ProfessionalDetails />} />
            <Route path="financial" element={<FinancialDetails />} />
            <Route path="security" element={<SecuritySettings />} />
            <Route path="notification" element={<NotificationSetting />} />
            <Route path="invoiceHistory" element={<BillingHistory />} />
            <Route path="rating" element={<RatingAndReview />} />
            <Route path="moreReviews" element={<MoreReviews />} />
          </Routes>
          <Outlet />
          <Modal
            show={show}
            onClose={() => setShow(!show)}
            children={
              <Logout setShow={setShow} onClick={() => setShow(!show)} />
            }
          />
        </div>
      </div>
    </>
  );
};
export default Settings;
