import styles from "../Styles/ProfileCommonCss.module.css";
import commonStyle from "../../ServiceProviderDashboard.module.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { ICONS } from "../../../Assets/Icons";
import Button from "../../Components/Button/Button";
import Inputbox from "../Components/Inputbox/Inputbox";
import Loader from "../../Components/Loader/Loader";
import UploadFile from "../../Components/UploadFile/UploadFile";
import EditButton from "../Components/EditButton/EditButton";
import SaveChangesButton from "../Components/SaveChangesButton/SaveChangesButton";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";

const BusinessDetails = () => {
    // login states
    // const userId = useSelector((state) => state.auth.userId);
    const stateStored = localStorage.getItem("state");
    const stateData = JSON.parse(stateStored);
    const userType = stateData.userType;
    const baseUrl =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_DEV
            : process.env.REACT_APP_API_PROD;
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const mobileNumber = stateData.userMobileNumber;
    const sectorId = stateData.currSectorId;

    const [defaultProfilePic, setDefaultProfilePic] = useState(
        "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/profilepic.webp"
    );
    const [isLoading, setIsLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [emptyValue, setEmptyValue] = useState(null);
    const [recievedFile, setRecievedFile] = useState(null);
    const [websiteUrl, setWebsiteUrl] = useState(null);
    const [socialMediaProfile, setSocialMediaProfile] = useState(null);
    const [recievedDocUrls, setRecievedDocUrls] = useState({
        profilePic: "",
        companyCertificate: "",
        profilePic: "",
    });
    const [discard, setDiscard] = useState(null);
    const [sameAs, setSameAs] = useState(null);
    const [error, setError] = useState({
        mobileNumber: null,
        emailAddress: null,
        bio: null,
        aadhar: null,
        pan: null,
        profilePic: null,
    });
    const [profilePic, setProfilePic] = useState({
        key: "",
        documentType: "",
        documentDescription: "",
        fileObject: null,
        docUrlRecievedFromDB: null,
        recievedUploadedDocId: null,
        emptyProfilePic:
            "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/emptyProfileImage.svg",
    });

    const [pancard, setPancard] = useState({
        key: "",
        documentType: "",
        documentDescription: "",
        docUrlRecievedFromDB: null,
        fileObject: null,
        recievedUploadedDocId: null,
    });

    const [companyCertificate, setCompanyCertificate] = useState({
        key: "",
        documentType: "",
        documentDescription: "",
        docUrlRecievedFromDB: null,
        fileObject: null,
        recievedUploadedDocId: null,
    });

    const [businessDetails, setBusinessDetails] = useState({
        companyName: "",
        companyType: "",
        emailAddress: "",
        gstInNumber: "",
        companyPancard: "",
        yearOfEstablishment: "",
        numberOfEmployees: "",
        preferredLanguage: "",
        contactPersonName: "",
        bio: "",
        permanentAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            pincode: "",
        },
        currentAddress: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            pincode: "",
        },
    });

    const handleEditDiscard = () => {
        if (edit) {
            setEdit(false);
            setDiscard(true);
            setEmptyValue(null);
            setError({
                mobileNumber: null,
                emailAddress: null,
                bio: null,
            });
        } else {
            setEdit(true);
            setDiscard(false);
            setEmptyValue(null);
        }
    };

    const handleProfilePic = (e) => {
        if (e.target.files.length === 0) return;
        const value = e.target.files[0];
        if (value?.size > 2 * 1024 * 1024) {
            setError(() => ({ ...error, profilePic: "size exceeds 10MB" }));
            setProfilePic({
                ...profilePic,
                fileObject: null,
                fileLink: defaultProfilePic,
            });
        } else {
            setProfilePic({
                ...profilePic,
                key: `profile-pictures/${mobileNumber}-${Date.now()}.jpeg`,
                documentType: "Profile",
                documentDescription: "Profile picture uploaded by user",
                fileObject: e.target.files[0],
                fileLink: URL.createObjectURL(value),
                docUrlRecievedFromDB: null,
                recievedUploadedDocId: null,
            });
            setError(() => ({ ...error, profilePic: null }));
        }
    };

    const handleCompanyCertificate = (value) => {
        if (value?.length === 0) return;
        if (value?.size > 4 * 1024 * 1024) {
            setError(() => ({ ...error, aadhar: "size exceeds 10MB" }));
            setCompanyCertificate({ ...companyCertificate, fileObject: null });
        } else {
            setCompanyCertificate({
                ...companyCertificate,
                key: `companyRegistrationCertificate/${mobileNumber}-${Date.now()}.${value?.type.split("/")[1]
                    }`,
                documentType: "RegistrationCertificate",
                documentDescription:
                    "company Registration Certificate uploaded by user",
                fileObject: value,
                recievedUploadedDocId: null,
            });
            setError((error) => ({ ...error, aadhar: null }));
        }
    };

    const handlePanCard = (value) => {
        if (value?.length === 0) return;
        if (value?.size > 4 * 1024 * 1024) {
            setError(() => ({ ...error, pan: "size exceeds 10MB" }));
            setPancard({ ...pancard, fileObject: null });
        } else {
            setPancard({
                ...pancard,
                key: `pan-card/${mobileNumber}-${Date.now()}.${value?.type.split("/")[1]
                    }`,
                documentType: "Pan",
                documentDescription: "Profile picture uploaded by user",
                fileObject: value,
                recievedUploadedDocId: null,
            });
            setError(() => ({ ...error, pan: null }));
        }
    };

    const handleSameAs = () => {
        setSameAs(!sameAs);
        if (!sameAs) {
            setBusinessDetails(prev => ({
                ...prev,
                currentAddress: {
                    ...prev.currentAddress,
                    addressLine1: prev.permanentAddress.addressLine1,
                    addressLine2: prev.permanentAddress.addressLine2,
                    city: prev.permanentAddress.city,
                    state: prev.permanentAddress.state,
                    pincode: prev.permanentAddress.pincode
                }
            }));
        }
    };

    const validateEntry = (value, name) => {
        switch (name) {
            case "mobileNumber":
                if (value) {
                    if (!/^[789]\d{9}$/.test(value)) {
                        return "Mobile number is invalid";
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }

            case "emailAddress":
                if (value) {
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        return "Email address is invalid";
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }

            case "bio":
                if (value) {
                    if (value.length >= 300) {
                        return "Max limit is 300 words";
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }

            default:
                return null;
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        console.log('value / ////////////', value, 'sssssss', name)
        if (name.startsWith("permanentAddress.")) {
            const field = name.split(".")[1];
            setBusinessDetails(prev => {
                const updatedPermanentAddress = {
                    ...prev.permanentAddress,
                    [field]: value
                };
                return {
                    ...prev,
                    permanentAddress: updatedPermanentAddress,
                    currentAddress: sameAs ? {
                        ...prev.currentAddress,
                        [field]: value
                    } : prev.currentAddress
                };
            });
        } else if (name.startsWith("currentAddress.") && !sameAs) {
            const field = name.split(".")[1];
            setBusinessDetails(prev => ({
                ...prev,
                currentAddress: {
                    ...prev.currentAddress,
                    [field]: value,
                },
            }));
        } else {
            setBusinessDetails(prev => ({ ...prev, [name]: value }));
        }

        const errorMessage = validateEntry(value, name);
        setError(prev => ({ ...prev, [name]: errorMessage }));
    };

    const uploadDocuments = () => {
        const uploadDocOneByOne = async (file) => {
            if (file.fileObject) {
                setIsLoading(true);
                try {
                    const getUrl = await axios2.post(
                        `${baseUrl}/api/v1/auth/generate-upload-url`,
                        {
                            bucketName: "connect-dev-test",
                            key: file.key,
                            expDate: 15,
                            contentType: file.fileObject.type,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                "Content-Type": "application/json",
                            },
                            withCredentials: true,
                        }
                    );

                    const { url } = getUrl.data.data;

                    const uploadFile = await axios.put(url, file.fileObject, {
                        headers: {
                            "Content-Type": file.fileObject.type,
                        },
                    });

                    if (uploadFile.status === 200) {
                        console.log(uploadFile, file, "uploadFile");
                        const uploadFileData = await axios2.post(
                            `${baseUrl}/api/v1/auth/list-uploaded-document`,
                            {
                                bucketName: "connect-dev-test",
                                key: file.key,
                                contentType: file.fileObject.type,
                                documentType: file.documentType,
                                documentDescription: file.documentDescription,
                                documentSize: file.fileObject.size,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                    "Content-Type": "application/json",
                                },
                                withCredentials: true,
                            }
                        );

                        if (file.documentType === "Pan") {
                            setPancard({
                                ...pancard,
                                recievedUploadedDocId: uploadFileData.data.data.document.id,
                            });
                        }

                        if (file.documentType === "RegistrationCertificate") {
                            setCompanyCertificate({
                                ...companyCertificate,
                                recievedUploadedDocId: uploadFileData.data.data.document.id,
                            });
                        }

                        if (file.documentType === "Profile") {
                            setProfilePic({
                                ...profilePic,
                                recievedUploadedDocId: uploadFileData.data.data.document.id,
                            });
                        }
                    }
                } catch (error) {
                    if (error?.response?.data?.message) {
                        toast.error(error?.response?.data?.message);
                        console.log(error);
                    } else {
                        console.log(error);
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        };

        const documents = [profilePic, companyCertificate, pancard];

        if (
            profilePic.docUrlRecievedFromDB &&
            companyCertificate.docUrlRecievedFromDB &&
            pancard.docUrlRecievedFromDB
        ) {
            uploadFullData();
        } else {
            documents.forEach((doc) => uploadDocOneByOne(doc));
        }
    };

    const uploadFullData = async () => {
        try {
            setIsLoading(true);
            const uploadFullData = await axios2.post(
                `${baseUrl}/api/v1/profile/business`,
                {
                    ...businessDetails,
                    sectorId: sectorId,
                    userId: userId,
                    websiteUrl: websiteUrl,
                    socialMediaProfile: socialMediaProfile,
                    registrationCertificateUploadId:
                        companyCertificate.recievedUploadedDocId,
                    panNumberUploadId: pancard.recievedUploadedDocId,
                    profilePictureUploadId: profilePic.recievedUploadedDocId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            if (uploadFullData.status === 200) {
                setEdit(false);
                setDiscard(null);
                setEmptyValue(null);
                setError({
                    mobileNumber: null,
                    emailAddress: null,
                    bio: null,
                });
                setIsLoading(false);
                toast.success("Successfully saved");
                getData();
            }
            // }

            setEmptyValue(null);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
                console.log(error);
            } else {
                console.log(error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const retriveDocuments = async (docObject) => {
        if (docObject.docId) {
            try {
                const response = await axios2.post(
                    `${baseUrl}/api/v1/auth/document-retrival`,
                    {
                        documentId: docObject.docId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );

                if (response.status === 200) {
                    const value = response.data.data.url;
                    if (docObject.docName === "companyCertificate") {
                        setCompanyCertificate((prev) => ({
                            ...prev,
                            recievedUploadedDocId: docObject.docId,
                            docUrlRecievedFromDB: value,
                        }));
                        setRecievedDocUrls((prev) => ({
                            ...prev,
                            companyCertificate: value,
                        }));
                    }
                    if (docObject.docName === "pancard") {
                        setPancard((prev) => ({
                            ...prev,
                            recievedUploadedDocId: docObject.docId,
                            docUrlRecievedFromDB: value,
                        }));
                        setRecievedDocUrls((prev) => ({
                            ...prev,
                            panCard: value,
                        }));
                    }
                    if (docObject.docName === "profilePic") {
                        setProfilePic((prev) => ({
                            ...prev,
                            recievedUploadedDocId: docObject.docId,
                            docUrlRecievedFromDB: value,
                        }));
                        setRecievedDocUrls((prev) => ({
                            ...prev,
                            profilePic: value,
                        }));
                    }
                }
            } catch (error) {
                if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message);
                    console.log(error);
                } else {
                    console.log(error);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await axios2.post(
                `${baseUrl}/api/v1/profile/your-business-details`,
                {
                    userId: userId,
                    sectorId: sectorId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                console.log(response, "response");
                const businessDetailsRecieved = response?.data?.data?.businessDetails;

                setRecievedFile(() => businessDetailsRecieved);
                setCompanyCertificate((prev) => ({
                    ...prev,
                    docUrlRecievedFromDB:
                        businessDetailsRecieved?.registrationCertificateUploadId,
                }));
                setPancard((prev) => ({
                    ...prev,
                    docUrlRecievedFromDB: businessDetailsRecieved?.panNumberUploadId,
                }));
                setProfilePic((prev) => ({
                    ...prev,
                    docUrlRecievedFromDB: businessDetailsRecieved?.profilePictureUploadId,
                }));

                const documentsNeedsToBeRetrived = [
                    {
                        docName: "companyCertificate",
                        docId: businessDetailsRecieved?.registrationCertificateUploadId,
                    },
                    {
                        docName: "pancard",
                        docId: businessDetailsRecieved?.panNumberUploadId,
                    },
                    {
                        docName: "profilePic",
                        docId: businessDetailsRecieved?.profilePictureUploadId,
                    },
                ];

                documentsNeedsToBeRetrived.forEach((docObject) =>
                    retriveDocuments(docObject)
                );
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
                console.log(error);
            } else {
                console.log(error);
            }
        } finally {
            console.log("setIsLoading(false)");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const errorValues = Object.values(error);
        const validationValue = errorValues.map((value) => value === null);
        if (
            companyCertificate.recievedUploadedDocId &&
            pancard.recievedUploadedDocId &&
            profilePic.recievedUploadedDocId &&
            emptyValue === false &&
            !validationValue.includes(false)
        ) {
            uploadFullData();
        }
    }, [companyCertificate, pancard, profilePic, emptyValue]);

    useEffect(() => {
        if (discard) {
            // setBusinessDetails({
            //     fullName: recievedFile?.fullName,
            //     dob: recievedFile?.dob,
            //     mobileNumber: recievedFile?.mobileNumber,
            //     emailAddress: recievedFile?.emailAddress,
            //     bio: recievedFile?.bio,
            //     permanentAddress: {
            //         addressLine1: recievedFile?.permanentAddress.addressLine1,
            //         addressLine2: recievedFile?.permanentAddress.addressLine2,
            //         city: recievedFile?.permanentAddress.city,
            //         state: recievedFile?.permanentAddress.state,
            //         pincode: recievedFile?.permanentAddress.pincode,
            //     },
            //     currentAddress: {
            //         addressLine1: recievedFile?.currentAddress.addressLine1,
            //         addressLine2: recievedFile?.currentAddress.addressLine2,
            //         city: recievedFile?.currentAddress.city,
            //         state: recievedFile?.currentAddress.state,
            //         pincode: recievedFile?.currentAddress.pincode,
            //     },
            // });
            getData();

            setCompanyCertificate((prev) => ({
                ...prev,
                docUrlRecievedFromDB: recievedDocUrls.aadharCard,
                fileObject: null,
            }));

            setPancard((prev) => ({
                ...prev,
                docUrlRecievedFromDB: recievedDocUrls.panCard,
                fileObject: null,
            }));

            setProfilePic((prev) => ({
                ...prev,
                fileObject: null,
                fileLink: null,
                docUrlRecievedFromDB: recievedDocUrls.profilePic,
            }));
        }
    }, [discard]);

    useEffect(() => {
        const errorValues = Object.values(error);
        const validationValue = errorValues.map((value) => value === null);

        if (emptyValue === false && !validationValue.includes(false)) {
            uploadDocuments();
        }
    }, [emptyValue, error]);

    useEffect(() => {
        const keys = Object.keys(businessDetails.permanentAddress);
        const values = Object.values(businessDetails.permanentAddress);

        const arr = keys.map((key) =>
            businessDetails?.permanentAddress[key] ===
                businessDetails?.currentAddress[key]
                ? true
                : false
        );

        arr.includes(false) || values.includes("")
            ? setSameAs(false)
            : setSameAs(true);
    }, [businessDetails]);

    useEffect(() => {
        if (recievedFile) {
            setBusinessDetails({
                companyName: recievedFile?.companyName,
                companyType: recievedFile?.companyType,
                companyPancard: recievedFile?.companyPancard,
                gstInNumber: recievedFile?.gstInNumber,
                mobileNumber: recievedFile?.mobileNumber,
                emailAddress: recievedFile?.emailAddress,
                yearOfEstablishment: recievedFile?.yearOfEstablishment,
                numberOfEmployees: recievedFile?.numberOfEmployees === 0 ? "" : recievedFile?.numberOfEmployees,
                preferredLanguage: recievedFile?.preferredLanguage,
                contactPersonName: recievedFile?.contactPersonName,
                bio: recievedFile?.bio,
                permanentAddress: {
                    addressLine1: recievedFile?.permanentAddress?.addressLine1
                        ? recievedFile?.permanentAddress?.addressLine1
                        : "",
                    addressLine2: recievedFile?.permanentAddress?.addressLine2
                        ? recievedFile?.permanentAddress?.addressLine2
                        : "",
                    city: recievedFile?.permanentAddress?.city
                        ? recievedFile?.permanentAddress?.city
                        : "",
                    state: recievedFile?.permanentAddress?.state
                        ? recievedFile?.permanentAddress?.state
                        : "",
                    pincode: recievedFile?.permanentAddress?.pincode
                        ? recievedFile?.permanentAddress?.pincode
                        : "",
                },
                currentAddress: {
                    addressLine1: recievedFile?.currentAddress?.addressLine1
                        ? recievedFile?.currentAddress?.addressLine1
                        : "",
                    addressLine2: recievedFile?.currentAddress?.addressLine2
                        ? recievedFile?.currentAddress?.addressLine2
                        : "",
                    city: recievedFile?.currentAddress?.city
                        ? recievedFile?.currentAddress?.city
                        : "",
                    state: recievedFile?.currentAddress?.state
                        ? recievedFile?.currentAddress?.state
                        : "",
                    pincode: recievedFile?.currentAddress?.pincode
                        ? recievedFile?.currentAddress?.pincode
                        : "",
                },
            });
            setWebsiteUrl(recievedFile?.websiteUrl);
            setSocialMediaProfile(recievedFile?.socialMediaProfile);
        }
    }, [recievedFile]);

    useEffect(() => {
        getData();
        setBusinessDetails((prev) => ({ ...prev, mobileNumber: mobileNumber }));
        window.scrollTo(0, 0);
    }, []);

    console.log(companyCertificate, "companyCertificate");
    console.log(pancard, "pancard");
    console.log(profilePic, "profilepic");
    console.log(emptyValue, "emptyValue");
    return (
        <>
            <Loader isLoading={isLoading} />
            <div className={mobile_profile_style.parent}>
                <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
                    <Link
                        to="../../mobileProfile/personalProfile"
                        className={styles.backButton}
                    >
                        {ICONS.ArrowBackIcon}
                    </Link>
                    <span>Business Profile Settings</span>
                    <span
                        className={commonStyle.filter_icon_mobile}
                        onClick={handleEditDiscard}
                    >
                        {edit ? `Discard` : ICONS.EditDataIcon}
                    </span>
                </div>
            </div>
            <div className={styles.Profile_Container}>
                <div className={styles.edit_save_button_section}>
                    <EditButton edit={edit} onClick={handleEditDiscard} />
                    <SaveChangesButton
                        formDetails={businessDetails}
                        edit={edit}
                        setEmptyValue={setEmptyValue}
                    />
                </div>

                <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
                    <p className={styles.text}>Business Details</p>
                    <p className={styles.mandatory_data}>
                        (* Indicates required question)
                    </p>
                </div>
                <div className={styles.Main_Container}>
                    <p
                        className={`${styles.Profile_heading_onMobile} ${styles.hideOnDesktop}`}
                    >
                        Profile Picture
                    </p>
                    <div className={styles.profile_personal_details}>
                        <img
                            src={
                                profilePic.fileLink
                                    ? profilePic.fileLink
                                    : profilePic.docUrlRecievedFromDB
                                        ? profilePic.docUrlRecievedFromDB
                                        : profilePic.emptyProfilePic
                            }
                            alt="Profile"
                            className={styles.profile_img}
                        />
                        <div className={styles.camera_outer_dot}>
                            <input
                                disabled={edit ? false : true}
                                type="file"
                                accept="image/jpeg,image/jpg"
                                id="imageUpload"
                                className={styles.file_input}
                                onChange={handleProfilePic}
                            />
                            <label htmlFor="imageUpload">
                                <img
                                    src="/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/CameraIcon.svg"
                                    alt="Camera Icon"
                                    className={styles.cameraIcon}
                                />
                            </label>
                        </div>
                        <div className={`${styles.Profile_data} ${styles.hideOnMobile}`}>
                            <p className={styles.name}>{recievedFile?.companyName}</p>
                            <p className={styles.label}>{recievedFile?.emailAddress}</p>
                            {error.profilePic && (
                                <p className={styles.error}>{error.profilePic}</p>
                            )}
                            {(emptyValue === false || emptyValue === true) &&
                                !profilePic.docUrlRecievedFromDB &&
                                !profilePic.fileLink && (
                                    <p className={styles.error}>required*</p>
                                )}
                        </div>
                    </div>
                </div>
                <div className={styles.heading}>Business Profile Settings</div>
                <div className={styles.Main_Container}>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Company Name <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.companyName}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        companyName: e.target.value,
                                    })
                                }
                                placeholder="company name"
                            />
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Company Type <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                value={businessDetails?.companyType}
                                emptyValue={emptyValue}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        companyType: e.target.value,
                                    })
                                }
                                type="string"
                                placeholder="Company type"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Email address <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.emailAddress}
                                onChange={(e) => handleChange(e)}
                                type="email"
                                name="emailAddress"
                                placeholder="Anymail@gmail.com"
                            />
                            {error.emailAddress && (
                                <p className={styles.error}>{error.emailAddress}</p>
                            )}
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                GSTIn Number <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.gstInNumber}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        gstInNumber: e.target.value,
                                    })
                                }
                                type="string"
                                name="GSTIn Number"
                                placeholder="1234 567 890"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Mobile Number <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={true}
                                emptyValue={emptyValue}
                                value={businessDetails?.mobileNumber}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                name="mobileNumber"
                                placeholder="1234 567 890"
                            />
                            {error.mobileNumber && (
                                <p className={styles.error}>{error.mobileNumber}</p>
                            )}
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Company Pan Card <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.companyPancard}
                                onChange={(e) => handleChange(e)}
                                type="string"
                                name="companyPancard"
                                placeholder="company pancard"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Years of establishment{" "}
                                <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.yearOfEstablishment}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        yearOfEstablishment: e.target.value,
                                    })
                                }
                                type="string"
                                name="Years of establishment"
                                placeholder="Years of establishment"
                            />
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Number of employees<span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.numberOfEmployees}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        numberOfEmployees: e.target.value,
                                    })
                                }
                                type="number"
                                name="number Of Employees"
                                placeholder="256"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Preferred Languages<span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.preferredLanguage}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        preferredLanguage: e.target.value,
                                    })
                                }
                                type="string"
                                name="Preferred Languages"
                                placeholder="Hindi, English,Tamil, Marathi"
                            />
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Contact person Name <span className={styles.star_mark}>*</span>
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={emptyValue}
                                value={businessDetails?.contactPersonName}
                                onChange={(e) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        contactPersonName: e.target.value,
                                    })
                                }
                                type="string"
                                name="contact person"
                                placeholder="Satish Pradhan"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>Website Url (if any)</label>
                            <Inputbox
                                readOnly={!edit}
                                emptyValue={false}
                                value={websiteUrl}
                                onChange={(e) => setWebsiteUrl(e.target.value)}
                                type="string"
                                name="websiteUrl"
                                placeholder="Anymail@gmail.com"
                            />
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Social Media profile (if any)
                            </label>
                            <Inputbox
                                readOnly={!edit}
                                value={socialMediaProfile}
                                emptyValue={false}
                                onChange={(e) => setSocialMediaProfile(e.target.value)}
                                type="string"
                                name="social media profile"
                                placeholder="insta: mann123, fb: manan_singh"
                            />
                        </div>
                    </div>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data_Bio}>
                            <label className={styles.form_label}>
                                Bio <span className={styles.star_mark}>*</span>
                            </label>
                            <textarea
                                value={businessDetails?.bio}
                                maxLength={300}
                                name="bio"
                                onChange={(e) => handleChange(e)}
                                disabled={edit ? false : true}
                                style={{
                                    color: !edit ? "var(--grey)" : "var(--black)",
                                    backgroundColor: !edit
                                        ? "var(--light-grey)"
                                        : "var(--lavender-blue)",
                                }}
                                placeholder="Expert in planning, executing, and closing projects efficiently.Committed to maintaining high standards of quality and compliance with industry regulations."
                            ></textarea>
                            {emptyValue && businessDetails?.bio === "" && (
                                <p className={styles.error}>* required</p>
                            )}
                            {error.bio && <p className={styles.error}>{error.bio}</p>}
                        </div>
                    </div>
                </div>

                <div className={styles.heading}>Address</div>
                <div className={styles.Main_Container}>
                    <div className={styles.main_container_form}>
                        <div className={`${styles.form_data} ${styles.form_data_one}`}>
                            <label
                                className={`${styles.form_label} ${styles.permanent_label}`}
                            >
                                Company Address <span className={styles.star_mark}>*</span>
                            </label>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Address line 1 <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit}
                                    type="string"
                                    placeholder="address line 1"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.permanentAddress?.addressLine1}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Address line 2 <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit}
                                    type="string"
                                    placeholder="address line 2"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.permanentAddress?.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    City <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit}
                                    type="string"
                                    placeholder="city"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.permanentAddress?.city}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    State <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit}
                                    type="string"
                                    placeholder="state"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.permanentAddress?.state}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Pin code <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit}
                                    type="string"
                                    placeholder="pincode"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.permanentAddress?.pincode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Current Address <span className={styles.star_mark}>*</span>
                            </label>
                            <label className={styles.checkbox_container}>
                                <input
                                    type="checkbox"
                                    checked={sameAs ? true : false}
                                    onChange={handleSameAs}
                                    disabled={!edit}
                                />
                                <span className={styles.checkbox_data}>
                                    Same as Permanent address
                                </span>
                            </label>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Address line 1 <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit || sameAs}
                                    type="string"
                                    placeholder="address line 1"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.currentAddress?.addressLine1}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Address line 2 <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit || sameAs}
                                    type="string"
                                    placeholder="address line 2"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.currentAddress?.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    City <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit || sameAs}
                                    type="string"
                                    placeholder="city"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.currentAddress?.city}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    State <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit || sameAs}
                                    type="string"
                                    placeholder="state"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.currentAddress?.state}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={styles.form_sub_data}>
                                <label className={styles.form_label}>
                                    Pin code <span className={styles.star_mark}>*</span>
                                </label>
                                <Inputbox
                                    readOnly={!edit || sameAs}
                                    type="string"
                                    placeholder="pincode"
                                    emptyValue={emptyValue}
                                    value={businessDetails?.currentAddress?.pincode}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.heading}>Other Important Details</div>
                <div className={styles.Main_Container}>
                    <div className={styles.main_container_form}>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                Comapny Registration Certificate{" "}
                                <span className={styles.star_mark}>*</span>
                                {companyCertificate.docUrlRecievedFromDB && (
                                    <a
                                        target="_blank"
                                        href={companyCertificate.docUrlRecievedFromDB}
                                        className={styles.received_doc_url}
                                    >
                                        view
                                    </a>
                                )}
                            </label>
                            <UploadFile
                                file={companyCertificate}
                                handleFileUpload={(value) => handleCompanyCertificate(value)}
                                setEmptyValue={setEmptyValue}
                                setFile={setCompanyCertificate}
                                inputId="companyCertificate"
                                disabledValue={!edit}
                                acceptedType=".pdf"
                            />

                            {error.aadhar ? (
                                <p className={styles.error}>{error.aadhar}</p>
                            ) : (
                                edit &&
                                companyCertificate.fileObject === null &&
                                companyCertificate.docUrlRecievedFromDB === null && (
                                    <p className={styles.error}>* required</p>
                                )
                            )}
                        </div>
                        <div className={styles.form_data}>
                            <label className={styles.form_label}>
                                PAN card <span className={styles.star_mark}>*</span>
                                {pancard.docUrlRecievedFromDB && (
                                    <a
                                        target="_blank"
                                        href={pancard.docUrlRecievedFromDB}
                                        className={styles.received_doc_url}
                                    >
                                        view
                                    </a>
                                )}
                            </label>
                            <UploadFile
                                file={pancard}
                                handleFileUpload={(value) => handlePanCard(value)}
                                setEmptyValue={setEmptyValue}
                                setFile={setPancard}
                                inputId="pancard"
                                disabledValue={!edit}
                                acceptedType=".pdf"
                            />

                            {error.pan ? (
                                <p className={styles.error}>{error.pan}</p>
                            ) : (
                                edit &&
                                pancard.fileObject === null &&
                                pancard.docUrlRecievedFromDB === null && (
                                    <p className={styles.error}>* required</p>
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.footer_data} ${styles.hideOnMobile}`}>
                    <Link to="../financial">
                        <Button
                            padding="10px 18px"
                            text="Next >"
                            backgroundColor="var(--yellow)"
                            color="var(--smoky-black)"
                            disabled={edit}
                        />
                    </Link>
                </div>
            </div>
            <div className={`${styles.mobileView_footerBtn} ${styles.hideOnDesktop}`}>
                <SaveChangesButton
                    width="100%"
                    formDetails={businessDetails}
                    edit={edit}
                    setEmptyValue={setEmptyValue}
                />
            </div>
        </>
    );
};
export default BusinessDetails;
