// import { ICONS } from "../../../Assets/Icons";
import styles from "../Components/ThisYearDropdownList.module.css";
import commonStyle from "../../ServiceProviderDashboard.module.css";
import { useState } from "react";

const ThisYearDropdownList = ({ setDropdownOption, dropdownOption, setOpen }) => {


  const handleclose = () => {
    setOpen(false);
  };

  const handleFilter = (e) => {
    setDropdownOption(e.target.value);
  };


  return (
    <>
      <div
        className={commonStyle.DropdownList_parent}
        onClick={() => handleclose()}
      >
        <div className={commonStyle.DropdownList_Container}>
          <div className={styles.DropdownList}>
            <label className={styles.DropdownList_data}>
              <input
                onChange={handleFilter}
                name="radioData"
                value="This year"
                checked={dropdownOption === 'This year' ? true : false}
                className={styles.thisYear_radiobtn}
                type="radio"
              />
              <span className={styles.text}>This Year</span>
            </label>
            <label

              className={styles.DropdownList_data}
            >
              <input
                name="radioData"
                value={'This month'}
                onChange={handleFilter}
                className={styles.thisYear_radiobtn}
                checked={dropdownOption === 'This month' ? true : false}
                type="radio"
              />
              <span className={styles.text}>This Month</span>
            </label>
            <label

              className={styles.DropdownList_data}
            >
              <input
                name="radioData"
                value={"This week"}
                onChange={handleFilter}
                className={styles.thisYear_radiobtn}
                checked={dropdownOption === 'This week' ? true : false}
                type="radio"
              />
              <span className={styles.text}>This Week</span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
export default ThisYearDropdownList;
