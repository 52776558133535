import Styles from "./filterByOrder.module.css";
import "../../../../../../utils/utilsCss/utilsCss.css";

import { useEffect, useState } from "react";

import { ICONS } from "../../../../../../Assets/Icons";

import Button from "../../../../../Components/Button/Button";
import axios from "axios";
import { toast } from "react-toastify";
import axios2 from "../../../../../../axios2";

const FilterByOrder = ({
  mobileView,
  onClose,
  orderTypes,
  setOrderTypes,
  localOrderMobileType,
  setLocalMobileOrderType,
}) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    orderTypes || localOrderMobileType
  );

  console.log(localOrderMobileType, "localOrderMobileType");

  // ------------------------------API----------------------------------------

  const categoryId = `dac82722-42d0-11ef-9f9b-325096b39f47`;

  const fetchSubcategory = async () => {
    try {
      let url = `${baseUrl}/api/v1/sectors/categories/subCategories`;
      const res = await axios2.post(
        url,
        {
          categoryId,
        },
        { withCredentials: true }
      );
      setSubCategories(res.data.data.subCategories);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  //----------------------------------------------------------

  const handleClick = (cat) => {
    if (!selectedCategory.includes(cat)) {
      setSelectedCategory((selectedCategory) => [...selectedCategory, cat]);
    } else {
      const data = [...selectedCategory].filter((catt) => catt !== cat);
      setSelectedCategory(() => data);
    }
  };

  const handleClear = () => {
    setSelectedCategory([]);
    setOrderTypes([]);
    onClose();
  };

  const handleApplyFilter = () => {
    setOrderTypes(selectedCategory);
    onClose();
  };

  useEffect(() => {
    if (setLocalMobileOrderType) {
      setLocalMobileOrderType(() => selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (localOrderMobileType) {
      setSelectedCategory(localOrderMobileType);
    }
  }, [localOrderMobileType]);

  useEffect(() => {
    fetchSubcategory();
  }, []);

  console.log(selectedCategory, 'selectedCategory')

  return (
    <div
      className={mobileView ? Styles.filterByOrderMobile : Styles.filterByorder}
    >
      <div className={Styles.header}>
        <h1>Order SubCategory</h1>
        {!mobileView && (
          <span onClick={() => onClose()}>{ICONS.closeIcon}</span>
        )}
      </div>
      <div className={Styles.order_list}>
        {subCategories.map((cat) => (
          <div
            key={cat.id}
            onClick={() => handleClick(cat.subCategoryName)}
            className={
              selectedCategory.includes(cat.subCategoryName)
                ? `${Styles.order_type_name} ${Styles.order_type_name_selected}`
                : Styles.order_type_name
            }
          >
            {cat.subCategoryName}
          </div>
        ))}
      </div>
      {!mobileView && (
        <div>
          <hr className={Styles.line}></hr>
          <p className={Styles.text}>*You can choose multiple order types</p>
        </div>
      )}

      {!mobileView && (
        <div className={Styles.footer}>
          <Button
            onClick={() => handleClear()}
            backgroundColor="var(--white)"
            borderColor="var(--grey-80)"
            color="var(--smoky-black)"
            text="Clear"
            width="100%"
            padding="10px 18px"
          />
          <Button
            onClick={() => handleApplyFilter()}
            backgroundColor="var(--yellow)"
            color="var(--smoky-black)"
            text="Apply Filter"
            width="100%"
            padding="10px 18px"
          />
        </div>
      )}
    </div>
  );
};

export default FilterByOrder;
