// import { plugins } from "chart.js";
import React, { useEffect, useState } from "react";
// import { Doughnut } from "react-chartjs-2";
import { Chart } from "primereact/chart";

import styles from "../../../../commonCss.module.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ICONS } from "../../../../Assets/Icons/index";
import axios2 from "../../../../axios2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const RevenueBreakoutChart = ({ selectedYear, dropdownOption }) => {
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;
  const [dataFetched, setDataFetched] = useState(null);
  console.log(dataFetched, 'dataFetched')
  const totalAmount =
    Number(dataFetched?.totalAmount[0]?.totalCompletedPrice) +
    Number(dataFetched?.commissionCharges)

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const fetchData = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/sales/overview/by_year_month_week`,
        {
          year: selectedYear,
          period: dropdownOption.split(" ")[1],
          userId,
          sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setDataFetched(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };


  const data = {
    labels: ["Comissions & Others", "Total sales"],
    datasets: [
      {
        data: [
          dataFetched?.commissionCharges,
          dataFetched?.totalAmount[0]?.totalCompletedPrice,
        ],
        backgroundColor: ["#002F77", "#36A2EB"],
      },
    ],
    spacing: 2,
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltip: {
      enabled: true,
    },
    layout: {
      padding: {
        left: 0,
      },
    },
    maintainAspectRatio: false,
    aspectRatio: 1.8,
    responsive: true,
    cutout: "80%",
  };

  useEffect(() => {
    if (userId && sectorId) {
      fetchData();
    }
  }, [selectedYear, dropdownOption, userId, sectorId]);

  return (
    <div className={styles.breakoutChart}>
      <div className={styles.total_revenue}>
        <p className={styles.total_revenue_amount}>
          &#8377; {totalAmount ? totalAmount : 0}.00 {ICONS.upsellIcon}
        </p>
        <p className={styles.total_revenue_title}>Total Revenue</p>
      </div>

      <div className={styles.revenue_chart_section}>
        <div className={styles.revenue_breakout_legend1}>
          <p>
            <b>
              &#8377; {dataFetched?.totalAmount[0]?.totalCompletedPrice || 0}
            </b>
          </p>
          <div className={styles.legend_title1}>
            <p>{data?.labels[1]}</p>
            <span
              style={{
                backgroundColor: data?.datasets[0]?.backgroundColor[1],
                width: "10px",
                height: "10px",
                borderRadius: "50%",
              }}
            ></span>
          </div>
        </div>
        <div className={styles.revenueBreakout_doughnut_parent}>
          <Chart
            className={styles.breakout_doughtnut_chart}
            type="doughnut"
            data={data}
            options={options}
          />
        </div>

        <div className={styles.revenue_breakout_legend2}>
          <p>
            <b>&#8377; {dataFetched?.commissionCharges || 0}</b>{" "}
          </p>
          <div className={styles.legend_title2}>
            <span
              style={{
                backgroundColor: data?.datasets[0]?.backgroundColor[0],
                minWidth: "10px",
                minHeight: "10px",
                borderRadius: "50%",
              }}
              className={styles.comissions_dot}
            ></span>
            <p> {data?.labels[0]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueBreakoutChart;
