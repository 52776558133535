import { ICONS } from "../../../../Assets/Icons";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./DragAndDropComponent.module.css";
import { ReactComponent as PageIcon } from "../../../../Assets/Icons/Assets/Page.svg";

const DragAndDrop = ({
    file = [],
    setFile,
    keyTitle,
    documentType,
    userId,
    documentDescription,
    displayBrowseOption,
    acceptedType,
    setFileError,
    width,
    disabled,
}) => {
    const imageExtensions = ["png", "jpeg", "jpg", "webp", "svg", "bmp"];

    const acceptedTypes = {
        "image/*": [".png", ".jpeg", ".jpg", ".webp", ".svg", ".bmp"],
        "text/plain": [".txt", ".md"],
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
            ".docx",
        ],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
        ],
    };

    const accept = acceptedType?.reduce((acc, crr) => {
        for (var key in acceptedTypes) {
            acc = key === crr ? { ...acc, [key]: acceptedTypes[crr] } : acc;
        }
        return acc;
    }, {});



    const handleRemoveFileToBeUploaded = (fileKey) => {
        const data = [...file];

        const updateData = data.filter((file) => file?.key !== fileKey);

        setFile(updateData);
    };

    const handleRemoveExistingFile = (docId) => {
        const data = [...file];
        const updateData = data.filter((file) => file?.docId !== docId);

        console.log(updateData, "updatedData from handleRemoveExistingFile");

        setFile(updateData);
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            let data = [];
            acceptedFiles?.forEach((value) => {
                if (value?.size <= 10 * 1024 * 1024) {
                    data = [
                        ...data,
                        {
                            key: `${userId}/${keyTitle}/${value.name}-${Date.now()}.${value?.type.split("/")[1]
                                }`,
                            documentType: documentType,
                            documentDescription: `${documentDescription} ${value.name}`,
                            fileObject: value,
                            recievedDocId: null,
                        },
                    ];
                } else {
                    console.log("ggggg");
                    setFileError(`each file size limit is 10MB.`);
                }
            });

            console.log(data, "data after if");
            console.log(file);
            setFile((prev) => [...prev, ...data]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: accept,
        multiple: true,
        disabled: disabled,
        maxSize: 524288000, // 500MB
    });
    console.log(accept)
    return (
        <>
            <div
                className={styles.dropfile_container}
                {...getRootProps()}
                style={{ display: displayBrowseOption ? "flex" : "none" }}
            >
                <span className={styles.dropfile_Icon}>{ICONS.CloudUploadIcon}</span>
                <div className={styles.dropfile_container_data}>
                    <p className={styles.hideOnMobile}>
                        Drop your file here or
                        <input {...getInputProps()} />
                        <span className={styles.browseData}>Browse</span>
                    </p>
                    <p className={styles.hideOnDesktop}>
                        <input {...getInputProps()} />
                        <span className={styles.browseData}>Browse</span>
                    </p>
                    <div className={styles.dropfileValues}>
                        <p>MAX file size: 10MB</p>
                        <p>
                            Supported file types:{" "}
                            {Object.values(accept)?.map((value) => (
                                <span>{`${value} `} </span>
                            ))}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.previewContainer}>
                {file?.map((doc, index) => (
                    <div key={index}>
                        {imageExtensions.includes(doc?.fileObject?.type.split("/")[1]) ? (
                            <div className={styles.image_preview_parent}>
                                <img
                                    style={{ width: width }}
                                    className={styles.image_preview}
                                    src={URL.createObjectURL(doc.fileObject)}
                                    alt={doc.fileObject.name}
                                />
                                {!disabled && (
                                    <span
                                        onClick={() => handleRemoveFileToBeUploaded(doc.key)}
                                        className={styles.preview_cancel_icon}
                                    >
                                        {ICONS.cancelIconRound}
                                    </span>
                                )}
                            </div>
                        ) : doc.docUrl &&
                            imageExtensions.includes(doc.docType.split("/")[1]) ? (
                            <div className={styles.image_preview_parent}>
                                <img src={doc.docUrl} alt="" className={styles.image_preview} />
                                {!disabled && (
                                    <span
                                        onClick={() => handleRemoveExistingFile(doc?.docId)}
                                        className={styles.preview_cancel_icon}
                                    >
                                        {ICONS.cancelIconRound}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div className={styles.doc_preview}>
                                <span>
                                    <PageIcon className={styles.doc_preview_page_icon} />
                                </span>
                                <span className={styles.details_container}>
                                    <span className={styles.doc_preview_type}>
                                        {doc?.fileObject?.type?.split("/")[1] || doc.docType.split("/")[1]}
                                        {/* either render locally upoladed file type or retrieved from url */}
                                    </span>
                                    <span className={styles.doc_preview_name}>
                                        {doc?.fileObject?.name?.length > 15
                                            ? `${doc.fileObject.name?.substring(0, 14)}...`
                                            : doc?.fileObject?.name}
                                        {doc?.docName?.length > 15
                                            ? `${doc?.docName?.substring(0, 14)}...`
                                            : doc?.docName}
                                        {/* either render locally upoladed file name or retrieved from url */}
                                    </span>
                                    {!disabled && (
                                        <span
                                            onClick={() =>
                                                doc.docUrl
                                                    ? handleRemoveExistingFile(doc.docId)
                                                    : handleRemoveFileToBeUploaded(doc?.key)
                                            }
                                            className={styles.preview_cancel_icon}
                                        >
                                            {ICONS.cancelIconRound}
                                        </span>
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default DragAndDrop;
