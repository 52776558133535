export const SupportFAQ = [
  {
    question:
      "How do I handle situations where the service requirements change after the service provider reaches the location?",
    answer:
      "If a customer requests changes to the service requirements after you’ve arrived at the location, first communicate with the support team to confirm the new requirements. You may need to update the service details accordingly. If the changes are significant, discuss with the support team the possibility of renegotiating the order.",
  },
  {
    question: "How do I update my service offerings?",
    answer:
      "To update the services you offer, go to the “Services” section in the app. Select the service you want to edit, then click on the “Edit” button. Make the necessary changes to the service details and click “Save Changes” to update your service information.",
  },
  {
    question: "How do I get paid for the services I provide?",
    answer:
      "Payments for the services you provide through Connect app will be processed after completion of the order. After processing, these funds are transferred to your linked bank account according to the established payment schedule.",
  },
  {
    question: "How do I resolve payment issues or discrepancies?",
    answer:
      " Ensure that your bank details are correctly entered in your profile. If the issue isn’t resolved, try to contact our support team through the app or email us for further assistance.",
  },
  {
    question: "What should I do if I’m not receiving any service requests?",
    answer:
      "If you’re not receiving service requests, check that your profile and service offerings are complete and up to date. Ensure your settings are correct and that your service areas are properly defined. If the issue continues, contact support for assistance.",
  },
];
