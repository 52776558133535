import styles from "../Styles/ProfileCommonCss.module.css";
import { ICONS } from "../../../Assets/Icons";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { Link } from "react-router-dom";
import commonStyle from "../../ServiceProviderDashboard.module.css";
import { useState } from "react";
import Header from "../../Components/Header/Header";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ProfileSideBar from "../Components/ProfileSideBar/ProfileSideBar";
import settingsStyles from "../../Profile_Settings/Settings.module.css";
import navButtonStyles from "../../Components/NavButton/NavButton.module.css";

const BillingHistory = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleDropdownclose = () => {
    setOpen(false);
  };

  const BillingHistoryData = [
    // {
    //   text: "Interior Designers",
    // },
    // {
    //   text: "Interior Designers",
    // },
    // {
    //   text: "Interior Designers",
    // },
    // {
    //   text: "Interior Designers",
    // },
    // {
    //   text: "Interior Designers",
    // },
    // {
    //   text: "Interior Designers",
    // },
  ];

  return (
    <>
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link to="../../mobileProfile" className={styles.backButton}>
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Invoice</span>
          {/* <span className={commonStyle.filter_icon_mobile}>{ICONS.filterIconMobileWhite}</span> */}
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Billing History</p>
        </div>

        <div className={styles.Security_Main_Container}>
          {BillingHistoryData?.length > 0 ? BillingHistoryData.map((item, index) => (
            <div className={styles.security_form_data} key={index}>
              <div div className={styles.textData}>
                <p className={styles.billing_data}>{item.text}</p>
                <p className={styles.checkbox_data}>
                  <span className={styles.location}>
                    INVOICE# 021345 {ICONS.EllipseIcon} 04/06/24
                  </span>
                </p>
              </div>
              <div
                className={`${styles.BillingHistory_footer} ${styles.hideOnMobile}`}
              >
                <p>{ICONS.VisibilityIcon}</p>
                {/* <p>{ICONS.DownloadIcon}</p>
                <p>{ICONS.DeleteIcon}</p> */}
              </div>
              <div className={styles.hideOnDesktop}>
                <p onClick={() => setOpen(true)}>{ICONS.threeDotsIcon}</p>
              </div>
            </div>

          ))
            : <div className={styles.emptybox} >
              No bills to show !
            </div>}
        </div>

        <div
          className={commonStyle.DropdownList_parent}
          style={{ display: open ? "block" : "none" }}
          onClick={() => handleDropdownclose()}
        >
          <div className={commonStyle.DropdownList_Container}>
            <div className={commonStyle.billing_container}>
              <div className={commonStyle.DropdownList_data}>
                <p className={commonStyle.billing_logout_text}>
                  {ICONS.VisibilityIcon}
                  <span>View</span>
                </p>
              </div>
              {/* <div className={commonStyle.DropdownList_data}>
                <p className={commonStyle.billing_logout_text}>
                  {ICONS.DownloadIcon}
                  <span>Download</span>
                </p>
              </div>
              <div className={commonStyle.DropdownList_data}>
                <p className={commonStyle.billing_logout_text}>
                  {ICONS.DeleteIcon}
                  <span>Delete</span>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistory;
