import { NavLink } from "react-router-dom";
import { ICONS } from "../../../Assets/Icons";
import Styles from "./MobileBar.module.css";
import { useEffect, useState } from "react";

const MobileBar = () => {
  const [active, setActive] = useState("home"); // url will be changed first

  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    setActiveLink(() => window.location.pathname);
  }, [active]); // once url is changed on page change , setActiveLink will be updated as the changed page

  return (
    <div className={`${Styles.navbar} ${Styles.display}`}>
      <NavLink
        to="/home"
        className={Styles.option}
        onClick={() => setActive("home")}
      >
        <span
          className={
            activeLink === "/home"
              ? Styles.selectedOptionIcon
              : Styles.optionIcon
          }
        >
          {activeLink === "/home" ? ICONS.homeFillBlue : ICONS.homeIconGrey}
        </span>
        <p
          style={{
            color: activeLink === "/home" ? "var(--navy-blue)" : "var(--grey)",
          }}
        >
          Home
        </p>
      </NavLink>
      <NavLink
        to="/order"
        className={Styles.option}
        onClick={() => setActive("order")}
      >
        <span
          className={
            activeLink === "/order"
              ? Styles.selectedOptionIcon
              : Styles.optionIcon
          }
        >
          {activeLink === "/order" ? ICONS.orderFillBlue : ICONS.orderIconGrey}
        </span>
        <p
          style={{
            color: activeLink === "/order" ? "var(--navy-blue)" : "var(--grey)",
          }}
        >
          Order
        </p>
      </NavLink>
      <NavLink
        to="/serviceManagement"
        className={Styles.option}
        onClick={() => setActive("serviceManagement")}
      >
        <span
          className={
            activeLink === "/serviceManagement"
              ? Styles.selectedOptionIcon
              : Styles.optionIcon
          }
        >
          {activeLink === "/serviceManagement"
            ? ICONS.serviceManagmentIcon
            : ICONS.serviceManagmentIconOutline}
        </span>
        <p
          style={{
            color:
              activeLink === "/serviceManagement"
                ? "var(--navy-blue)"
                : "var(--grey)",
          }}
        >
          Service
        </p>
      </NavLink>
      {/* <NavLink
        to="/message"
        className={Styles.option}
        onClick={() => setActive("message")}
      >
        <span
          className={
            activeLink === "/message"
              ? Styles.selectedOptionIcon
              : Styles.optionIcon
          }
        >
          {activeLink === "/message"
            ? ICONS.messageFillBlue
            : ICONS.messageIconOutline}
        </span>
        <p>Message</p>
      </NavLink> */}
      <NavLink
        to="/mobileProfile"
        className={Styles.option}
        onClick={() => setActive("mobileProfile")}
      >
        <span
          className={
            activeLink?.includes("mobileProfile")
              ? Styles.selectedOptionIcon
              : Styles.optionIcon
          }
        >
          {activeLink?.includes("mobileProfile")
            ? ICONS.profileFillBlue
            : ICONS.profileIconGrey}
        </span>
        <p
          style={{
            color:
              activeLink?.includes("mobileProfile")
                ? "var(--navy-blue)"
                : "var(--grey)",
          }}
        >
          Profile
        </p>
      </NavLink>
    </div>
  );
};

export default MobileBar;
