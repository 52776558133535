import styles from "../../Styles/TableCommonCss.module.css";
import "../../../../utils/utilsCss/utilsCss.css";
import acceptedStyle from "./Accepted.module.css";
import React, { useCallback, useEffect, useState } from "react";

import { ICONS } from "../../../../Assets/Icons";
import JobCard from "./Sections/JobCard/JobCard";
import Invoice from "./Sections/Invoice/Invoice";
import Modal from "../../../../utils/modal/Modal";
import Button from "../../../Components/Button/Button";
import VerifiedOtpPopup from "./Sections/OTP/askForOTP/askForOTP";
import RecieveAmount from "./Sections/RecieveAmount/RecieveAmount";
import RejectionPopup from "./Sections/RejectMessage/RejectMessage";
import MessagePopup from "./Sections/ComposeMessage/ComposeMessage";
import VerifiedOTP from "./Sections/OTP/OTPsucessCard/OTPsucessCard";
import PaymentDetails from "./Sections/PaymentDetails/PaymentDetails";
import PaymentSuccessful from "./Sections/PaymentSuccessful/PaymentSuccessful";
import OrderSearchSection from "../../Components/OrderSearchSection/OrderSearchSection";
import Pagination from "../../Components/Pagination/Pagination";
import Loader from "../../../Components/Loader/Loader";
import axios from "axios";
import axios2 from "../../../../axios2";
import EmptyStateComponent from "../../../Components/EmptyStateComponent/EmptyStateComponent";
import { toast } from "react-toastify";
import useRetriveFileHook from "../../../../hooks/useRetriveFileHook";
import Attachment from "../../Components/Attachments/Attachments";

const Accepted = ({
  mobileDateRange,
  mobileOrderTypes,
  mobileAmount,
  renderAccepted,
  setRenderRejected,
  setRenderCompleted,
}) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  // const mobileNumber = useSelector((state) => state.auth.userMobileNumber);
  // const sectorId = useSelector((state) => state.auth.currSectorId);
  const orderItemBookingId = localStorage.getItem('orderItemBookingId')
  const workInProgress = localStorage.getItem('workInProgress')
  const [useCase, setUseCase] = useState("Started Work");
  const [OrderListNewData, setOrderListNewData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [orderItemBookingId, setOrderItemBookingId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date("2020-03-11"),
    new Date("9999-01-01"),
  ]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [amount, setAmount] = useState([0, 10000]);
  const [search, setSearch] = useState(null);

  const [show, setShow] = useState(false);
  const [screen, setScreen] = useState("");
  // const [otpVerified, setOtpVerified] = useState(false);
  // const [workInProgress, setWorkInProgress] = useState(false);
  const [verificationPending, setVerificationPending] = useState(false);
  const [jobStarted, setJobStarted] = useState(false);
  // const [paymentDone, setPaymentDone] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState("online");
  const [paymentRecieved, setPaymentRecieved] = useState(false);
  const [startResendTimer, setStartResendTimer] = useState(false);

  const [showAttachment, setShowAttachment] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const { attachmentUrls, retriveDocuments } = useRetriveFileHook(
    setIsLoading,
    showAttachment
  );

  const showAttachmentFunction = (attachments) => {
    console.log(attachments);
    attachments?.forEach((id) => retriveDocuments(id));
    // console.log(attachments);
    setShowAttachment(true);
  };


  const handleModal = (value) => {
    setScreen(value);
    setShow(true);
  };

  // ---------------------------------- API --------------------------------

  const fetchAccepetdServiceJobs = useCallback(async () => {
    const screenWidth = window.innerWidth;
    try {
      setIsLoading(true);
      let url = `${baseUrl}/api/v1/order-management/service-provider`;
      let reqBody = {
        userId,
        status: "Accepted",
        page: currPage,
        limit: 5
      };
      if (screenWidth <= 768) {
        if (mobileDateRange) {
          const dateObj1 = new Date(mobileDateRange[0]);
          const dateObj2 = new Date(mobileDateRange[1]);
          const isoDateStart = dateObj1.toISOString();
          const isoDateEnd = dateObj2.toISOString();
          // url += `&dateStart=${isoDateStart}&dateEnd=${isoDateEnd}`;
          reqBody = { ...reqBody, dateStart: isoDateStart, dateEnd: isoDateEnd };

        }
        if (mobileAmount) {
          // url += `&priceStart=${mobileAmount[0]}&priceEnd=${mobileAmount[1]}`;
          reqBody = { ...reqBody, priceStart: mobileAmount[0], priceEnd: mobileAmount[1] };

        }
        if (mobileOrderTypes && mobileOrderTypes.length !== 0) {
          let subCategoryStr = ``;
          for (let i = 0; i < mobileOrderTypes.length; i++) {
            if (i === mobileOrderTypes.length - 1)
              subCategoryStr += mobileOrderTypes[i];
            else subCategoryStr += mobileOrderTypes[i] + ",";
          }
          // url += `&subCategory=` + subCategoryStr;
          reqBody = { ...reqBody, subCategory: subCategoryStr };
        }
      } else {
        if (dateRange) {
          const dateObj1 = new Date(dateRange[0]);
          const dateObj2 = new Date(dateRange[1]);
          const isoDateStart = dateObj1.toISOString();
          const isoDateEnd = dateObj2.toISOString();
          // url += `&dateStart=${isoDateStart}&dateEnd=${isoDateEnd}`;
          reqBody = { ...reqBody, dateStart: isoDateStart, dateEnd: isoDateEnd };
        }
        if (amount) {
          // url += `&priceStart=${amount[0]}&priceEnd=${amount[1]}`;
          reqBody = { ...reqBody, priceStart: amount[0], priceEnd: amount[1] };
        }
        console.log(orderTypes, 'orderTypes')
        if (orderTypes) {
          let subCategoryStr = ``;
          for (let i = 0; i < orderTypes.length; i++) {
            if (i === orderTypes.length - 1) subCategoryStr += orderTypes[i];
            else subCategoryStr += orderTypes[i] + ",";
          }
          // console.log(subCategoryStr)
          // url += `&subCategory=` + subCategoryStr;
          reqBody = { ...reqBody, subCategory: subCategoryStr };
        }
      }
      console.log(url);
      const res = await axios2.post(
        url,
        reqBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(res, "res");
      console.log(orderTypes, 'orderTypes')
      setOrderListNewData(res.data.data.services);
      setTotalPages(res.data.data.totalPages);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    [
      currPage,
      dateRange,
      amount,
      orderTypes,
      mobileDateRange,
      mobileOrderTypes,
      mobileAmount,
      renderAccepted,
      setOrderListNewData,
      setTotalPages,
    ],
  ]);


  const jobCompleted = async () => {
    if (orderItemBookingId) {
      try {
        let url = `${baseUrl}/api/v1/order-management/service-provider/complete`;
        const res = await axios2.post(url, {
          orderItemBookingId
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        console.log(res);
        fetchAccepetdServiceJobs();
        localStorage.removeItem('workInProgress');
        localStorage.removeItem('orderItemBookingId');
        setRenderCompleted((prev) => prev + 1);
        toast.success('Well done, Job completed')
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
          console.log(error);
        } else {
          console.log(error)
        }
      }
    }
  }

  useEffect(() => {
    fetchAccepetdServiceJobs();
  }, [
    dateRange,
    amount,
    orderTypes,
    mobileDateRange,
    mobileOrderTypes,
    mobileAmount,
    currPage,
    renderAccepted,
  ]);

  const workHandler = async (useCase) => {
    try {
      setIsLoading(true);
      let url = `${baseUrl}/api/v1/auth/verification/generate`;
      const res = await axios2.post(
        url,
        {
          userId,
          userType: "Service Provider",
          useCase,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setUseCase(useCase);
      handleModal("askForOTP");
      setStartResendTimer(() => true);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // ----------------------------

  const components = {
    jobCard: <JobCard onClose={() => setShow(false)} />,
    invoice: <Invoice onClose={() => setShow(false)} />,
    paymentDetails: <PaymentDetails onClose={() => setShow(false)} />,
    paymentSuccessful: <PaymentSuccessful onClose={() => setShow(false)} />,
    recieveAmount: <RecieveAmount onClose={() => setShow(false)} />,
    rejectModal: (
      <RejectionPopup
        setRenderRejected={setRenderRejected}
        fetchService={fetchAccepetdServiceJobs}
        orderItemBookingId={orderItemBookingId}
        onClose={() => setShow(false)}
      />
    ),
    messageModal: <MessagePopup onClose={() => setShow(false)} />,
    askForOTP: (
      <VerifiedOtpPopup
        // verificationPending={verificationPending}
        // setVerificationPending={setVerificationPending}
        // setWorkInProgress={setWorkInProgress}
        workInProgress={workInProgress}
        orderItemBookingId={orderItemBookingId}
        useCase={useCase}
        startResendTimer={startResendTimer}
        setStartResendTimer={setStartResendTimer}
        userId={userId}
        onClose={() => setShow(false)}
        setShow={setShow}
        setScreen={setScreen}
      />
    ),
    otpSuccessCard: (
      <VerifiedOTP
        setJobStarted={setJobStarted}
        onClose={() => setShow(false)}
        useCase={useCase}
        fetchAccepetdServiceJobs={fetchAccepetdServiceJobs}
        orderItemBookingId={orderItemBookingId}
        setRenderCompleted={setRenderCompleted}
      />
    ),
    jobCard: <JobCard setScreen={setScreen} onClose={() => setShow(false)} />,
    paymentDetails: (
      <PaymentDetails
        setScreen={setScreen}
        modeOfPayment={modeOfPayment}
        setModeOfPayment={setModeOfPayment}
        onClose={() => setShow(false)}
      />
    ),
    recieveAmount: (
      <RecieveAmount
        setPaymentRecieved={setPaymentRecieved}
        // setWorkInProgress={setWorkInProgress}
        setVerificationPending={setVerificationPending}
        setScreen={setScreen}
        onClose={() => setShow(false)}
      />
    ),
    paymentSuccessful: (
      <PaymentSuccessful setScreen={setScreen} onClose={() => setShow(false)} />
    ),
    invoice: <Invoice onClose={() => setShow(false)} />,
  };
  console.log(orderItemBookingId, 'orderItemBookingId')
  return (
    <div className={styles.parent_page}>
      <Loader isLoading={isLoading} />
      <Modal
        show={show}
        onClose={() => setShow(!show)}
        children={components[screen]}
      // children={components['askForOTP']}
      />
      <Attachment
        attachmentUrls={attachmentUrls}
        showAttachment={showAttachment}
        onClose={() => {
          setShowAttachment(!showAttachment);

        }}
      />
      <OrderSearchSection
        amount={amount}
        dateRange={dateRange}
        orderTypes={orderTypes}
        setAmount={setAmount}
        setOrderTypes={setOrderTypes}
        setDateRange={setDateRange}
        setSearch={setSearch}
      />
      {OrderListNewData?.length === 0 && (
        <EmptyStateComponent
          stateType="emptyConstructionState"
          heading="No Accepted Request !"
          subHeading="You haven’t accepted any job requests. All your accepted job request will be shown here."
        />
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            {/* <th>
              <input type="checkbox" />
            </th> */}
            <th className={styles.job_details}>
              <div className={styles.table_data}>
                <span>Job Details</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Description</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Amount</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Action</span>
              </div>
            </th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {OrderListNewData?.map((item) => (
            <React.Fragment key={item.orderItemBookingId}>
              {
                // workInProgress.includes(item.orderItemBookingId) 
                workInProgress && item.orderItemBookingId === orderItemBookingId
                && (
                  <tr
                    className={`${acceptedStyle.Progress_bar_data} ${styles.hideOnDesktop}`}
                  >
                    <td colSpan="6" className={acceptedStyle.status_td}>
                      <div
                        className={`${acceptedStyle.status_bar} ${acceptedStyle.work_progress}`}
                      >
                        {ICONS.clockUnderProcess} Work in Progress
                      </div>
                    </td>
                  </tr>
                )}
              <tr
                onClick={() => {
                  // setOrderItemBookingId(item.orderItemBookingId);
                  localStorage.setItem('orderItemBookingId', item.orderItemBookingId)
                }}
              >
                {/* <td style={{ verticalAlign: "top" }}>
                  <div className={styles.checkbox_data}>setOrderItemBookingId
                    <input type="checkbox" />
                  </div>
                </td> */}
                <td className={styles.hideOnMobile}>
                  {/* <div className={styles.job_req_header}>
                  <div className={styles.job_req_header_data}> */}
                  {/* <p className={styles.main_heading}>{item.category}</p> */}
                  <div className="space_between">
                    <span className={styles.main_heading_data}>
                      Job ID: {item.orderItemBookingId}
                    </span>
                    <span
                      className={styles.clip}
                      onClick={() =>
                        // item.attachments.length > 0 &&
                        showAttachmentFunction(item.attachments)
                      }
                    >
                      {ICONS.clipIcon}
                      <span className={styles.attachment_count}>
                        {item.attachments.length}
                      </span>
                    </span>
                  </div>

                  {
                    // workInProgress.includes(item.orderItemBookingId) 
                    workInProgress && item.orderItemBookingId === orderItemBookingId
                    && (
                      <tr className={acceptedStyle.Progress_bar_data}>
                        <td colSpan="6" className={acceptedStyle.status_td}>
                          <div
                            className={`${acceptedStyle.status_bar} ${acceptedStyle.work_progress}`}
                          >
                            {ICONS.clockUnderProcess} Work in Progress
                          </div>
                        </td>
                      </tr>
                    )}
                  {/* {verificationPending && (
                        <tr className={acceptedStyle.Progress_bar_data}>
                          <td colSpan="6" className={acceptedStyle.status_td}>
                            <div
                              className={`${acceptedStyle.status_bar} ${acceptedStyle.verification_pending}`}
                            >
                              {ICONS.passkeyIcon} Verification Pending
                            </div>
                          </td>
                        </tr>
                      )} */}
                </td>
                <td className={styles.job_details}>
                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Job ID: </span>
                    <span>{item.orderItemBookingId}</span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Date & Time: </span>
                    <span>
                      {item.deliveryDate} {item.deliveryTime}
                    </span>
                  </div>

                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Service Name: </span>
                    <span>{item.serviceCategory}</span>
                  </div>

                  <div className={styles.order_card_detail}>
                    <span>Customer Name: </span>
                    <span>{item.customerName}</span>
                  </div>

                  <div className={styles.order_card_detail}>
                    <span>Address: </span>
                    <span>
                      {item.orderItemBooking.address?.addressLine1}
                      {item.orderItemBooking.address?.addressLine2},{" "}
                      {item.orderItemBooking.address?.city},{" "}
                      {item.orderItemBooking.address?.state},{" "}
                      {item.orderItemBooking.address?.pincode}
                    </span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Contact Number: </span>
                    <span>{item.customerMobileNumber} </span>
                  </div>
                </td>
                <td>
                  <div className={styles.order_card_detail}>
                    <span>Work Details: </span>
                    <span>
                      {item.workDetails ? item.workDetails : "not added"}
                    </span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Additional Notes: </span>
                    <span>{item.additionalNote ? item.additionalNote : "not added"}</span>
                  </div>
                </td>
                <td>
                  <div className={styles.order_card_detail}>
                    <span>Service Charges: </span>
                    <span>₹ {item.price}</span>
                  </div>
                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Attachments: </span>
                    <span
                      className={styles.attachments_value}
                      onClick={() =>
                        item.attachments.length > 0 &&
                        showAttachmentFunction(item.attachments)
                      }
                    >
                      {" "}
                      {item.attachments.length} attachments
                    </span>
                  </div>
                </td>
                <td className={styles.action_td}>
                  <div
                    className={
                      // jobStarted &&
                      workInProgress && item.orderItemBookingId === orderItemBookingId
                        // workInProgress.includes(item.orderItemBookingId)
                        ? "hide"
                        : styles.multi_btn
                    }
                  >
                    <div className={styles.table_data_first}>
                      <Button
                        onClick={() => {
                          //  setOrderItemBookingId(item.orderItemBookingId);
                          localStorage.setItem('orderItemBookingId', item.orderItemBookingId)
                          // workHandler("Started Work");
                          localStorage.setItem('workInProgress', true)
                          // setWorkInProgress(true)
                          setJobStarted(true)
                        }}
                        padding="10px 9px"
                        text="Start Work"
                        backgroundColor="var(--green)"
                        color="var(--white)"
                        width="100%"
                        minWidth="8rem"
                      />
                    </div>
                    <div className={styles.table_data_second}>
                      <Button
                        onClick={() => {
                          // setOrderItemBookingId(item.orderItemBookingId);
                          localStorage.setItem('orderItemBookingId', item.orderItemBookingId)
                          handleModal("rejectModal");
                        }}
                        padding="9px 22px"
                        text="Cancel"
                        backgroundColor="var(--white)"
                        color="var(--red)"
                        borderColor="var(--red)"
                        width="100%"
                        minWidth="8rem"
                      />
                    </div>
                    {/* <span
                        className={`${acceptedStyle.messageIcon} ${styles.Message_hideOnMobile}`}
                        onClick={() => handleModal("messageModal")}
                      >
                        {ICONS.messageBlackIcon}
                      </span> */}
                  </div>
                  <div
                    className={
                      // jobStarted &&
                      workInProgress && item.orderItemBookingId === orderItemBookingId
                        // workInProgress.includes(item.orderItemBookingId)
                        ? styles.multi_btn
                        : "hide"
                    }
                  >
                    {/* {/* <Button
                    onClick={
                      verificationPending
                        ? () => handleModal("invoice")
                        : () => handleModal("jobCard")
                    }
                    padding="10px 18px"
                    text="Job Card"
                    backgroundColor="var(--yellow)"
                    color="var(--smoky-black)"
                    width="100%"
                    minWidth="8rem"
                  /> */}

                    <Button
                      onClick={() => {
                        // setOrderItemBookingId(item.orderItemBookingId);
                        jobCompleted()
                      }}
                      padding="9px 22px"
                      text="Finish Job"
                      backgroundColor="var(--green)"
                      color="var(--white)"
                      width="100%"
                      minWidth="8rem"
                    />

                    {/* <Button
                    onClick={() => handleModal("askForOTP")}
                    padding="9px 22px"
                    text="Finish Job"
                    backgroundColor={"var(--green)"}
                    color={"var(--white)"}
                    // backgroundColor={
                    //   paymentRecieved ? "var(--green)" : "var(--grey-80)"
                    // }
                    // color={paymentRecieved ? "var(--white)" : "var(--grey)"}
                    // disabled={paymentRecieved ? false : true}
                    width="100%"
                    minWidth="8rem"
                  /> */}
                    {/* <span
                        className={`${acceptedStyle.messageIcon} ${styles.Message_hideOnMobile}`}
                        onClick={() => handleModal("messageModal")}
                      >
                        {ICONS.messageBlackIcon}
                      </span> */}
                  </div>
                </td>
                {/* <td>
                    <div
                      className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                    >
                      <span
                        className={acceptedStyle.messageIcon}
                        onClick={() => handleModal("messageModal")}
                      >
                        {ICONS.messageBlackIcon}
                      </span>
                    </div>
                  </td> */}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <Pagination
        pageLength={OrderListNewData?.length}
        currentPage={currPage}
        totalPages={totalPages}
        setCurrPage={setCurrPage}
      />
    </div>
  );
};
export default Accepted;
