import { useState } from "react";
import Styles from "./SortByYear.module.css";
import "../../../../utils/utilsCss/utilsCss.css";
import { ICONS } from "../../../../Assets/Icons";
import ThisYearDropdownList from "../ThisYearDropdownList";

const SortByYear = ({
  selectedYear,
  setSelectedYear,
  dropdownOption,
  setDropdownOption
} ) => {
  const [open, setOpen] = useState(false);

  const years = [2024, 2025];

  const dropdownFunc = (e) => {
    const value = e.target.value;
    setDropdownOption(value);
    setOpen(false);
  };

  return (
    <div className={`${Styles.sortByYearContainer} space_between`}>
      <div className={Styles.sortByYearMenuParent}>
        <span className={Styles.calendarIcon}>{ICONS.calendarIcon}</span>
        <span className={open ? Styles.cursorIcon : Styles.cursorIconDown }>{ICONS.cursorDown}</span>
        <div onClick={() => setOpen(!open)} className={Styles.sortByYearMenu}>
          {dropdownOption}
        </div>

        <div
          // style={{ display: open ? "block" : "none" }}
          className={open ? Styles.dropdownMenu : Styles.dropdownMenuClosed}
        >
          <option
            onClick={(e) => dropdownFunc(e)}
            value="This year"
            className={Styles.option}
          >
            This year
          </option>

          <option
            onClick={(e) => dropdownFunc(e)}
            value="This month"
            className={Styles.option}
          >
            This month
          </option>
          <option
            onClick={(e) => dropdownFunc(e)}
            value="This week"
            className={Styles.option}
          >
            This week
          </option>
        </div>
      </div>

      <div className={Styles.yearList}>
        {years.map((year) => (
          <span
            key={year}
            className={
              selectedYear === year
                ? `${Styles.selected} ${Styles.year}`
                : Styles.year
            }
            onClick={() => setSelectedYear(year)}
          >
            {year}
          </span>
        ))}
      </div>

      <div
        className={
          open ? Styles.bottom_dropdownMenu : Styles.dropdownMenuClosed
        }
      >
        <ThisYearDropdownList
          dropdownOption={dropdownOption}
          setDropdownOption={setDropdownOption}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
};

export default SortByYear;
