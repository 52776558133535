import { Link } from "react-router-dom";

import styles from "../../../ServiceProviderDashboard.module.css";
import mobile_profile_style from "../../styles/MobileProfileStyle.module.css";
import { ICONS } from "../../../../Assets/Icons";
import profile_styles from "../../Sections/Profile/Profile.module.css";
import { useSelector } from "react-redux";

const ProfileSettings = () => {

  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const userType = stateData.userType;

  return (
    <div className={mobile_profile_style.parent}>
      <div className={styles.mobileNavbar}>
        <Link to="../profile" className={profile_styles.backButton}>
          {ICONS.ArrowBackIcon}
        </Link>
        <span>Profile Settings</span>
      </div>

      <Link
        to={
          userType === "Business"
            ? "../../../settings/business"
            : "../../../settings/individual"
        }
      >
        <div className={profile_styles.main_Container}>
          <div className={profile_styles.notification_setting}>
            {ICONS.PersonIcon}
            <span className={profile_styles.profile_heading}>
              {userType === "Business"
                ? "Business Details"
                : "Personal Details"}
            </span>
          </div>
        </div>
      </Link>
      {/* <Link to="../../../settings/professional">
        <div className={profile_styles.main_Container}>
          <div className={profile_styles.notification_setting}>
            {ICONS.ProfessionalIcon}
            <span className={profile_styles.profile_heading}>
              Professional Details
            </span>
          </div>
        </div>
      </Link> */}
      {userType === 'Individual' && <Link to="../../../settings/educational">
        <div className={profile_styles.main_Container}>
          <div className={profile_styles.notification_setting}>
            {ICONS.EducationIcon}
            <span className={profile_styles.profile_heading}>
              Education Details
            </span>
          </div>
        </div>
      </Link>}
      <Link to="../../../settings/financial">
        <div className={profile_styles.main_Container}>
          <div className={profile_styles.notification_setting}>
            {ICONS.FinancialIcon}
            <span className={profile_styles.profile_heading}>
              Financial Details
            </span>
          </div>
        </div>
      </Link>
      <Link to="../../../settings/security">
        <div className={profile_styles.main_Container}>
          <div className={profile_styles.notification_setting}>
            {ICONS.encryptedIcon}
            <span className={profile_styles.profile_heading}>
              Security Settings
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProfileSettings;
