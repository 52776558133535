import Styles from "../RatingAndReview/RatingAndReview.module.css";
import commonStyles from "../Profile_Settings/Styles/ProfileCommonCss.module.css";
// import { ICONS } from "../../Assets/Icons";
import CustomerRatingCard from "./Components/CustomerRatingCard/CustomerRatingCard";
import { Link } from "react-router-dom";
import StarRating from "./Components/RatingMachanism/StarRating";

const RatingAndReview = () => {
  const customerData = [
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating:3.5,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating:4.5,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
  ];
  const reliabilityWidth = (5 / 5) * 100;
  const payoutWidth = (1 / 5) * 100;
  const positiveWidth = (3.5 / 5) * 100;
  return (
    <div className={commonStyles.Profile_Container}>
      <div className={Styles.heading}>Ratings & Reviews</div>
      <div className={Styles.main_container}>
        <div className={Styles.left_rating_container}>
          <div className={Styles.left_rating_card}>
            <div className={Styles.left_rating_card_data}>
              <h1 className={Styles.left_rating_heading}>
                Ratings and Reviews
              </h1>
              <div className={Styles.rating_data}>
                <div className={Styles.left_rating_heading}>4.7</div>
                <div className={Styles.star_icon}>
                  {/* <p>
                    <span>{ICONS.bigStarIcon}</span>
                    <span>{ICONS.bigStarIcon}</span>
                    <span>{ICONS.bigStarIcon}</span>
                    <span>{ICONS.bigStarIcon}</span>
                    <span>{ICONS.bigStarIcon}</span>
                  </p> */}
                  <p><StarRating rating={4} scale={1.5}/></p>
                  <p className={Styles.text}>Based on 875 ratings</p>
                </div>
              </div>
              <div className={Styles.line}></div>
              <div className={Styles.parent_bar_container}>
                <div className={Styles.parent_bar}>
                  <p className={Styles.parent_bar_label}>Reliability</p>
                  <p className={Styles.parent_bar_value}>4.1</p>
                </div>
                <div className={Styles.progress_bar}>
                  <div
                    className={Styles.inner_bar}
                    style={{
                      backgroundColor: "#FF6B00",
                      width: `${reliabilityWidth}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className={Styles.parent_bar_container}>
                <div className={Styles.parent_bar}>
                  <p className={Styles.parent_bar_label}>Payout rating</p>
                  <p className={Styles.parent_bar_value}>4.4</p>
                </div>
                <div className={Styles.progress_bar}>
                  <div
                    className={Styles.inner_bar}
                    style={{
                      backgroundColor: "#00F0C5",
                      width: `${payoutWidth}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className={Styles.parent_bar_container}>
                <div className={Styles.parent_bar}>
                  <p className={Styles.parent_bar_label}>Positive solutions</p>
                  <p className={Styles.parent_bar_value}>4.2</p>
                </div>
                <div className={Styles.progress_bar}>
                  <div
                    className={Styles.inner_bar}
                    style={{
                      backgroundColor: "#00F0C5",
                      width: `${positiveWidth}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.right_rating_container}>
          {customerData.map(
            ({ name, date, cusromerTitle,rating,description }, index) => (
              <div key={index}>
                <CustomerRatingCard
                  name={name}
                  date={date}
                  cusromerTitle={cusromerTitle}
                  rating={rating}
                  description={description}
                />
              </div>
            )
          )}
          <Link to="../moreReviews">
            <div className={Styles.more_review_data}>More Reviews</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RatingAndReview;
