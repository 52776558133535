import styles from "../../RejectMessage/RejectMessage.module.css";
import Button from "../../../../../../Components/Button/Button";
import Verifiedstyles from "./OTPsucessCard.module.css";
import { ICONS } from "../../../../../../../Assets/Icons";
import axios from "axios";
import { toast } from "react-toastify";
import axios2 from "../../../../../../../axios2";


const VerifiedOTP = ({ setJobStarted, onClose, useCase, fetchAccepetdServiceJobs, orderItemBookingId, setRenderCompleted }) => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const accessToken = localStorage.getItem('accessToken');

  const jobCompleted = async () => {
    if (orderItemBookingId) {
      try {
        let url = `${baseUrl}/api/v1/order-management/service-provider/complete`;
        const res = await axios2.post(url, {
          orderItemBookingId
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        console.log(res);
        fetchAccepetdServiceJobs();
        setRenderCompleted((prev) => prev + 1);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
          console.log(error);
        } else {
          console.log(error)
        }
      }
    }
  }

  const handleOkay = () => {
    setJobStarted(true);
    if (useCase === 'Ended Work') {
      jobCompleted();
    }
    onClose();
  };

  return (
    <>
      <div className={styles.popup}>
        <div className={Verifiedstyles.VerifiedOTP_header}>
          <span>OTP Verified!</span>
          <span onClick={() => onClose()}>{ICONS.closeWhiteIcon}</span>
        </div>
        <div className={styles.popup_body}>
          <div className={Verifiedstyles.VerifiedOtp_details}>
            <span>{ICONS.checkIcon}</span>
            <div className={Verifiedstyles.VerifiedOtp_detail_data}>
              <p className={Verifiedstyles.VerifiedOtp_label}>
                OTP Verified Successfully
              </p>
              <span className={Verifiedstyles.VerifiedOtp_value}>
                You can now begin the job. Click 'Finish Job' when the task is complete.
              </span>
            </div>
          </div>
          <div className={styles.popup_buttons}>
            <Button
              onClick={() => handleOkay()}
              padding="10px 18px"
              text="Okay"
              backgroundColor="var(--green)"
              color="var(--white)"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifiedOTP;
