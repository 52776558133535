import { useEffect, useLayoutEffect, useState } from "react";
import styles from "./Attachments.module.css";
import navbarStyles from '../../../Profile_Settings/Styles/ProfileCommonCss.module.css'
import { ICONS } from "../../../../Assets/Icons/index";

const images = [
    "https://picsum.photos/800/600?random=1",
    "https://picsum.photos/800/600?random=2",
    "https://picsum.photos/800/600?random=3",
    "https://picsum.photos/800/600?random=4",
    "https://picsum.photos/800/600?random=5",
    "https://picsum.photos/800/600?random=1",
    "https://picsum.photos/800/600?random=2",
    "https://picsum.photos/800/600?random=3",
    "https://picsum.photos/800/600?random=4",
    "https://picsum.photos/800/600?random=5",
];

const Attachment = ({ attachmentUrls, showAttachment, onClose }) => {

    const [imageSelectedIndex, setImageSelectedIndex] = useState(0);

    if (!showAttachment) {
        return null;
    }

    return (
        <div className={styles.attachmentPageParent}>
            <div className={styles.attachmentPageMobile}>
                <div className={`${navbarStyles.mobileNavbar}`}>
                    <span
                        className={navbarStyles.backButton}
                        onClick={() => onClose()}
                    >
                        {ICONS.ArrowBackIcon}
                    </span>
                    <span>Attachments</span>
                    {/* <span
                    className={commonStyle.filter_icon_mobile}
                    onClick={handleEditDiscard}
                >
                    {edit ? `Discard` : ICONS.EditDataIcon}
                </span> */}
                </div>
                {
                    attachmentUrls?.map((img, i) => (
                        <img
                            key={i}
                            className={
                                styles.full_image
                            }
                            src={img}
                            alt=""
                        />
                    ))
                }
            </div>
            <div className={styles.attachmentPage}>

                <span onClick={() => onClose()} className={styles.closeIcon}>{ICONS.closeIcon}</span>
                <h3 className={styles.heading}>Attachments</h3>

                <div className={styles.upperBlock}>
                    <button
                        disabled={imageSelectedIndex === 0}
                        style={{ visibility: imageSelectedIndex === 0 ? `hidden` : `visible` }}
                        className={styles.rightArrow}
                        onClick={() => setImageSelectedIndex(imageSelectedIndex - 1)}
                    >
                        {ICONS.LeftArrowIcon}
                    </button>

                    <img
                        className={styles.upperBlockImage}
                        src={attachmentUrls[imageSelectedIndex]}
                        alt="..."
                    />

                    <button
                        style={{ visibility: imageSelectedIndex === (attachmentUrls.length - 1) ? `hidden` : `visible` }}
                        disabled={imageSelectedIndex === (attachmentUrls.length - 1)}
                        className={styles.leftArrow}
                        onClick={() => setImageSelectedIndex(imageSelectedIndex + 1)}
                    >
                        {ICONS.rightBlackArrowIcon}
                    </button>
                </div>


                <div className={styles.lowerBlock}>
                    {attachmentUrls?.map((img, i) => (
                        <img
                            key={i}
                            onClick={() => setImageSelectedIndex(i)}
                            className={
                                imageSelectedIndex === i
                                    ? `${styles.selected_array_image} ${styles.array_image}`
                                    : styles.array_image
                            }
                            src={img}
                            alt=""
                        />
                    ))}
                </div>


            </div >
        </div>
    );
};

export default Attachment;
