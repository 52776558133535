import styles from "../Styles/ProfileCommonCss.module.css";
import commonStyle from "../../ServiceProviderDashboard.module.css";

import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { ICONS } from "../../../Assets/Icons";
import Inputbox from "../Components/Inputbox/Inputbox";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/Loader/Loader";
import EditButton from "../Components/EditButton/EditButton";
import SaveChangesButton from "../Components/SaveChangesButton/SaveChangesButton";
import DragAndDrop from "../Components/DragAndDropComponent/DragAndDropComponent";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";

const FinancialDetails = () => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const userId = localStorage.getItem('userId');
  const accessToken = localStorage.getItem('accessToken');
  // const mobileNumber = useSelector((state) => state.auth.userMobileNumber);
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  // const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(null);
  const [emptyValue, setEmptyValue] = useState(null);
  const [discard, setDiscard] = useState(null);
  const [recievedFile, setRecievedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [cancelledChequesIds, setCancelledChequesIds] = useState([]);
  const [cancelledChequeArray, setCancelledChequeArray] = useState([]);
  const [error, setError] = useState({
    ifscCode: null,
    accountNumber: null,
  });
  const [financialDetails, setFinancialDetails] = useState({
    bankName: "",
    ifscCode: "",
    accountNumber: "",
    accountHolder: "",
    upiIds: [],
    // cancelledChequesIds: [],
  });

  const handleEditDiscard = () => {
    if (edit) {
      setEdit(false);
      setDiscard(true);
      setEmptyValue(null);
    } else {
      setEdit(true);
      setDiscard(false);
      setEmptyValue(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFinancialDetails((prev) => ({ ...prev, [name]: value }));

    const errorMessage = validateEntry(name, value);

    setError((prev) => ({ ...prev, [name]: errorMessage }));
  };

  const validateEntry = (name, value) => {
    switch (name) {
      case "accountNumber":
        if (value) {
          if (!/^[0-9]{9,18}$/.test(value)) {
            return "Account number is not valid";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "ifscCode":
        if (value) {
          if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
            return "IFSC code is invalid";
          } else {
            return null;
          }
        } else {
          return null;
        }

      default:
        return null;
    }
  };

  const uploadDoc = async (file) => {
    console.log(file)
    try {
      setIsLoading(true);
      const generateUrl = await axios2.post(
        `${baseUrl}/api/v1/auth/generate-upload-url`,
        {
          bucketName: "connect-dev-test",
          key: file.key,
          expDate: 15,
          contentType: file.fileObject?.type,
        }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      );

      const { url } = generateUrl.data.data;
      // console.log(url, "url");

      const uploadFile = await axios.put(url, file.fileObject, {
        headers: {
          "Content-Type": file.fileObject.type,
        },
      });
      // console.log(uploadFile, "uploadFile");
      if (uploadFile.status === 200) {
        const uploadDocDetails = await axios2.post(
          `${baseUrl}/api/v1/auth/list-uploaded-document`,
          {
            bucketName: "connect-dev-test",
            key: file.key,
            contentType: file.fileObject.type,
            documentType: file.documentType,
            documentName: file.fileObject.name,
            documentDescription: file.documentDescription,
            documentSize: file.fileObject.size,
          }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
        );
        // console.log(uploadDocDetails, "uploadDocDetails");
        if (uploadDocDetails.status === 201) {
          const docDetails = uploadDocDetails.data.data.document;
          // console.log(docDetails.id, 'docDetails.id')
          // setFinancialDetails((prev) => ({
          //   ...prev,
          //   cancelledChequesIds: [...prev.cancelledChequesIds, docDetails.id],
          // }));
          setCancelledChequesIds((prev) => [...prev, docDetails.id])
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error)
      }

    } finally {
      setIsLoading(false);
    }
  };

  const uploadFullData = async () => {
    try {
      setIsLoading(true)
      const response = await axios2.post(
        `${baseUrl}/api/v1/profile/financial`,
        {
          ...financialDetails,
          cancelledChequesIds: cancelledChequesIds,
          sectorId: sectorId,
          userId: userId,
        }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      );
      console.log(response, "upload full data");
      if (response.status === 200) {
        setEdit(false);
        setDiscard(null);
        setEmptyValue(null);
        toast.success('Successfully saved');
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  const retriveDocuments = async (docId) => {
    try {
      setIsLoading(true)
      const response = await axios2.post(
        `${baseUrl}/api/v1/auth/document-retrival`,
        {
          documentId: docId,
        }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      );

      console.log(response, "retrieved Document");

      if (response.status === 200) {
        const docFile = {
          docId: docId,
          docType: response.data.data.type,
          docName: response.data.data.name,
          docUrl: response.data.data.url,
        };

        setCancelledChequeArray((prev) => {
          const repeatedDoc = prev?.find((cheque) => cheque.docId === docFile.docId)
          if (repeatedDoc) {
            return prev
          } else {
            return [...prev, docFile]
          }
        });

      }

    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error)
      }
    } finally {
      setIsLoading(false)
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/profile/your-financial-details`,
        {
          userId: userId,
          sectorId: sectorId,
        }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      );
      console.log(response)
      if (response.status === 200) {
        setRecievedFile(response.data.data.financialDetails);
        // console.log(
        //   response.data.data.financialDetails.cancelledChequeUploadIds,
        //   "cancelledCheckIds"
        // );
        if (response.data.data.financialDetails.cancelledChequeUploadIds) {
          response.data.data.financialDetails.cancelledChequeUploadIds.forEach(
            (id) => retriveDocuments(id)
          );
        }

      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const errorValues = Object.values(error);
    const validationValue = errorValues.filter((error) => error !== null);

    if (
      emptyValue === false &&
      cancelledChequeArray.length > 0 &&
      validationValue.length === 0
    ) {
      // if (cancelledChequesIds.length === 0) {
      //   setFinancialDetails((prev) => ({ ...prev, cancelledChequesIds: [] }));
      // }

      const newDocAddedToUpload = cancelledChequeArray.filter(
        (cheque) => cheque.fileObject
      );
      console.log(newDocAddedToUpload, "newDocAddedToUpload");
      if (newDocAddedToUpload.length > 0) {
        newDocAddedToUpload.forEach((cheque) => uploadDoc(cheque));
      }
      if (newDocAddedToUpload.length === 0 && cancelledChequesIds.length > 0) {

        uploadFullData();
      }
    }
  }, [emptyValue, error]);

  useEffect(() => {
    // console.log(
    //   financialDetails.cancelledChequesIds,
    //   " financialDetails.cancelledChequesIds"
    // );
    // console.log(cancelledChequeArray, " cancelledChequeArray");
    // console.log(emptyValue, 'empty value')
    // console.log(financialDetails.cancelledChequesIds?.length > 0, ' financialDetails.cancelledChequesIds?.length > 0')
    // console.log(financialDetails.cancelledChequesIds?.length === cancelledChequeArray?.length, '  financialDetails.cancelledChequesIds?.length === cancelledChequeArray?.length')
    if (
      emptyValue === false &&
      cancelledChequesIds?.length > 0 &&
      cancelledChequesIds?.length === cancelledChequeArray?.length
    ) {
      // console.log(" financialDetails.cancelledChequesIds useffect in if ");
      // setFinancialDetails((prev) => ({ ...prev, cancelledChequesIds: [] }))
      uploadFullData();
    }
    // else {
    //   console.log('condition not met')
    // }
  }, [cancelledChequesIds]);

  useEffect(() => {
    const existingDocIds = cancelledChequeArray?.reduce(
      (acc, crr) => crr.docId ? [...acc, crr.docId] : acc
      , []);
    console.log(existingDocIds, 'existingDocIds')

    // setFinancialDetails((prev) => ({
    //   ...prev,
    //   cancelledChequesIds: existingDocIds,
    // }));
    setCancelledChequesIds(existingDocIds)
    setEmptyValue(null)

  }, [cancelledChequeArray]);

  useEffect(() => {
    if (discard) {
      getData()
      // setFinancialDetails({
      //   bankName: recievedFile?.bankName,
      //   ifscCode: recievedFile?.ifscCode,
      //   accountNumber: recievedFile?.accountNumber,
      //   accountHolder: recievedFile?.accountHolder,
      //   upiIds: recievedFile?.upiIds,
      //   cancelledChequesIds: recievedFile?.cancelledChequeUploadIds,
      // });
      setError({
        ifscCode: '',
        accountNumber: '',
      })

    }
  }, [discard]);

  useEffect(() => {
    if (recievedFile) {
      console.log('recieved')
      setFinancialDetails({
        bankName: recievedFile?.bankName,
        ifscCode: recievedFile?.ifscCode,
        accountNumber: recievedFile?.accountNumber,
        accountHolder: recievedFile?.accountHolder,
        upiIds: recievedFile?.upiIds,
        // cancelledChequesIds: recievedFile?.cancelledChequeUploadIds,
      });
    }

  }, [recievedFile]);

  // useEffect(() => {
  //   setEmptyValue(null)
  // }, [financialDetails])

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  // console.log(recievedFile, "recievedFile");
  // console.log(response, "response");
  console.log(cancelledChequeArray, "cancelledChequeArray");
  console.log(cancelledChequesIds, 'cancelledChequesIds')
  console.log(emptyValue, 'emptyvalue')
  console.log(financialDetails, 'financialDetails')
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link
            to="../../mobileProfile/personalProfile"
            className={styles.backButton}
          >
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Financial Details</span>
          <span
            className={commonStyle.filter_icon_mobile}
            onClick={handleEditDiscard}
          >
            {edit ? `Discard` : ICONS.EditDataIcon}
          </span>
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={styles.edit_save_button_section}>
          <EditButton edit={edit} onClick={handleEditDiscard} />
          <SaveChangesButton
            formDetails={financialDetails}
            edit={edit}
            setEmptyValue={setEmptyValue}
          />
        </div>
        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Financial Details</p>
          <p className={styles.mandatory_data}>
            (* Indicates required question)
          </p>
        </div>
        <div className={styles.heading}>Bank Details</div>
        <div className={styles.Main_Container}>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Bank Name
              <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={financialDetails.bankName}
              onChange={(e) =>
                setFinancialDetails({
                  ...financialDetails,
                  bankName: e.target.value,
                })
              }
              type="text"
              placeholder="HDFC Bank"
            />
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Account Holder
              <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={financialDetails.accountHolder}
              onChange={(e) =>
                setFinancialDetails({
                  ...financialDetails,
                  accountHolder: e.target.value,
                })
              }
              type="text"
              placeholder="HDFC Bank"
            />
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              Account Number <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={financialDetails.accountNumber}
              onChange={(e) => handleChange(e)}
              name="accountNumber"
              type="number"
              placeholder="121212121212121212"
            />
            {error.accountNumber && (
              <p className={styles.error}>{error.accountNumber}</p>
            )}
          </div>
          <div className={styles.form_data}>
            <label className={styles.form_label}>
              IFSC Code <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={financialDetails.ifscCode}
              onChange={(e) => handleChange(e)}
              name="ifscCode"
              type="text"
              placeholder="HDFC000452"
            />
            {error.ifscCode && <p className={styles.error}>{error.ifscCode}</p>}
          </div>

          <div className={styles.form_data}>
            <label className={styles.form_label}>
              UPI ID <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={financialDetails?.upiIds}
              onChange={(e) =>
                setFinancialDetails({
                  ...financialDetails,
                  upiIds: [e.target.value],
                })
              }
              type="text"
              placeholder="0123456789@ybl"
            />
            {financialDetails?.upiIds[0] === '' && <p className={styles.error}>*required</p>}
            {/* <p className={styles.financial_upi_data}>
              <span>{ICONS.AddIconBlue}</span> ADD UPI ID
            </p> */}
          </div>
        </div>

        <div className={styles.heading}>Other Important Details</div>
        <div className={styles.Main_Container}>
          <div className={styles.cancelledCheque_container}>
            <label className={styles.form_label}>
              Cancelled Cheque <span className={styles.star_mark}>*</span>
            </label>
            <DragAndDrop
              disabled={!edit}
              file={cancelledChequeArray}
              setFile={setCancelledChequeArray}
              // width="266px"
              userId={userId}
              setFileError={setFileError}
              acceptedType={['image/*',]}
              keyTitle="cancel-cheque"
              documentType="Other"
              documentDescription="cancelled cheque"
              displayBrowseOption={true}
            />
            {cancelledChequeArray?.length <= 0 && edit && (
              <p className={styles.error}>required*</p>
            )}
            {fileError && !cancelledChequeArray?.length <= 0 && (
              <p className={styles.error}>{fileError}</p>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.mobileView_footerBtn} ${styles.hideOnDesktop}`}>
        <SaveChangesButton
          width="100%"
          formDetails={financialDetails}
          edit={edit}
          setEmptyValue={setEmptyValue}
        />
      </div>
    </>
  );
};

export default FinancialDetails;
