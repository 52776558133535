import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import ServiceProviderDashboard from "./ServiceProviderDashboard/ServiceProviderDashboard";
import axios from "axios";
import { BrowserRouter as useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

// Initialize Google Analytics with your Tracking ID directly
const TRACKING_ID = 'G-L4GPSQWH1Z'; // Replace with your actual Tracking ID
ReactGA.initialize(TRACKING_ID);

// Custom hook for page tracking (same as before)
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

function App() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;
  const mainBaseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_MAIN_DEV
      : process.env.REACT_APP_MAIN_PROD;

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const authorizationCheck = async () => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/v1/auth/verify-login-token`,
        {},
        {
          withCredentials: true,
        }
      );

      localStorage.setItem("accessToken", res.data.data.accessToken);
      localStorage.setItem("userId", res.data.data.userId);
      setIsAuthenticated(true);
      console.log("Successfully verified uuid token:", res);
    } catch (error) {
      console.log("Got error verifying login state:", error);
      // window.location.href = `${mainBaseUrl}/auth/login`;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setIsAuthenticated(true);
    } else {
      authorizationCheck();
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isAuthenticated ? <ServiceProviderDashboard /> : <p>Loading...</p>}
    </div>
  );
}

export default App;
