import { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { ICONS } from "../../../../Assets/Icons";
import styles from "./Invoice.module.css";
import { useSelector } from "react-redux";
import axios2 from "../../../../axios2";
import { toast } from "react-toastify";

const Invoice = ({ onClose, order }) => {
    console.log(order)
    const accessToken = localStorage.getItem("accessToken");
    const baseUrl =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_DEV
            : process.env.REACT_APP_API_PROD;

    const stateStored = localStorage.getItem("state");
    const state = JSON.parse(stateStored);

    const ref = useRef();

    const [invoiceInfo, setInvoiceInfo] = useState()
    const [loading, setIsLoading] = useState()

    const getInvoiceDetail = async () => {
        try {

            setIsLoading(true);
            console.log(order.invoiceId)
            const response = await axios2.post(
                `${baseUrl}/api/v1/invoices/get-invoices`,
                {
                    id: order.invoiceId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            console.log(response, "data check response");
            if (response.status === 200) {
                setInvoiceInfo(response.data.data.invoices[0])
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
                console.log(error);
            } else {
                console.log(error);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const download = () => {
        const element = ref.current;

        const section_1 = element.querySelector(`.${styles.section_1}`);
        const section_1Style = section_1.style.cssText
        section_1.style.flexDirection = 'row'

        const clickableElements = element.querySelectorAll(`.${styles.clickable}`);
        clickableElements.forEach((clickable) => {
            clickable.style.visibility = 'hidden';
        });

        const originalStyle = element.style.cssText;
        element.style.maxHeight = "unset";
        element.style.overflowY = "unset";
        element.style.width = "100%"; // Make sure content takes full width

        // clickable.style.display = 'hidden'

        const opt = {
            margin: [0, 0], // Set margins to 0 for both top/bottom and left/right
            filename: "invoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 3 }, // Set high scale for better quality
        };

        // Generate PDF and then restore original styles
        html2pdf()
            .from(element)
            .set(opt)
            .save()
            .then(() => {
                element.style.cssText = originalStyle; // Revert to original style
                section_1.style.cssText = section_1Style
                clickableElements.forEach((clickable) => {
                    clickable.style.visibility = 'visible'; // or use '' to reset to the default
                });
            });
    };

    console.log(order, 'order')

    useEffect(() => {
        getInvoiceDetail()
    }, [order])

    return (
        <div className={styles.invoice_page_parent}>
            <div className={styles.invoice_page} ref={ref}>
                <div className={styles.header}>
                    <span onClick={download} className={styles.clickable}>
                        {ICONS.DownloadIcon}
                    </span>
                    <div className={styles.header_middle_section}>
                        <span>{ICONS.connectLogoMini}</span>
                        <span>V Delever Solutionss Pvt ltd</span>
                    </div>
                    <span onClick={onClose} className={styles.clickable}>
                        {ICONS.closeIcon}
                    </span>
                </div>
                <div className={styles.section_1_sub_section_1}>
                    <p className={styles.heading}>INVOICE</p>
                    <p>Invoice No.: #{order?.invoiceId}</p>
                    <p>Issue Date: {invoiceInfo?.issueDate}</p>
                </div>
                <hr className={styles.line}></hr>
                <div className={styles.section_1}>
                    <div className={styles.section_1_sub_section_1}>
                        <p className={styles.heading}>BILL TO:</p>
                        <p>{state?.userDetails?.fullName}</p>
                        <p>{state?.userDetails?.emailAddress}</p>
                        <p>{state?.userDetails?.mobileNumber}</p>
                        <p>{state?.userDetails?.permanentAddress?.addressLine1},{state?.userDetails?.permanentAddress?.addressLine2}</p>
                        <p>{state?.userDetails?.permanentAddress?.city}</p>
                        <p>{state?.userDetails?.permanentAddress?.pincode} {state?.userDetails?.permanentAddress?.state}</p>
                    </div>
                    <hr className={styles.inner_line}></hr>
                    <div className={styles.section_1_sub_section_2}>
                        <p className={styles.heading}>ORDER SUMMARY:</p>
                        <p>Order Id: {order?.id}</p>
                        <p>Order Date: {new Date(order?.createdAt).toLocaleDateString()}</p>
                        <p>Service Category: {state?.currCategoryName}</p>
                        <p>Transaction Type: {invoiceInfo?.transaction?.method}</p>
                        <p>Transaction ID: {invoiceInfo?.transactionId}</p>
                    </div>
                </div>
                <hr className={styles.outer_line}></hr>
                <div className={styles.section_2}>
                    <div className={`${styles.row} ${styles.row_1}`}>
                        <p>ITEM</p>
                        <p>TOTAL</p>
                    </div>
                    <div className={`${styles.row} ${styles.row_2}`}>
                        <p>{order?.orderItemBooking?.providedService?.subCategory?.subCategoryName}</p>
                        <p>{order?.price}</p>
                    </div>
                    <div className={`${styles.row} ${styles.row_3}`}>
                        <p>SGST ({order?.sgstPercentage?.split('.')[0]}%)</p>
                        <p>{order?.sgstPrice}</p>
                    </div>
                    <div className={`${styles.row} ${styles.row_3}`}>
                        <p>CGST ({order?.cgstPercentage?.split('.')[0]}%)</p>
                        <p>{order?.cgstPrice}</p>
                    </div>
                    <div className={`${styles.row} ${styles.row_3}`}>
                        <p>Other Charges</p>
                        <p>00.00</p>
                    </div>
                    <div className={`${styles.row} ${styles.row_4}`}>
                        <p>Total:</p>
                        <p>₹ {order?.totalPrice}</p>
                    </div>
                </div>
                <div className={styles.section_3}>
                    <p className={styles.heading}>BILL FROM:</p>
                    <p>V Delever Solutionss PVT LTD</p>
                    <p>+91-7738388034</p>
                    <p>29/30 Floor 4th,</p>
                    <p>26, jethabhai Building,</p>
                    <p>Dr Atmaram merchant road,</p>
                    <p>Bhuleshwar,Mumbai </p>
                    <p>Maharashtra (400002)</p>
                    <p>GST Number: 27AAICV4177C1Z7</p>
                </div>
                <div className={styles.footer}>
                    <p className={styles.footer_line_1}>THANK YOU!</p>
                    <p>{`We hope you are satisfied with our services :)`}</p>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
