import styles from "./Messages.module.css";
import { useState } from "react";
import Header from "../Components/Header/Header";
import mobile_profile_style from "../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { ICONS } from "../../Assets/Icons";
// import { Link } from "react-router-dom";
import commonStyle from "../ServiceProviderDashboard.module.css";
import SearchBar from "../Components/SearchBar/SearchBar";

const MessageData = [
  {
    profilePic: "/Assets/Message/Images/profile_pic.svg",
    name: "Akash",
    text: "Hello! Hii 👋",
    time: "4:30 PM",
    unreadMessage: 1,
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic.svg",
    name: "Akash",
    text: "Hello! Hii 👋",
    time: "4:30 PM",
    unreadMessage: 1,
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic.svg",
    name: "Akash",
    text: "Hello! Hii 👋",
    time: "4:30 PM",
    unreadMessage: 1,
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic.svg",
    name: "Akash",
    text: "Hello! Hii 👋",
    time: "4:30 PM",
    unreadMessage: 1,
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic.svg",
    name: "Akash",
    text: "Hello! Hii 👋",
    time: "4:30 PM",
    unreadMessage: 1,
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic1.svg",
    name: "Akash",
    text: "Wow really Cool 🔥",
    time: "4:30 PM",
    unreadMessage: "",
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic1.svg",
    name: "Akash",
    text: "Wow really Cool 🔥",
    time: "4:30 PM",
    unreadMessage: "",
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic1.svg",
    name: "Akash",
    text: "Wow really Cool 🔥",
    time: "4:30 PM",
    unreadMessage: "",
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic1.svg",
    name: "Akash",
    text: "Wow really Cool 🔥",
    time: "4:30 PM",
    unreadMessage: "",
  },
  {
    profilePic: "/Assets/Message/Images/profile_pic1.svg",
    name: "Akash",
    text: "Wow really Cool 🔥",
    time: "4:30 PM",
    unreadMessage: "",
  },
];

const Messages = () => {
  const [open, setOpen] = useState(false);
  const [pageinfo, setPageInfo] = useState(false);

  const handleDropdownclose = () => {
    setOpen(false);
  };

  const handlePageInfoclose = () => {
    setPageInfo(false);
  };
  return (
    <>
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <div className={styles.searchBar_parent}>
            <SearchBar placeholder="Search uesrname..." />
            <span
              className={styles.page_info_Icon}
              onClick={() => setPageInfo(true)}
            >
              {ICONS.PageInfoIcon}
            </span>
          </div>
          <span
            className={commonStyle.filter_icon_mobile}
            onClick={() => setOpen(true)}
          >
            {ICONS.ThreeDotsIconWhite}
          </span>
        </div>
      </div>
      <div className={styles.message_page}>
        <Header title="Messages" />
        <div className={styles.message_list}>
          <div className={styles.connect_left_chat_box}>
            <img
              src="/Assets/Message/Images/connect_logo.svg"
              className={styles.profile_img}
              alt="..."
            />
            <div className={styles.message_box}>
              <div className={styles.message}>
                <div className={styles.text}>CONNECT AI</div>
              </div>
              <div className={styles.message}>
                <div className={styles.time}>4:30 PM</div>
                <div className={styles.unread_count}>
                  <img src="/Assets/Message/Images/pin_message.svg" alt="..." />
                </div>
              </div>
            </div>
          </div>
          {MessageData.map((item, index) => (
            <div className={styles.left_chat_box} key={index}>
              <img
                src={item.profilePic}
                className={styles.profile_img}
                alt="..."
              />
              <div className={styles.outer_dot}>
                <div className={styles.status_dot}></div>
              </div>
              <div className={styles.message_box}>
                <div className={styles.message}>
                  <div className={styles.text}>{item.name}</div>
                  <div className={styles.label}>{item.text}</div>
                </div>
                <div className={styles.message}>
                  <div className={styles.time}>{item.time}</div>
                  <div className={styles.unread_count}>
                    {item.unreadMessage === 1 ? (
                      <span className={styles.unread_message}>
                        {item.unreadMessage}
                      </span>
                    ) : (
                      <span>{item.unreadMessage}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={commonStyle.DropdownList_parent}
        style={{ display: pageinfo ? "block" : "none" }}
        onClick={() => handlePageInfoclose()}
      >
        <div className={commonStyle.DropdownList_Container}>
          <div className={commonStyle.DropdownList}>
            <span className={commonStyle.sort_by_text}>Sort by</span>
            <div className={commonStyle.DropdownList_data}>
              <input
                name="radioData"
                className={commonStyle.thisYear_radiobtn}
                type="radio"
              />
              <span className={commonStyle.text}>Read</span>
            </div>
            <div className={commonStyle.DropdownList_data}>
              <input
                name="radioData"
                className={commonStyle.thisYear_radiobtn}
                type="radio"
              />
              <span className={commonStyle.text}>Unread</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={commonStyle.DropdownList_parent}
        style={{ display: open ? "block" : "none" }}
        onClick={() => handleDropdownclose()}
      >
        <div className={commonStyle.DropdownList_Container}>
          <div className={commonStyle.logout_container}>
            <div className={commonStyle.DropdownList_data}>
              <p className={commonStyle.billing_logout_text}>
                {ICONS.editNotificationIcon}
                <span>Notification settings</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
