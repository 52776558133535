import Styles from "../CustomerRatingCard/CustomerRatingCard.module.css";
import { ICONS } from "../../../../Assets/Icons";
import StarRating from "../RatingMachanism/StarRating";

const CustomerRatingCard = ({name,date,cusromerTitle,rating,description}) => {
  return (
    <>
      <div className={Styles.rating_card_container}>
        <div className={Styles.rating_card_detail}>
          <p className={Styles.rating_heading}>Reviews</p>
          <p className={Styles.rating_value}>Verified {ICONS.blueTickIcon}</p>
          <div className={Styles.line}></div>
          <div className={Styles.profile_container}>
            <div className={Styles.profile_section}>
              <div className={Styles.profile_section_value}>
                {name} {ICONS.ratingProfileIcon}
              </div>
              <div className={Styles.date}>{date}</div>
            </div>
            <div>
              {/* <span>{ICONS.smallStarIcon}</span>
              <span>{ICONS.smallStarIcon}</span>
              <span>{ICONS.smallStarIcon}</span>
              <span>{ICONS.smallStarIcon}</span>
              <span>{ICONS.smallStarIcon}</span> */}
              <StarRating rating={rating} scale={1}/>
            </div>
          </div>
          <div className={Styles.bottom_data}>
            <div className={Styles.bottom_data_heading}>
              {cusromerTitle}
            </div>
            <div className={Styles.bottom_data_value}>
              {description}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRatingCard;
