import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Profile from "./Sections/Profile/Profile";
import ProfileSettings from "./Sections/ProfileSettings/ProfileSettings";

const MobileProfile = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Navigate to='profile' replace />} />
                <Route path='profile' element={<Profile />} />
                <Route path='personalProfile' element={<ProfileSettings />} />
            </Routes>
            <Outlet />
        </div>
    )
}

export default MobileProfile;