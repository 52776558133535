import axios from 'axios';

const baseApiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
const mainBaseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_MAIN_DEV : process.env.REACT_APP_MAIN_PROD;

const axios2 = axios.create({
    baseURL: baseApiUrl,
    withCredentials: true,
});

// Function to refresh the access token
const refreshAccessToken = async () => {
    try {
        const res = await axios2.post('/api/v1/auth/refresh', {}, { withCredentials: true });

        const newAccessToken = res.data.data.accessToken;
        const userId = res.data.data.userId;

        // Store the new access token in localStorage
        if (newAccessToken) {
            console.log(`................................................................... Silent login implemented .....................................................................`);
            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('userId', userId);
        }

        console.log('Token refreshed:', res);
        return newAccessToken;
    } catch (error) {
        console.log('Error refreshing token:', error);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');

        window.location.href = `${mainBaseUrl}/auth/login`;
        throw error;
    }
};

// Add a response interceptor to handle token refresh logic
axios2.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const newAccessToken = await refreshAccessToken();

                // Attach the new access token to the original request headers
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                return axios2(originalRequest);
            } catch (err) {
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);

// Adding an interceptor to include the access token in every request if it exists
// axios2.interceptors.request.use(
//     (config) => {
//         const accessToken = localStorage.getItem('accessToken');
//         if (accessToken) {
//             config.headers['Authorization'] = `Bearer ${accessToken}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

export default axios2;