import styles from "./NotificationList.module.css";
import { ICONS } from "../../../../Assets/Icons";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const notificationData = [
  {
    text: "Special Offer! Provide a 10% discount on renovation services this month and boost your client base.",
    time: "2 mins ago",
    status: "read",
  },
  {
    text: "New Booking: You've been booked for a home renovation project starting June 15th at 9:00 AM.",
    time: "Today",
    status: "unread",
  },
  {
    text: "Appointment Reminder: You have a scheduled site visit for a construction project tomorrow at 10:00 AM.",
    time: "Yesterday",
    status: "read",
  },
  {
    text: "Security Alert: A new login to your account was detected from a different device. If this wasn’t you, please secure your account.",
    time: "01-06-24",
    status: "unread",
  },
  {
    text: "Support Update: Your support ticket #4567 has been updated. Please review the latest response.",
    time: "31-05-24",
    status: "unread",
  },
];
const NotifiactionList = ({ small = false }) => {
  const [showId, setShowId] = useState(null);
  const [notifications, setNotifications] = useState(notificationData);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotificationMenu = (index, event) => {
    event.stopPropagation();
    index !== showId ? setShowId(index) : setShowId(null);
  };

  const markAsRead = (index) => {
    setNotifications(prevNotifications =>
      prevNotifications.map((notification, i) =>
        i === index ? { ...notification, status: notification.status === "unread" ? "read" : "unread" } : notification
      )
    );
    setShowId(null);
  };

  const deleteNotification = (index) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter((_, i) => i !== index)
    );
    setShowId(null);
  };

  const toggleRead = (index) => {
    setNotifications(prevNotifications =>
      prevNotifications.map((notification, i) =>
        i === index && notification.status === "unread" ? { ...notification, status: "read" } : notification
      )
    );
  }
  

  return (
    <>
      {notifications.map((item, index) => (
        <div
          key={index}
          className={
            small
              ? styles.notification_small
              : showId !== null
              ? styles.notification_open_threedot_menu
              : styles.notification
          }
          style={{
            backgroundColor: item.status === "unread" ? "var(--light-blue)" : "var(--white)",
            cursor: 'pointer'
          }}
          onClick={() => toggleRead(index)}
        >
          <div className={styles.notification_data}>
            <div
              className={small ? styles.status_dot_small : styles.status_dot}
              style={{
                backgroundColor: item.status === "unread" ? "var(--mint-green)" : "var(--white)",
              }}
            ></div>
            <div
              className={
                small
                  ? styles.notification_data_child_small
                  : styles.notification_data_child
              }
            >
              {item.text}
              <p className={small ? styles.time_small : styles.time}>
                {item.time}
              </p>
            </div>
          </div>
          <div
            style={{
              display: small ? "none" : "block",
            }}
            className={
              showId === index ? styles.show_threedot : styles.threedot
            }
            onClick={(event) => handleNotificationMenu(index, event)}
          >
            {ICONS.threeDotsIcon}
          </div>
          <div
            // ref={popupRef}
            className={styles.colon_popup}
            style={{ display: showId === index ? "block" : "none" }}
          >
            <div className={styles.colon_item} onClick={(e) => {
              e.stopPropagation();
              markAsRead(index)}}>
              Mark as {item.status === "unread" ? "read" : "unread"}
            </div>
            <div onClick={(e) => {
              e.stopPropagation();
              deleteNotification(index)}} className={styles.colon_item}>Delete notification</div>
          </div>
        </div>
      ))}

      {small === true && (
        <div className={styles.footer}>
          <Link to="../notification">See all notifications</Link>
        </div>
      )}
    </>
  );
};
export default NotifiactionList;
