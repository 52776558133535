// import styles from "../Styles/ProfileCommonCss.module.css";
// import Button from "../../Components/Button/Button";
// import Inputbox from "../Components/Inputbox/Inputbox";
// import { ICONS } from "../../../Assets/Icons";
// import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
// import { Link } from "react-router-dom";
// import commonStyle from "../../ServiceProviderDashboard.module.css";
// import { useState } from "react";
// import Header from "../../Components/Header/Header";
// import SearchBar from "../../Components/SearchBar/SearchBar";
// import ProfileSideBar from "../Components/ProfileSideBar/ProfileSideBar";
// import navButtonStyles from '../../Components/NavButton/NavButton.module.css';
// import axios2 from "../../../axios2";

// const SecuritySettings = () => {
//   // const [open, setOpen] = useState(false);
//   // const [show, setShow] = useState(false)
//   // const handleDropdownclose = () => {
//   //   setOpen(false);
//   // };

//   const [password, setPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmedPassword, setConfirmedPassword] = useState("");

//   const userId = localStorage.getItem('userId');
//   const accessToken = localStorage.getItem('accessToken');

//   const changePasswordHandler = async () => {
//     if (newPassword === confirmedPassword) {
//       try {
//         const res = await axios2.post("http://localhost:5000/api/v1/auth/change-password", {
//           userId,
//           password,
//           newPassword
//         }, {
//           headers: {
//             'Authorization': `Bearer ${accessToken}`,
//             'Content-Type': 'application/json'
//           },
//           withCredentials: true,
//         })

//         console.log('Password changed successfully :', res);

//       } catch (error) {
//         console.log('Error changing password :', error);
//       }
//     }
//   }

//   return (
//     <>

//       <div className={mobile_profile_style.parent}>
//         <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
//           <Link
//             to="../../mobileProfile/personalProfile"
//             className={styles.backButton}
//           >
//             {ICONS.ArrowBackIcon}
//           </Link>
//           <span>Security Settings</span>
//         </div>
//       </div>
//       <div className={styles.Profile_Container}>
//         <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
//           <p className={styles.text}>Security Settings</p>
//         </div>
//         <div className={styles.heading}>Change Password</div>
//         <div className={styles.Main_Container}>
//           <div className={styles.professional_form_data}>
//             <label className={styles.form_label}>Current Password</label>
//             <Inputbox type="password" placeholder="**********" value={password} onChange={(e) => { setPassword(e.target.value) }} />
//           </div>
//           <div className={styles.professional_form_data}>
//             <label className={styles.form_label}>New Password</label>
//             <Inputbox type="password" placeholder="**********" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
//           </div>
//           <div className={styles.professional_form_data}>
//             <label className={styles.form_label}>Confirm Password</label>
//             <Inputbox type="password" placeholder="**********" value={confirmedPassword} onChange={(e) => { setConfirmedPassword(e.target.value) }} />
//           </div>
//           <div className={styles.SecuritySettings_footer}>
//             <Button
//               padding="10px 24px"
//               text="Update Password"
//               backgroundColor="var(--yellow)"
//               color="var(--smoky-black)"
//             />
//           </div>
//         </div>

//         {/* <div className={styles.heading}>
//           Where you’re logged in
//           <p className={styles.security_heading_data}>
//             We’ll alert you via
//             <span className={styles.callNumber}>*********90</span> if there is
//             any unusual activity on your account.
//           </p>
//         </div>
//         <div className={styles.Security_Main_Container}>
//           <div className={styles.security_form_data}>
//             <div className={styles.textData}>
//               <p className={styles.security_Icondata}>
//                 {ICONS.DesktopIcon} Windows Desktop
//               </p>
//               <p
//                 className={styles.checkbox_data}
//                 style={{ marginLeft: "38px" }}
//               >
//                 <span className={styles.location}>
//                   Mumbai, Maharastra {ICONS.EllipseIcon} 14 Jan at 10:40am
//                 </span>
//               </p>
//             </div>
//             <div
//               className={`${styles.SecuritySettings_footer} ${styles.hideOnMobile}`}
//             >
//               <Button
//                 borderColor="var(--grey-80)"
//                 padding="10px 18px"
//                 text="Log out"
//                 backgroundColor="var(--white)"
//                 color="var(--smoky-black)"
//               />
//             </div>
//             <div className={styles.hideOnDesktop}>
//               <p onClick={() => setOpen(true)}>{ICONS.threeDotsIcon}</p>
//             </div>
//           </div>
//           <div className={styles.security_form_data}>
//             <div className={styles.textData}>
//               <p className={styles.security_Icondata}>
//                 {ICONS.MobileIcon} Android 14, Samsung s22 ultra
//               </p>
//               <p
//                 className={styles.checkbox_data}
//                 style={{ marginLeft: "38px" }}
//               >
//                 <span className={styles.location}>
//                   Mumbai, Maharastra {ICONS.EllipseIcon} 14 Jan at 10:40am
//                 </span>
//               </p>
//             </div>
//             <div
//               className={`${styles.SecuritySettings_footer} ${styles.hideOnMobile}`}
//             >
//               <Button
//                 borderColor="var(--grey-80)"
//                 padding="10px 18px"
//                 text="Log out"
//                 backgroundColor="var(--white)"
//                 color="var(--smoky-black)"
//               />
//             </div>
//             <div className={styles.hideOnDesktop}>
//               <p onClick={() => setOpen(true)}>{ICONS.threeDotsIcon}</p>
//             </div>
//           </div>
//         </div>

//         <div
//           className={commonStyle.DropdownList_parent}
//           style={{ display: open ? "block" : "none" }}
//           onClick={() => handleDropdownclose()}
//         >
//           <div className={commonStyle.DropdownList_Container}>
//             <div className={commonStyle.logout_container}>
//               <div className={commonStyle.DropdownList_data}>
//                 <p className={commonStyle.billing_logout_text}>
//                   {ICONS.LogoutWhiteIcon}
//                   <span>Log out from Windows Desktop</span>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div> */}
//       </div>
//     </>
//   );
// };

// export default SecuritySettings;

import styles from "../Styles/ProfileCommonCss.module.css";
import Button from "../../Components/Button/Button";
import Inputbox from "../Components/Inputbox/Inputbox";
import { ICONS } from "../../../Assets/Icons";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { Link } from "react-router-dom";
import commonStyle from "../../ServiceProviderDashboard.module.css";
import { useState } from "react";
import Header from "../../Components/Header/Header";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ProfileSideBar from "../Components/ProfileSideBar/ProfileSideBar";
import navButtonStyles from '../../Components/NavButton/NavButton.module.css';
import Loader from "../../Components/Loader/Loader";
import axios2 from "../../../axios2";
import { toast } from "react-toastify";

const SecuritySettings = () => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const userId = localStorage.getItem('userId');
  const accessToken = localStorage.getItem('accessToken');

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const changePasswordHandler = async () => {
    if (newPassword !== confirmedPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage("Password must be at least 8 characters long, include a capital letter, a small letter, a number, and a special character.");
      return;
    }

    try {
      setIsLoading(true)
      const res = await axios2.post(`${baseUrl}/api/v1/auth/change-password`, {
        userId,
        password,
        newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true,
      });

      console.log('Password changed successfully :', res);
      setErrorMessage("");
      setPassword(() => '')
      setConfirmedPassword(() => '')
      setNewPassword(() => '')
      toast.success('Password updated successfully')
    } catch (error) {
      console.log('Error changing password :', error);
      setErrorMessage("Error changing password");
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link
            to="../../mobileProfile/personalProfile"
            className={styles.backButton}
          >
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Security Settings</span>
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Security Settings</p>
        </div>
        <div className={styles.heading}>Change Password</div>
        <div className={styles.Main_Container}>
          <div className={styles.professional_form_data}>
            <label className={styles.form_label}>Current Password</label>
            <Inputbox
              type="password"
              placeholder="**********"
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
            />
          </div>
          <div className={styles.professional_form_data}>
            <label className={styles.form_label}>New Password</label>
            <Inputbox
              type="password"
              placeholder="**********"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value); }}
            />
          </div>
          <div className={styles.professional_form_data}>
            <label className={styles.form_label}>Confirm Password</label>
            <Inputbox
              type="password"
              placeholder="**********"
              value={confirmedPassword}
              onChange={(e) => { setConfirmedPassword(e.target.value); }}
            />
          </div>
          {errorMessage && (
            <div className={styles.error_message}>
              {errorMessage}
            </div>
          )}
          <div className={styles.SecuritySettings_footer}>
            <Button
              padding="10px 24px"
              text="Update Password"
              backgroundColor="var(--yellow)"
              color="var(--smoky-black)"
              onClick={changePasswordHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecuritySettings;
