import Styles from "./Button.module.css";

const Button = ({
  text,
  backgroundColor,
  color,
  borderColor,
  padding,
  width,
  height,
  fontSize,
  onClick,
  children,
  disabled = false,
  minWidth,
  maxWidth
}) => {

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={Styles.button}
      style={{
        backgroundColor: disabled ? '#b8b8b8' : backgroundColor,
        color: disabled ? '#837f7f' : color,
        border: disabled ? 'none' : borderColor
          ? `1px solid ${borderColor} `
          : `1px solid ${backgroundColor}`,
        padding: padding,
        width: width,
        height: height,
        fontSize: fontSize,
        minWidth: minWidth ? minWidth : '',
        maxWidth: maxWidth
      }}
    >
      {text ? text : children}
    </button>
  );
};

export default Button;
