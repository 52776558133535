import styles from "./DeleteComponent.module.css";
import { ICONS } from "../../../../Assets/Icons";
import Button from "../../../Components/Button/Button";

const DeleteComponent = ({ setShow, onClick }) => {

    const handleLogout = () => {
        setShow(false);
    }

    return (
        <>
            <div className={styles.logout_Container}>
                <div className={styles.logout_popup}>
                    <div className={styles.logout_heading}>
                        Delete
                        <p className={styles.logout_text}>
                            Are you sure you want to delete?
                        </p>
                    </div>
                    <div onClick={() => setShow(false)} className={styles.logout_icon}>{ICONS.logoutCloseIcon}</div>
                </div>
                <div className={styles.footer_logout_btn}>
                    <Button
                        width="100%"
                        borderColor="var(--grey-80)"
                        padding="14px 18px"
                        text="No"
                        backgroundColor="var(--white)"
                        color="var(--smoky-black)"
                        onClick={() => setShow(false)}
                    />
                    <Button
                        width="100%"
                        padding="14px 18px"
                        text="Yes"
                        backgroundColor="var(--red)"
                        color="var(--white)"
                        onClick={() => onClick()}
                    />
                </div>
            </div>
        </>
    );
};
export default DeleteComponent;
