import Styles from "./SearchBar.module.css";

import { ICONS } from "../../../Assets/Icons";

const SearchBar = ({ onChange, placeholder, minWidth, height, width }) => {
  return (
    <div
      className={Styles.input_parent}
      style={{ minWidth: minWidth, height: height, width: width }}
    >
      {ICONS.searchIcon}
      <input
        onChange={onChange}
        className={Styles.input}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchBar;
