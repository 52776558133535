import axios from "axios";
import Button from "../../../Components/Button/Button";
import OrderSearchSection from "../../Components/OrderSearchSection/OrderSearchSection";
import Pagination from "../../Components/Pagination/Pagination";
import styles from "../../Styles/TableCommonCss.module.css";
import { useEffect, useState } from "react";
import Loader from "../../../Components/Loader/Loader";
import EmptyStateComponent from "../../../Components/EmptyStateComponent/EmptyStateComponent";
import { toast } from "react-toastify";
import axios2 from "../../../../axios2";
import useRetriveFileHook from "../../../../hooks/useRetriveFileHook";
import Attachment from "../../Components/Attachments/Attachments";
import { ICONS } from "../../../../Assets/Icons";

const Rejected = ({
  mobileDateRange,
  mobileOrderTypes,
  mobileAmount,
  renderRejected,
}) => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  const [orderListNewData, setOrderListNewData] = useState([]);
  const [dateRange, setDateRange] = useState([new Date('2020-03-11'), new Date('9999-01-01')]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [amount, setAmount] = useState([0, 10000]);
  const [search, setSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showAttachment, setShowAttachment] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const { attachmentUrls, retriveDocuments } = useRetriveFileHook(
    setIsLoading,
    showAttachment
  );

  const showAttachmentFunction = (attachments) => {
    console.log(attachments);
    attachments?.forEach((id) => retriveDocuments(id));
    // console.log(attachments);
    setShowAttachment(true);
  };

  const fetchRejectedServiceJobs = async () => {
    const screenWidth = window.innerWidth;
    try {
      let url = `${baseUrl}/api/v1/order-management/service-provider`;
      let reqBody = {
        userId,
        status: "Rejected",
        page: currPage,
        limit: 5
      };
      if (screenWidth <= 768) {
        console.log("Screen Width :", screenWidth);
        if (mobileDateRange) {
          const dateObj1 = new Date(mobileDateRange[0]);
          const dateObj2 = new Date(mobileDateRange[1]);
          const isoDateStart = dateObj1.toISOString();
          const isoDateEnd = dateObj2.toISOString();
          // url += `&dateStart=${isoDateStart}&dateEnd=${isoDateEnd}`;
          reqBody = { ...reqBody, dateStart: isoDateStart, dateEnd: isoDateEnd };
        }
        if (mobileAmount) {
          // url += `&priceStart=${mobileAmount[0]}&priceEnd=${mobileAmount[1]}`;
          reqBody = { ...reqBody, priceStart: mobileAmount[0], priceEnd: mobileAmount[1] };
        }
        if (mobileOrderTypes && mobileOrderTypes.length !== 0) {
          let subCategoryStr = ``;
          for (let i = 0; i < mobileOrderTypes.length; i++) {
            if (i === mobileOrderTypes.length - 1)
              subCategoryStr += mobileOrderTypes[i];
            else subCategoryStr += mobileOrderTypes[i] + ",";
          }
          // url += `&subCategory=` + subCategoryStr;
          reqBody = { ...reqBody, subCategory: subCategoryStr };
        }
        console.log("mobile url :", url);
      } else {
        if (dateRange) {
          const dateObj1 = new Date(dateRange[0]);
          const dateObj2 = new Date(dateRange[1]);
          const isoDateStart = dateObj1.toISOString();
          const isoDateEnd = dateObj2.toISOString();
          // url += `&dateStart=${isoDateStart}&dateEnd=${isoDateEnd}`;
          reqBody = { ...reqBody, dateStart: isoDateStart, dateEnd: isoDateEnd };
        }
        if (amount) {
          // url += `&priceStart=${amount[0]}&priceEnd=${amount[1]}`;
          reqBody = { ...reqBody, priceStart: amount[0], priceEnd: amount[1] };
        }
        if (orderTypes) {
          let subCategoryStr = ``;
          for (let i = 0; i < orderTypes.length; i++) {
            if (i === orderTypes.length - 1) subCategoryStr += orderTypes[i];
            else subCategoryStr += orderTypes[i] + ",";
          }
          // url += `&subCategory=` + subCategoryStr;
          reqBody = { ...reqBody, subCategory: subCategoryStr };
        }
        console.log("url :", url);
      }
      setIsLoading(true);
      const res = await axios2.post(url, reqBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      console.log(res, 'res')
      setOrderListNewData(res.data.data.services);
      setTotalPages(res.data.data.totalPages);

    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
        console.log(error);
      } else {
        console.log(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRejectedServiceJobs();
  }, [
    dateRange,
    amount,
    orderTypes,
    currPage,
    renderRejected,
    mobileDateRange,
    mobileOrderTypes,
    mobileAmount,
  ]);

  return (
    <div className={styles.parent_page}>
      <Loader isLoading={isLoading} />
      <Attachment
        attachmentUrls={attachmentUrls}
        showAttachment={showAttachment}
        onClose={() => {
          setShowAttachment(!showAttachment);

        }}
      />
      <OrderSearchSection
        amount={amount}
        dateRange={dateRange}
        orderTypes={orderTypes}
        setAmount={setAmount}
        setOrderTypes={setOrderTypes}
        setDateRange={setDateRange}
        setSearch={setSearch}
      />
      {orderListNewData?.length === 0 &&
        <EmptyStateComponent
          stateType="emptyConstructionState"
          heading="No Rejected Request !"
          subHeading="You haven’t rejected any requests yet. Your rejected job requests will be shown here."
        />
      }
      <table className={styles.table}>
        <thead>
          <tr>
            {/* <th>
              <input type="checkbox" />
            </th> */}
            <th className={styles.job_details}>
              <div className={styles.table_data}>
                <span>Job Details</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Description</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Amount</span>
              </div>
            </th>
            <th>
              <div className={styles.table_data}>
                <span>Reason for Rejection</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            orderListNewData?.map((item) => (
              <tr key={item.orderItemBookingId}>
                {/* <td style={{ verticalAlign: "top" }}>
                <div className={styles.checkbox_data}>
                  <input type="checkbox" />
                </div>
              </td> */}
                <td className={styles.hideOnMobile}>
                  {/* <div className={styles.job_req_header}>
                  <div className={styles.job_req_header_data}> */}
                  {/* <p className={styles.main_heading}>{item.category}</p> */}
                  <div className="space_between">
                    <span className={styles.main_heading_data}>
                      Job ID: {item.orderItemBookingId}
                    </span>
                    <span
                      className={styles.clip}
                      onClick={() =>
                        // item.attachments.length > 0 &&
                        showAttachmentFunction(item.attachments)
                      }
                    >
                      {ICONS.clipIcon}
                      <span className={styles.attachment_count}>
                        {item.attachments.length}
                      </span>
                    </span>
                  </div>

                  {/* </div> */}
                  {/* <input type="checkbox" /> */}
                  {/* </div> */}
                </td>
                <td className={styles.job_details}>
                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Job ID: </span>
                    <span>{item.orderItemBookingId}</span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Date & Time: </span>
                    <span>
                      {item.deliveryDate} {item.deliveryTime}
                    </span>
                  </div>
                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Service Name: </span>
                    <span>{item.serviceCategory}</span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Work Location: </span>
                    <span>
                      {item.orderItemBooking.address.city}/{" "}
                      {item.orderItemBooking.address.addressLine1}
                    </span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Contact Number: </span>
                    <span>xxxxxxxxxx </span>
                  </div>
                </td>
                <td>
                  <div className={styles.order_card_detail}>
                    <span>Work Details: </span>
                    <span>{item.workDetails ? item.workDetails : 'not added'}</span>
                  </div>
                  <div className={styles.order_card_detail}>
                    <span>Additional Notes: </span>
                    <span>{item.additionalNote ? item.additionalNote : 'not added'}</span>
                  </div>
                </td>
                <td>
                  <div className={styles.order_card_detail}>
                    <span>Service Charges: </span>
                    <span>₹ {item.price}</span>
                  </div>
                  <div
                    className={`${styles.order_card_detail} ${styles.hideOnDesktop}`}
                  >
                    <span>Attachments: </span>
                    <span
                      className={styles.attachments_value}
                      onClick={() =>
                        item.attachments.length > 0 &&
                        showAttachmentFunction(item.attachments)
                      }
                    >
                      {" "}
                      {item.attachments.length} attachments
                    </span>
                  </div>
                </td>
                <td>
                  <div
                    className={`${styles.reason_for_reject} ${styles.hideOnDesktop}`}
                  >
                    <span>{item.reasonIfRejected}</span>
                  </div>
                  <div
                    className={`${styles.reason_for_reject_onMobile} ${styles.hideOnMobile1}`}
                  >
                    <p>Reason for Rejection:</p>
                    <span>{item.reasonIfRejected}</span>
                  </div>
                </td>
                {/* <td>
                <div className={styles.reason_for_reject}>
                  <span>
                    The requested service date and time do not align with our
                    current schedule. Please select an alternate time or contact
                    support for further assistance.
                  </span>
                </div>
              </td> */}
              </tr>
            )
            )}
        </tbody>
      </table>
      <Pagination
        pageLength={orderListNewData?.length}
        currentPage={currPage}
        totalPages={totalPages}
        setCurrPage={setCurrPage}
      />
    </div>
  );
};
export default Rejected;
