import { useEffect, useState } from "react";
import axios2 from "../axios2";
import { toast } from "react-toastify";

const useRetriveFileHook = (
  setIsLoading,
  showAttachment
) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const accessToken = localStorage.getItem("accessToken");

  const [attachmentUrls, setAttachmentUrls] = useState([]);
  // console.log(attachmentUrls);

  const retriveDocuments = async (docId) => {
    console.log(attachmentUrls);
    // console.log(docId);
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/auth/document-retrival`,
        {
          documentId: docId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(response, "retrieved Document");

      if (response.status === 200) {
        setAttachmentUrls((prev) => [...prev, response.data.data.url]);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showAttachment === false) {
      setAttachmentUrls([]);
    }
  }, [showAttachment]);

  return { attachmentUrls, retriveDocuments };
};

export default useRetriveFileHook;
