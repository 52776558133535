import styles from './EditButton.module.css';

const EditButton = ({ onClick, edit }) => {

    // console.log(onClick,'onClick')
    return (
        <button onClick={onClick} className={edit ? styles.discard_button : styles.edit_button} >
            {edit ? 'Discard Changes' : 'Edit Profile'}
        </button>
    )
}

export default EditButton;