import React, { useEffect, useRef, useState } from "react";
import styles from "../DropdownList/DropdownList.module.css";
import { ICONS } from "../../../../Assets/Icons";

const DropdownList = ({
  edit,
  setOptionSelected,
  serviceToBeAdded,
  optionSelected,
  emptyValue,
  options,
  defaultValue,
  from,
  minWidth,
  padding,
  selectType,
  keyName,
}) => {
  const initialvalue = defaultValue || "Choose an option";
  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState(initialvalue);
  const ref = useRef(null);

  const handleClickOutside = (event) => {

    if (ref.current && !ref.current.contains(event.target)) {

      setShowOptions(!showOptions);
    }
  };
  // console.log(setOptionSelected, " setOptionSelected");
  //console.log(optionSelected, "optionSelected");

  const handleToggle = (value) => {
    console.log(value, 'value')
    setSelected(value);
    setShowOptions(false);
  };

  const handleCheck = (e) => {
    const { value, checked } = e.target;
    //  console.log(value, checked);

    if (value === "All of the above" && checked) {
      //  console.log(value, "All above");
      setOptionSelected((serviceToBeAdded) => ({
        ...serviceToBeAdded,
        [keyName]: [...options.slice(0, -1)],
      }));
    } else if (value === "All of the above" && !checked) {
      //  console.log(value, checked);
      setOptionSelected((serviceToBeAdded) => ({
        ...serviceToBeAdded,
        [keyName]: [],
      }));
    } else if (value === "none" && checked) {
      setOptionSelected((serviceToBeAdded) => ({
        ...serviceToBeAdded,
        [keyName]: ["none"],
      }));
      setShowOptions(false);
    } else if (checked) {
      setOptionSelected((serviceToBeAdded) => ({
        ...serviceToBeAdded,
        [keyName]: [...optionSelected, value],
      }));
    } else {
      const updatedValue = [...optionSelected].filter(
        (option) => option !== value
      );
      setOptionSelected((serviceToBeAdded) => ({
        ...serviceToBeAdded,
        [keyName]: updatedValue,
      }));
    }
  };

  useEffect(() => {
    if (
      Array.isArray(optionSelected) &&
      optionSelected?.includes("none") &&
      optionSelected.length > 1
    ) {
      const updatedArray = [...optionSelected].filter(
        (option) => option !== "none"
      );
      setOptionSelected((prev) => ({ ...prev, [keyName]: updatedArray }));
    }
  }, [optionSelected]);

  useEffect(() => {
    if (selected !== initialvalue) {
      console.log(selected, 'selected')
      console.log(keyName, 'keyName')
      setOptionSelected({
        ...serviceToBeAdded,
        [keyName]: selected,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (showOptions) {

      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  console.log(initialvalue, 'initialvalue')


  return (
    <div
      style={{ minWidth: minWidth }}
      // className={styles.DropdownList_parent}
      ref={ref}

      className={
        emptyValue &&
          ((Array.isArray(optionSelected) && optionSelected.length === 0) ||
            optionSelected === "")
          ? `${styles.emptyInput} ${styles.DropdownList_parent}`
          : styles.DropdownList_parent
      }
    >
      <div
        className={styles.dropdownList}
        // style={{ padding: padding }}
        style={{
          backgroundColor: edit ? 'var(--lavender-blue)' : 'var(--light-grey)',
          color: edit ? ' var(--smoky-black)' : 'var(--grey)'
        }}

        onClick={() => (edit ? setShowOptions(!showOptions) : null)}
      >
        <div className={styles.dropdownList_text}
          style={{
            backgroundColor: edit ? 'var(--lavender-blue)' : 'var(--light-grey)',
            color: edit ? ' var(--smoky-black)' : 'var(--grey)'
          }}
        >
          {Array.isArray(optionSelected) && optionSelected.length > 0
            ? optionSelected?.map((option) => <span> {option}, </span>)
            : optionSelected?.length === 0 && initialvalue || optionSelected || initialvalue}
        </div>
        <div className={showOptions ? styles.DropdownList_Cursor : null}>
          {ICONS.cursorDownGrey}
        </div>
      </div>
      {showOptions && (
        <div className={styles.DropdownList_Data}>
          {options?.map((item, index) =>
            selectType === "multiple" ? (
              <label className={styles.DropdownList_childData}>
                <input
                  type="checkbox"
                  value={item.name || item}
                  onChange={(e) => handleCheck(e)}
                  checked={optionSelected?.includes(item.name || item)}
                  className={styles.checkbox}
                />
                {item.name || item}
              </label>
            ) : (
              <p
                className={styles.DropdownList_childData}
                key={index}
                onClick={() => handleToggle(item)}
              >
                {item}
              </p>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownList;
