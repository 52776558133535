//import styles from "../Notifiactions/Notifiactions.module.css";
import styles from "./Notifiactions.module.css";
import commonStyle from "../ServiceProviderDashboard.module.css";
import notification_styles from "./Notifiactions.module.css";
import Header from "../Components/Header/Header";
import NavButton from "../Components/NavButton/NavButton";
import NotificationList from "./Components/NotificationList/NotificationList";
import { useState } from "react";
import { ICONS } from "../../Assets/Icons";
import { Link, useNavigate } from "react-router-dom";

const Notifiactions = () => {
  const [show, setShow] = useState("All");
  const [showOptions, setOptions] = useState(false);
  const [dropDownClose, setDropDownClose] = useState(false);

  const navigate = useNavigate();

  const handleMarkAllAsRead = () => {

    // api call
    setDropDownClose(false);
  };

  const handleNotificationSettings = () => {
    navigate("../settings/notification");
    setDropDownClose(false);
  };

  return (
    <>
      <div className={commonStyle.mobileNavbar}>
        <Link to='../home' className={styles.backButton}>{ICONS.ArrowBackIcon}</Link><span>Notification</span>
        <span
          onClick={() => setDropDownClose(true)}
          className={commonStyle.filter_icon_mobile}
        >
          {ICONS.ThreeDotsIconWhite}
        </span>
      </div>
      <Header title="Notifications" />
      <NavButton
        show={show}
        setShow={setShow}
      // options={[
      //   "All",
      //   "Promotional",
      //   "Transactional",
      //   "Reminder",
      //   "Security",
      //   "Support",
      // ]}
      />
      {/* <div
        className={
          showOptions
            ? `${notification_styles.menu_option} ${notification_styles.menu_option_active}`
            : notification_styles.menu_option
        }
        onClick={() => setOptions(!showOptions)}
      >
        {ICONS.threeDotsIcon}
      </div> */}
      <div
        className={notification_styles.colon_popup}
        style={{ display: showOptions ? "block" : "none" }}
      >
        <div className={styles.colon_item}>Mark all as unread</div>
        <div className={styles.colon_item}>Notification settings</div>
      </div>

      <div className={notification_styles.notificationList}>
        <NotificationList />
      </div>

      {/* DropDown menu section */}

      <div
        style={{ display: dropDownClose ? "block" : "none" }}
        onClick={() => setDropDownClose(false)}
        className={commonStyle.DropdownList_parent}
      >
        <div className={commonStyle.DropdownList_Container}>
          <div className={commonStyle.DropdownList}>
            <div
              className={commonStyle.DropdownList_data}
              onClick={() => handleMarkAllAsRead()}
            >
              <span>{ICONS.blackTickIcon}</span>
              <p>Mark all read</p>
            </div>
            <div
              className={commonStyle.DropdownList_data}
              onClick={() => handleNotificationSettings()}
            >
              <span>{ICONS.editNotificationIcon}</span>
              <p>Notification settings</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Notifiactions;
