import styles from "./Inputbox.module.css";

const Inputbox = ({
  emptyValue,
  type,
  placeholder,
  onChange,
  readOnly,
  value,
  padding,
  minWidth,
  maxWidth,
  name,
  max,
  min,
  color
}) => {

  // console.log(emptyValue, value)

  return (
    <div className={emptyValue && value === "" || value === 0 ? styles.emptyInput : null}>
      <input
        name={name}
        value={value}
        max={max}
        min={min}
        readOnly={readOnly}
        onChange={onChange}
        type={type ? type : "string"}
        placeholder={placeholder}
        className={styles.profileInput}
        style={{
          padding: padding, maxWidth: maxWidth, minWidth: minWidth,
          backgroundColor: readOnly ? 'var(--light-grey)' : 'var(--lavender-blue)',
          color: readOnly ? 'var(--grey)' : ' var(--smoky-black)'
        }}
      />
    </div>
  );
};
export default Inputbox;
