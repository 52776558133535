import Styles from "./Header.module.css";
import { ICONS } from "../../../Assets/Icons";
import "../../../utils/utilsCss/utilsCss.css";
import { useEffect, useState } from "react";
import NotifiactionList from "../../Notifiactions/Components/NotificationList/NotificationList";
import ProfilePopup from "../../Profile_Settings/Components/ProfileExtendedPopup/ProfilePopup";
import axios2 from "../../../axios2";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store";
import { toast } from "react-toastify";

const Header = ({ title }) => {
  const dispatch = useDispatch();
  // const [dataa, setData] = useState(null);
  const stateData = localStorage.getItem('state')
  const state = JSON.parse(stateData)

  const [profilePic, setProfilePic] = useState(null);
  const sectorId = state.currSectorId;
  const [show, setShow] = useState(null);
  const userType = state.userType;
  const dataRequested = localStorage.getItem('storedData');
  const data = JSON.parse(dataRequested);

  const handleToggle = (value) => {
    if (show === value) {
      setShow(null);
    } else {
      setShow(value);
    }
  };

  const accessToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");

  // ----------------API-----------------------------------

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const retriveDocuments = async (docId) => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/auth/document-retrival`,
        {
          documentId: docId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setProfilePic(response.data.data.url);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const fetchUserDetails = async () => {
    try {
      if (userType === "Individual") {
        const res = await axios2.post(
          `${baseUrl}/api/v1/profile/your-personal-details`,
          {
            userId: userId,
            sectorId: sectorId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log(res, "res");
        localStorage.setItem('storedData', JSON.stringify(res.data.data.personalDetails))

        dispatch(authActions.setUserDetails(res.data.data.personalDetails));
        if (res.data.data?.personalDetails?.profilePictureUploadId) {
          retriveDocuments(
            res.data.data.personalDetails.profilePictureUploadId
          );
        }
      } else if (userType === "Business") {
        const res = await axios2.post(
          `${baseUrl}/api/v1/profile/your-business-details`,
          {
            userId: userId,
            sectorId: sectorId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log(res, "res");
        localStorage.setItem('storedData', JSON.stringify(res.data.data.businessDetails))
        // setData(res.data.data.businessDetails);
        dispatch(authActions.setUserDetails(res.data.data.businessDetails));
        if (res.data.data?.businessDetails?.profilePictureUploadId) {
          retriveDocuments(
            res.data.data.businessDetails.profilePictureUploadId
          );
        }
      }
    } catch (error) {
      console.log("Error fetching user Details :", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userType]);

  console.log(data);
  console.log(userType)

  // -----------------------------------------------------------

  return (
    <div>
      <header className={Styles.header}>
        <h2>{title}</h2>
        <div className={`space_between ${Styles.header_right_section}`}>
          <div style={{ position: "relative" }}>
            <span
              onClick={() => handleToggle("notification")}
              className={
                show === "notification"
                  ? Styles.bell_icon_active
                  : Styles.bell_icon
              }
            >
              {ICONS.notificationsIconBlack}
            </span>
            <div
              className={Styles.notificationList}
              style={{ display: show === "notification" ? "block" : "none" }}
            >
              <NotifiactionList small={true} />
            </div>
          </div>

          <div
            onClick={() => handleToggle("profile")}
            className={
              show === "profile"
                ? `space_between ${Styles.profile_section_active}`
                : `space_between ${Styles.profile_section}`
            }
          >
            <img
              className={Styles.profile_img}
              src={
                profilePic ||
                "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/emptyProfileImage.svg"
              }
              alt=""
            />
            <p className={Styles.profile_name}>
              Hi, {data ? data.fullName || data.companyName : "Service Provider"}
            </p>
            <span className={show === "profile" ? Styles.open_cursor : null}>
              {ICONS.cursorDown}
            </span>
          </div>
          <div
            style={{ display: show === "profile" ? "block" : "none" }}
            className={Styles.profile_dropdown}
          >
            <ProfilePopup setShow={setShow} />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
