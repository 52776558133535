import React, { useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";

import Styles from "../../../../commonCss.module.css";
import axios2 from "../../../../axios2";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const OrdersBreakoutChart = ({ selectedYear, dropdownOption }) => {
  const [dataFetched, setDataFetched] = useState(null);
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  const dataObject = (fetchedArray) => {
    const dataObject = fetchedArray.reduce(
      (acc, crr) => (acc = { ...acc, [crr.status]: crr }),
      {}
    );

    setDataFetched(dataObject);
  };

  const totalCount =
    dataFetched?.Accepted?.count +
    dataFetched?.Rejected?.count +
    dataFetched?.Completed?.count;

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const fetchData = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/by_year_month_week`,
        {
          year: selectedYear,
          period: dropdownOption.split(" ")[1],
          userId,
          sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      dataObject(response.data.data.currentPeriod);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const data = {
    labels: [
      dataFetched?.Accepted?.status,
      dataFetched?.Rejected?.status,
      dataFetched?.Completed?.status,
    ],
    datasets: [
      {
        data: [
          dataFetched?.Accepted?.count,
          dataFetched?.Rejected?.count,
          dataFetched?.Completed?.count,
        ],
        backgroundColor: ["#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: ["#FFCE56", "#FF6384", "#36A2EB"],
        borderWidth: 1,
        spacing: 3,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: 0,
    },
    maintainAspectRatio: false,
    // aspectRatio: 1.,
    //responsive: true,
    cutout: "60%",
  };

  useEffect(() => {
    if (userId && sectorId) {
      fetchData();
    }
  }, [selectedYear, dropdownOption, userId, sectorId]);

  return (
    <div className={Styles.orderBreakoutChart}>
      <div className={Styles.orderBreakout_doughnut_parent}>
        <Chart
          type="doughnut"
          data={data}
          options={options}
          className={Styles.doughnut_chart}
        />
        <div className={Styles.breakout_center_text}>
          <h1>{totalCount ? totalCount : 0}</h1>
          <p>All Orders</p>
        </div>
      </div>

      <div className={Styles.legend_list}>
        <p className={Styles.breakout_legend}>
          <span
            style={{ backgroundColor: "#FFCE56" }}
            className={Styles.legend_color}
          ></span>
          {dataFetched?.Accepted?.status} <span className={Styles.space}></span>{" "}
          <b>{dataFetched?.Accepted?.count}</b>
        </p>
        <p className={Styles.breakout_legend}>
          <span
            style={{ backgroundColor: "#FF6384" }}
            className={Styles.legend_color}
          ></span>
          {dataFetched?.Rejected?.status} <span className={Styles.space}></span>
          <b> {dataFetched?.Rejected?.count}</b>
        </p>
        <p className={Styles.breakout_legend}>
          <span
            style={{ backgroundColor: "#36A2EB" }}
            className={Styles.legend_color}
          ></span>
          {dataFetched?.Completed?.status}{" "}
          <span className={Styles.space}></span>{" "}
          <b>{dataFetched?.Completed?.count}</b>
        </p>
      </div>
    </div>
  );
};

export default OrdersBreakoutChart;
