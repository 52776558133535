import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";

const initialAuthState = {
  accessToken: null,
  userId: null,
  userType: "Individual",
  userMobileNumber: null,
  currSectorId: null,
  currCategoryId: null,
  currCategoryName: "category",
  userDetails: null,
};

console.log(initialAuthState, "userDetails");

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setUserId: (state, actions) => {
      state.userId = actions.payload;
    },
    setUserType: (state, actions) => {
      state.userType = actions.payload;
    },
    setToken: (state, actions) => {
      state.accessToken = actions.payload;
    },
    setUserMobileNumber: (state, actions) => {
      state.userMobileNumber = actions.payload;
    },
    setCurrSectorId: (state, actions) => {
      state.currSectorId = actions.payload;
    },
    setCurrCategoryId: (state, actions) => {
      state.currCategoryId = actions.payload;
    },
    setCurrCategoryName: (state, actions) => {
      state.currCategoryName = actions.payload;
    },
    setUserDetails: (state, actions) => {
      state.userDetails = actions.payload;
    },
  },
});

export const authActions = authSlice.actions;

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

export default store;
