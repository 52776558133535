import styles from "../Styles/ProfileCommonCss.module.css";
import commonStyle from "../../ServiceProviderDashboard.module.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { ICONS } from "../../../Assets/Icons";
import Button from "../../Components/Button/Button";
import Inputbox from "../Components/Inputbox/Inputbox";
import Loader from "../../Components/Loader/Loader";
import UploadFile from "../../Components/UploadFile/UploadFile";
import EditButton from "../Components/EditButton/EditButton";
import SaveChangesButton from "../Components/SaveChangesButton/SaveChangesButton";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";
import DropdownList from "../Components/DropdownList/DropdownList";

const PersonalDetails = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  // login states
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;
  const mobileNumber = stateData.userMobileNumber;
  const [defaultProfilePic, setDefaultProfilePic] = useState(
    "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/emptyProfileImage.svg"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [emptyValue, setEmptyValue] = useState(null);
  const [recievedFile, setRecievedFile] = useState(null);
  const [socialMediaProfile, setSocialMediaProfile] = useState('')
  const [recievedDocUrls, setRecievedDocUrls] = useState({
    profilePic: "",
    aadharCard: "",
    profilePic: "",
  });
  const [discard, setDiscard] = useState(null);
  const [sameAs, setSameAs] = useState(null);
  const [error, setError] = useState({
    mobileNumber: null,
    emailAddress: null,
    bio: null,
    aadhar: null,
    pan: null,
    profilePic: null,
    "permanentAddress.city": null,
    "permanentAddress?.state": null,
    "permanentAddress?.pincode": null,
    "currentAddress.city": null,
    "currentAddress?.state": null,
    "currentAddress?.pincode": null,
  });
  const [profilePic, setProfilePic] = useState({
    key: "",
    documentType: "",
    documentDescription: "",
    fileObject: null,
    fileLink: null,
    docUrlRecievedFromDB: null,
    recievedUploadedDocId: null,
    emptyProfilePic:
      "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/emptyProfileImage.svg",
  });

  const [pancard, setPancard] = useState({
    key: "",
    documentType: "",
    documentDescription: "",
    docUrlRecievedFromDB: null,
    fileObject: null,
    recievedUploadedDocId: null,
  });

  const [adharcard, setAdharcard] = useState({
    key: "",
    documentType: "",
    documentDescription: "",
    docUrlRecievedFromDB: null,
    fileObject: null,
    recievedUploadedDocId: null,
  });

  const [personalDetails, setPersonalDetails] = useState({
    fullName: "",
    dob: "",
    mobileNumber: "",
    emailAddress: "",
    gender: '',
    preferredLanguage: '',
    bio: "",
    permanentAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
    },
    currentAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
    },
  });

  const handleEditDiscard = () => {
    if (edit) {
      setEdit(false);
      setDiscard(true);
      setEmptyValue(null);
      setError({
        mobileNumber: null,
        emailAddress: null,
        bio: null,
      });
    } else {
      setEdit(true);
      setDiscard(false);
      setEmptyValue(null);
    }
  };

  const handleProfilePic = (e) => {
    if (e.target.files.length === 0) return;
    const value = e.target.files[0];
    if (value?.size > 2 * 1024 * 1024) {
      setError(() => ({ ...error, profilePic: "size exceeds 2MB" }));
      setProfilePic({
        ...profilePic,
        fileObject: null,
        fileLink: defaultProfilePic,
      });
    } else {
      setProfilePic({
        ...profilePic,
        key: `profile-pictures/${mobileNumber}-${Date.now()}.jpeg`,
        documentType: "Profile",
        documentDescription: "Profile picture uploaded by user",
        fileObject: e.target.files[0],
        fileLink: URL.createObjectURL(value),
        recievedUploadedDocId: null,
        docUrlRecievedFromDB: null,
      });
      setError(() => ({ ...error, profilePic: null }));
    }
  };

  const handleAdharCard = (value) => {
    if (value?.length === 0) return;
    if (value?.size > 4 * 1024 * 1024) {
      setError(() => ({ ...error, aadhar: "size exceeds 10MB" }));
      setAdharcard({ ...adharcard, fileObject: null });
    } else {
      setAdharcard({
        ...adharcard,
        key: `adhar-card/${mobileNumber}-${Date.now()}.${value?.type.split("/")[1]
          }`,
        documentType: "Aadhar",
        documentDescription: "Adharcard uploaded by user",
        fileObject: value,

        recievedUploadedDocId: null,
      });
      setError((error) => ({ ...error, aadhar: null }));
    }
  };

  const handlePanCard = (value) => {
    if (value === null) return;
    if (value?.size > 4 * 1024 * 1024) {
      setError(() => ({ ...error, pan: "size exceeds 10MB" }));
      setPancard({ ...pancard, fileObject: null });
    } else {
      setPancard({
        ...pancard,
        key: `pan-card/${mobileNumber}-${Date.now()}.${value?.type.split("/")[1]
          }`,
        documentType: "Pan",
        documentDescription: "Profile picture uploaded by user",
        fileObject: value,
        recievedUploadedDocId: null,
      });
      setError(() => ({ ...error, pan: null }));
    }
  };

  const handleSameAs = () => {
    setSameAs(!sameAs);
    if (!sameAs) {
      setPersonalDetails(prev => ({
        ...prev,
        currentAddress: {
          ...prev.currentAddress,
          addressLine1: prev.permanentAddress.addressLine1,
          addressLine2: prev.permanentAddress.addressLine2,
          city: prev.permanentAddress.city,
          state: prev.permanentAddress.state,
          pincode: prev.permanentAddress.pincode
        }
      }));
    }
  };

  const validateEntry = (value, name) => {
    switch (name) {
      case "mobileNumber":
        if (value) {
          if (!/^[789]\d{9}$/.test(value)) {
            return "Mobile number is invalid";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "emailAddress":
        if (value) {
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return "Email address is invalid";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "bio":
        if (value) {
          if (value.length >= 300) {
            return "Max limit is 300 words";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "permanentAddress.city":
        if (value) {
          if (!/^[A-Za-z\s]+$/.test(value)) {
            return "invalid city name";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "permanentAddress.state":
        if (value) {
          if (!/^[A-Za-z\s]+$/.test(value)) {
            return "invalid state name";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "permanentAddress.pincode":
        if (value) {
          if (!/^\d{6}$/.test(value)) {
            return "invalid pincode";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "currentAddress.city":
        if (value) {
          if (!/^[A-Za-z\s]+$/.test(value)) {
            return "invalid  city name";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "currentAddress.state":
        if (value) {
          if (!/^[A-Za-z\s]+$/.test(value)) {
            return "invalid  state name";
          } else {
            return null;
          }
        } else {
          return null;
        }

      case "currentAddress.pincode":
        if (value) {
          if (!/^\d{6}$/.test(value)) {
            return "invalid  pincode";
          } else {
            return null;
          }
        } else {
          return null;
        }

      default:
        return null;
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    console.log('value / ////////////', value,'sssssss',name)
    if (name.startsWith("permanentAddress.")) {
      const field = name.split(".")[1];
      setPersonalDetails(prev => {
        const updatedPermanentAddress = {
          ...prev.permanentAddress,
          [field]: value
        };
        return {
          ...prev,
          permanentAddress: updatedPermanentAddress,
          currentAddress: sameAs ? {
            ...prev.currentAddress,
            [field]: value
          } : prev.currentAddress
        };
      });
    } else if (name.startsWith("currentAddress.") && !sameAs) {
      const field = name.split(".")[1];
      setPersonalDetails(prev => ({
        ...prev,
        currentAddress: {
          ...prev.currentAddress,
          [field]: value,
        },
      }));
    } else {
      setPersonalDetails(prev => ({ ...prev, [name]: value }));
    }

    const errorMessage = validateEntry(value, name);
    setError(prev => ({ ...prev, [name]: errorMessage }));
  };

  const uploadDocuments = () => {
    const uploadDocOneByOne = async (file) => {
      if (file.fileObject) {
        setIsLoading(true);
        try {
          // IF HAVING PROBLEM HERE REMOVE WITHCREDENTIALS
          const getUrl = await axios2.post(
            `${baseUrl}/api/v1/auth/generate-upload-url`,
            {
              bucketName: "connect-dev-test",
              key: file.key,
              expDate: 15,
              contentType: file.fileObject.type,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          const { url } = getUrl.data.data;

          const uploadFile = await axios.put(url, file.fileObject, {
            headers: {
              "Content-Type": file.fileObject.type,
            },
          });

          if (uploadFile.status === 200) {
            const uploadFileData = await axios2.post(
              `${baseUrl}/api/v1/auth/list-uploaded-document`,
              {
                bucketName: "connect-dev-test",
                key: file.key,
                contentType: file.fileObject.type,
                documentType: file.documentType,
                documentDescription: file.documentDescription,
                documentSize: file.fileObject.size,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              }
            );

            if (file.documentType === "Pan") {
              setPancard({
                ...pancard,
                recievedUploadedDocId: uploadFileData.data.data.document.id,
              });
            }

            if (file.documentType === "Aadhar") {
              setAdharcard({
                ...adharcard,
                recievedUploadedDocId: uploadFileData.data.data.document.id,
              });
            }

            if (file.documentType === "Profile") {
              setProfilePic({
                ...profilePic,
                recievedUploadedDocId: uploadFileData.data.data.document.id,
              });
            }
          }
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
            console.log(error);
          } else {
            console.log(error);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    const documents = [profilePic, adharcard, pancard];
    // const docUrlRecievedFromDBList = [
    //   profilePic.docUrlRecievedFromDB,
    //   adharcard.docUrlRecievedFromDB,
    //   pancard.docUrlRecievedFromDB,
    // ];
    // !docUrlRecievedFromDBList.includes(null)
    console.log(
      profilePic, adharcard, pancard
    )
    if (
      profilePic.docUrlRecievedFromDB &&
      adharcard.docUrlRecievedFromDB &&
      pancard.docUrlRecievedFromDB
    ) {
      uploadFullData();
    } else {
      documents.forEach((doc) => uploadDocOneByOne(doc));
    }
  };

  const uploadFullData = async () => {
    console.log(personalDetails, 'personalDetails')
    try {
      setIsLoading(true);
      const uploadFullData = await axios2.post(
        `${baseUrl}/api/v1/profile/personal`,
        {
          ...personalDetails,
          sectorId: sectorId,
          userId: userId,
          socialMediaProfile: socialMediaProfile,
          aadharNumberId:
            adharcard.recievedUploadedDocId,
          panNumberId:
            pancard.recievedUploadedDocId,
          profilePictureId:
            profilePic.recievedUploadedDocId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (uploadFullData.status === 200) {
        setEdit(false);
        setDiscard(null);
        setEmptyValue(null);
        setError({
          mobileNumber: null,
          emailAddress: null,
          bio: null,
        });
        setIsLoading(false);
        getData();
      }
      // }
      toast.success('Successfully saved');
      setEmptyValue(null);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const retriveDocuments = async (docObject) => {
    if (docObject.docId) {
      try {
        const response = await axios2.post(
          `${baseUrl}/api/v1/auth/document-retrival`,
          {
            documentId: docObject.docId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          const value = response.data.data.url;
          if (docObject.docName === "aadharcard") {
            setAdharcard((prev) => ({
              ...prev,
              recievedUploadedDocId: docObject.docId,
              docUrlRecievedFromDB: value,
            }));

            setRecievedDocUrls((prev) => ({
              ...prev,
              aadharCard: value,
            }));
          }
          if (docObject.docName === "pancard") {
            setPancard((prev) => ({
              ...prev,
              recievedUploadedDocId: docObject.docId,
              docUrlRecievedFromDB: value,
            }));
            setRecievedDocUrls((prev) => ({
              ...prev,
              panCard: value,
            }));
          }
          if (docObject.docName === "profilePic") {
            setProfilePic((prev) => ({
              ...prev,
              recievedUploadedDocId: docObject.docId,
              docUrlRecievedFromDB: value,
            }));
            setRecievedDocUrls((prev) => ({
              ...prev,
              profilePic: value,
            }));
          }
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
          console.log(error);
        } else {
          console.log(error);
        }
      }
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/profile/your-personal-details`,
        {
          userId: userId,
          sectorId: sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, "response");
      if (response.status === 200) {
        console.log(response, "response");
        const personalDetailsRecieved = response?.data?.data?.personalDetails;

        setRecievedFile(() => personalDetailsRecieved);

        const documentsNeedsToBeRetrived = [
          {
            docName: "aadharcard",
            docId: personalDetailsRecieved?.aadharNumberUploadId,
          },
          {
            docName: "pancard",
            docId: personalDetailsRecieved?.panNumberUploadId,
          },
          {
            docName: "profilePic",
            docId: personalDetailsRecieved?.profilePictureUploadId,
          },
        ];

        documentsNeedsToBeRetrived.forEach((docObject) =>
          retriveDocuments(docObject)
        );
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      console.log("setIsLoading(false)");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("inside useeffect");
    console.log("inside useeffect");
    const errorValues = Object.values(error);
    const validationValue = errorValues.map((value) => value === null);
    if (
      adharcard.recievedUploadedDocId &&
      pancard.recievedUploadedDocId &&
      profilePic.recievedUploadedDocId &&
      // profilePic.docUrlRecievedFromDB &&
      // adharcard.docUrlRecievedFromDB &&
      // pancard.docUrlRecievedFromDB &&
      emptyValue === false &&
      !validationValue.includes(false)
    ) {
      uploadFullData();
    }
  }, [adharcard, pancard, profilePic, emptyValue]);

  useEffect(() => {
    // console.log("discard value changed");
    if (discard) {
      // console.log("discard value true");
      // setPersonalDetails({
      //   fullName: recievedFile?.fullName,
      //   dob: recievedFile?.dob,
      //   mobileNumber: recievedFile?.mobileNumber,
      //   emailAddress: recievedFile?.emailAddress,
      //   bio: recievedFile?.bio,
      //   permanentAddress: {
      //     addressLine1: recievedFile?.permanentAddress.addressLine1,
      //     addressLine2: recievedFile?.permanentAddress.addressLine2,
      //     city: recievedFile?.permanentAddress.city,
      //     state: recievedFile?.permanentAddress.state,
      //     pincode: recievedFile?.permanentAddress.pincode,
      //   },
      //   currentAddress: {
      //     addressLine1: recievedFile?.currentAddress.addressLine1,
      //     addressLine2: recievedFile?.currentAddress.addressLine2,
      //     city: recievedFile?.currentAddress.city,
      //     state: recievedFile?.currentAddress.state,
      //     pincode: recievedFile?.currentAddress.pincode,
      //   },
      // });
      getData();
      setAdharcard((prev) => ({
        ...prev,
        docUrlRecievedFromDB: recievedDocUrls.aadharCard,
        fileObject: null,
      }));

      setPancard((prev) => ({
        ...prev,
        docUrlRecievedFromDB: recievedDocUrls.panCard,
        fileObject: null,
      }));
    }

    console.log(recievedDocUrls.profilePic, " recievedDocUrls.profilePic");

    setProfilePic((prev) => ({
      ...prev,
      fileObject: null,
      fileLink: null,
      docUrlRecievedFromDB: recievedDocUrls.profilePic,
    }));

    setError({
      mobileNumber: null,
      emailAddress: null,
      bio: null,
      aadhar: null,
      pan: null,
      profilePic: null,
      "permanentAddress.city": null,
      "permanentAddress?.state": null,
      "permanentAddress?.pincode": null,
      "currentAddress.city": null,
      "currentAddress?.state": null,
      "currentAddress?.pincode": null,
    });
  }, [discard]);

  useEffect(() => {
    const errorValues = Object.values(error);
    const validationValue = errorValues.map((value) => value === null);
    console.log(validationValue, "validationValue");
    console.log(
      validationValue.includes(false),
      "validationValue.includes(false)"
    );
    if (emptyValue === false && !validationValue.includes(false)) {
      uploadDocuments();
    }
  }, [emptyValue, error]);

  useEffect(() => {
    const keys = Object.keys(personalDetails?.permanentAddress);
    const values = Object.values(personalDetails?.permanentAddress);

    const arr = keys.map((key) =>
      personalDetails?.permanentAddress[key] ===
        personalDetails?.currentAddress[key]
        ? true
        : false
    );

    arr.includes(false) || values.includes("")
      ? setSameAs(false)
      : setSameAs(true);
  }, [personalDetails]);

  useEffect(() => {
    if (recievedFile) {
      setPersonalDetails({
        fullName: recievedFile?.fullName,
        dob: recievedFile?.dob,
        mobileNumber: recievedFile?.mobileNumber,
        emailAddress: recievedFile?.emailAddress,
        bio: recievedFile?.bio,
        gender: recievedFile?.gender,
        preferredLanguage: recievedFile?.preferredLanguage,
        permanentAddress: {
          addressLine1: recievedFile?.permanentAddress?.addressLine1,
          addressLine2: recievedFile?.permanentAddress?.addressLine2,
          city: recievedFile?.permanentAddress?.city,
          state: recievedFile?.permanentAddress?.state,
          pincode: recievedFile?.permanentAddress?.pincode,
        },
        currentAddress: {
          addressLine1: recievedFile?.currentAddress?.addressLine1,
          addressLine2: recievedFile?.currentAddress?.addressLine2,
          city: recievedFile?.currentAddress?.city,
          state: recievedFile?.currentAddress?.state,
          pincode: recievedFile?.currentAddress?.pincode,
        },
      });
    }
    setSocialMediaProfile(recievedFile?.socialMediaProfile)
  }, [recievedFile]);

  useEffect(() => {
    getData();
    setPersonalDetails((prev) => ({ ...prev, mobileNumber: mobileNumber }));
    window.scrollTo(0, 0);
  }, []);

  console.log(adharcard, "adharcard");
  console.log(pancard, "pancard");
  console.log(edit, "edit");
  console.log(profilePic, "profilepic");
  console.log(personalDetails, "personalDetails");
  console.log(recievedFile, 'recievedFile');

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link
            to="../../mobileProfile/personalProfile"
            className={styles.backButton}
          >
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Personal Details</span>
          <span
            className={commonStyle.filter_icon_mobile}
            onClick={handleEditDiscard}
          >
            {edit ? `Discard` : ICONS.EditDataIcon}
          </span>
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={styles.edit_save_button_section}>
          <EditButton edit={edit} onClick={handleEditDiscard} />
          <SaveChangesButton
            formDetails={personalDetails}
            edit={edit}
            setEmptyValue={setEmptyValue}
          />
        </div>

        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Personal Details</p>
          <p className={styles.mandatory_data}>
            (* Indicates required question)
          </p>
        </div>
        <div className={styles.Main_Container}>
          <p
            className={`${styles.Profile_heading_onMobile} ${styles.hideOnDesktop}`}
          >
            Profile Picture
          </p>
          <div className={styles.profile_personal_details}>
            <img
              src={
                profilePic.fileLink
                  ? profilePic.fileLink
                  : profilePic.docUrlRecievedFromDB
                    ? profilePic.docUrlRecievedFromDB
                    : profilePic.emptyProfilePic
              }
              alt="Profile"
              className={styles.profile_img}
            />
            <div className={styles.camera_outer_dot}>
              <input
                disabled={edit ? false : true}
                type="file"
                accept="image/jpeg,image/jpg"
                id="imageUpload"
                className={styles.file_input}
                onChange={handleProfilePic}
              />
              <label htmlFor="imageUpload">
                <img
                  src="/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/CameraIcon.svg"
                  alt="Camera Icon"
                  className={styles.cameraIcon}
                />
              </label>
            </div>
            <div className={`${styles.Profile_data} ${styles.hideOnMobile}`}>
              <p className={styles.name}>{recievedFile?.fullName}</p>
              <p className={styles.label}>{recievedFile?.emailAddress}</p>
              {error.profilePic && (
                <p className={styles.error}>{error.profilePic}</p>
              )}
              {emptyValue === false &&
                !profilePic.docUrlRecievedFromDB &&
                !profilePic.fileObject && (
                  <p className={styles.error}>required*</p>
                )}
            </div>
          </div>
        </div>
        <div className={styles.heading}>Personal Information</div>
        <div className={styles.Main_Container}>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Full name <span className={styles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={!edit}
                emptyValue={emptyValue}
                value={personalDetails?.fullName}
                onChange={(e) =>
                  setPersonalDetails({
                    ...personalDetails,
                    fullName: e.target.value,
                  })
                }
                placeholder="Saikumar"
              />
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Date of birth <span className={styles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={!edit}
                value={personalDetails?.dob}
                emptyValue={emptyValue}
                onChange={(e) =>
                  setPersonalDetails({
                    ...personalDetails,
                    dob: e.target.value,
                  })
                }
                type="date"
                placeholder="05/05/2000"
              />
            </div>
          </div>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Mobile Number <span className={styles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={true}
                emptyValue={emptyValue}
                value={personalDetails?.mobileNumber}
                onChange={(e) => handleChange(e)}
                type="number"
                name="mobileNumber"
                placeholder="1234 567 890"
              />
              {error.mobileNumber && (
                <p className={styles.error}>{error.mobileNumber}</p>
              )}
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Email address <span className={styles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={!edit}
                emptyValue={emptyValue}
                value={personalDetails?.emailAddress}
                onChange={(e) => handleChange(e)}
                type="email"
                name="emailAddress"
                placeholder="Anymail@gmail.com"
              />
              {error.emailAddress && (
                <p className={styles.error}>{error.emailAddress}</p>
              )}
            </div>
          </div>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Gender <span className={styles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={edit}
                emptyValue={emptyValue}
                optionSelected={personalDetails.gender}
                setOptionSelected={setPersonalDetails}
                serviceToBeAdded={personalDetails}
                keyName="gender"
                options={['Male', 'Female', 'Other']}
                //placeholder="Services Offered"
                selectType="single"
              />
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Preferred Languages<span className={styles.star_mark}>*</span>
              </label>

              <Inputbox
                readOnly={!edit}
                value={personalDetails?.preferredLanguage}
                emptyValue={emptyValue}
                onChange={(e) =>
                  setPersonalDetails({
                    ...personalDetails,
                    preferredLanguage: e.target.value,
                  })
                }
                type="string"
                placeholder="Hindi, English, Marathi, Tamil"
              />
            </div>
          </div>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Social Media Profiles (if any)
              </label>

              <Inputbox
                readOnly={!edit}
                value={socialMediaProfile}
                emptyValue={false}
                onChange={(e) =>
                  setSocialMediaProfile(e.target.value)
                }
                type="string"
                placeholder="insta: mann123, fb: manan_singh"
              />
            </div>
          </div>
          <div className={styles.main_container_form}>
            <div className={styles.form_data_Bio}>
              <label className={styles.form_label}>
                Bio <span className={styles.star_mark}>*</span>
              </label>
              <textarea
                value={personalDetails?.bio}
                maxLength={300}
                name="bio"
                onChange={(e) => handleChange(e)}
                disabled={edit ? false : true}
                style={{
                  color: !edit
                    ? "var(--grey)"
                    : "var(--black)",
                  backgroundColor: !edit
                    ? "var(--light-grey)"
                    : "var(--lavender-blue)",
                }}
                placeholder="Expert in planning, executing, and closing projects efficiently.Committed to maintaining high standards of quality and compliance with industry regulations."
              ></textarea>
              {emptyValue && personalDetails?.bio === "" && (
                <p className={styles.error}>* required</p>
              )}
              {error.bio && <p className={styles.error}>{error.bio}</p>}
            </div>
          </div>
        </div>

        <div className={styles.heading}>Address</div>
        <div className={styles.Main_Container}>
          <div className={styles.main_container_form}>
            <div className={`${styles.form_data} ${styles.form_data_one}`}>
              <label
                className={`${styles.form_label} ${styles.permanent_label}`}
              >
                Permanent address <span className={styles.star_mark}>*</span>
              </label>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Address line 1 <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit}
                  type="text"
                  name='permanentAddress.addressLine1'
                  placeholder="address line 1"
                  emptyValue={emptyValue}
                  value={personalDetails?.permanentAddress?.addressLine1}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Address line 2 <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit}
                  type="text"
                  name='permanentAddress.addressLine2'
                  placeholder="address line 2"
                  emptyValue={emptyValue}
                  value={personalDetails?.permanentAddress?.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  City <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit}
                  type="text"
                  placeholder="city"
                  name="permanentAddress.city"
                  emptyValue={emptyValue}
                  value={personalDetails?.permanentAddress?.city}
                  onChange={handleChange}
                />
                {error["permanentAddress.city"] && (
                  <p className={styles.error}>
                    {error["permanentAddress.city"]}
                  </p>
                )}
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  State <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit}
                  type="text"
                  placeholder="state"
                  emptyValue={emptyValue}
                  name="permanentAddress.state"
                  value={personalDetails?.permanentAddress?.state}
                  onChange={handleChange}
                />
                {error["permanentAddress.state"] && (
                  <p className={styles.error}>
                    {error["permanentAddress.state"]}
                  </p>
                )}
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Pin code <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit}
                  type="number"
                  placeholder="pincode"
                  emptyValue={emptyValue}
                  name="permanentAddress.pincode"
                  value={personalDetails?.permanentAddress?.pincode}
                  onChange={handleChange}
                />
                {error["permanentAddress.pincode"] && (
                  <p className={styles.error}>
                    {error["permanentAddress.pincode"]}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Current address <span className={styles.star_mark}>*</span>
              </label>
              <label className={styles.checkbox_container}>
                <input
                  type="checkbox"
                  checked={sameAs ? true : false}
                  onChange={handleSameAs}
                  disabled={!edit}
                />
                <span className={styles.checkbox_data}>
                  Same as Permanent address
                </span>
              </label>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Address line 1 <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit || sameAs}
                  type="text"
                  name='currentAddress.addressLine1'
                  placeholder="address line 1"
                  emptyValue={emptyValue}
                  value={personalDetails?.currentAddress?.addressLine1}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Address line 2 <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit || sameAs}
                  type="text"
                  name='currentAddress.addressLine2'
                  placeholder="address line 2"
                  emptyValue={emptyValue}
                  value={personalDetails?.currentAddress?.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  City <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit || sameAs}
                  type="text"
                  placeholder="city"
                  name="currentAddress.city"
                  emptyValue={emptyValue}
                  value={personalDetails?.currentAddress?.city}
                  onChange={handleChange}
                />
                {error["currentAddress.city"] && (
                  <p className={styles.error}>{error["currentAddress.city"]}</p>
                )}
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  State <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit || sameAs}
                  type="text"
                  placeholder="state"
                  name="currentAddress.state"
                  emptyValue={emptyValue}
                  value={personalDetails?.currentAddress?.state}
                  onChange={handleChange}
                />
                {error["currentAddress.state"] && (
                  <p className={styles.error}>
                    {error["currentAddress.state"]}
                  </p>
                )}
              </div>
              <div className={styles.form_sub_data}>
                <label className={styles.form_label}>
                  Pin code <span className={styles.star_mark}>*</span>
                </label>
                <Inputbox
                  readOnly={!edit || sameAs}
                  type="number"
                  placeholder="pincode"
                  name="currentAddress.pincode"
                  emptyValue={emptyValue}
                  value={personalDetails?.currentAddress?.pincode}
                  onChange={handleChange}
                />
                {error["currentAddress.pincode"] && (
                  <p className={styles.error}>
                    {error["currentAddress.pincode"]}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.heading}>Other Important Details</div>
        <div className={styles.Main_Container}>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Aadhar card <span className={styles.star_mark}>*</span>
                {adharcard.docUrlRecievedFromDB && (
                  <a
                    target="_blank"
                    href={adharcard.docUrlRecievedFromDB}
                    className={styles.received_doc_url}
                  >
                    view
                  </a>
                )}
              </label>
              <UploadFile
                file={adharcard}
                handleFileUpload={(value) => handleAdharCard(value)}
                setFile={setAdharcard}
                setEmptyValue={setEmptyValue}
                inputId="adharcard"
                disabledValue={!edit}
                acceptedType=".pdf"
              />

              {error.aadhar ? (
                <p className={styles.error}>{error.aadhar}</p>
              ) : (
                edit &&
                adharcard.fileObject === null &&
                !adharcard.docUrlRecievedFromDB && (
                  <p className={styles.error}>* required</p>
                )
              )}
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                PAN card <span className={styles.star_mark}>*</span>
                {pancard.docUrlRecievedFromDB && (
                  <a
                    target="_blank"
                    href={pancard.docUrlRecievedFromDB}
                    className={styles.received_doc_url}
                  >
                    view
                  </a>
                )}
              </label>
              <UploadFile
                file={pancard}
                handleFileUpload={(value) => handlePanCard(value)}
                setEmptyValue={setEmptyValue}
                setFile={setPancard}
                inputId="pancard"
                disabledValue={!edit}
                acceptedType=".pdf"
              />

              {error.pan ? (
                <p className={styles.error}>{error.pan}</p>
              ) : (
                edit &&
                pancard.fileObject === null &&
                !pancard.docUrlRecievedFromDB && (
                  <p className={styles.error}>* required</p>
                )
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.footer_data} ${styles.hideOnMobile}`}>
          <Link to="../educational">
            <Button
              padding="10px 18px"
              text="Next >"
              backgroundColor="var(--yellow)"
              color="var(--smoky-black)"
              disabled={edit}
            />
          </Link>
        </div>
      </div>
      <div className={`${styles.mobileView_footerBtn} ${styles.hideOnDesktop}`}>
        <SaveChangesButton
          width="100%"
          formDetails={personalDetails}
          edit={edit}
          setEmptyValue={setEmptyValue}
        />
      </div>
    </>
  );
};
export default PersonalDetails;
