import styles from "./EmptyStateComponent.module.css";

const EmptyStateComponent = ({ stateType, heading, subHeading }) => {
    const imageSources = {
        emptyService:
            "/Assets/ServiceProviderDashboard/EmptyStateAndErrorLogos/emptyServiceStateLogo.svg",
        emptyJobRequest:
            "/Assets/ServiceProviderDashboard/EmptyStateAndErrorLogos/emptyJobRequestLogo.svg",
        emptyConstructionState:
            "/Assets/ServiceProviderDashboard/EmptyStateAndErrorLogos/constructionEmptyStateLogo.svg",
    };

    return (
        <div className={styles.emptyStateComponent}>
            <img src={imageSources[stateType]} alt="" />
            <p className={styles.emptyStateHeading}>{heading}</p>
            <p className={styles.emptyStateSubHeading}>{subHeading}</p>
        </div>
    );
};

export default EmptyStateComponent;
