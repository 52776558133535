import CustomerRatingCard from "../../Components/CustomerRatingCard/CustomerRatingCard";
import Styles from "../MoreReviews/MoreReviews.module.css";
import commonStyles from "../../../Profile_Settings/Styles/ProfileCommonCss.module.css";

const MoreReviews = () => {
  const customerData = [
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating: 4,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating: 4.5,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating: 3.5,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
    {
      name: "Satish",
      date: "Aug 12, 2024",
      cusromerTitle: "Switched from Urban Company to Connect",
      rating: 3,
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cursus eros integer quis scelerisque eu. Augue ultrices eget morbi aliquam. At imperdiet diam porttitor morbi non ut bibendum diam. Diam mi et cras se",
    },
  ];
  return (
    <div className={commonStyles.Profile_Container}>
      <div className={Styles.heading}>Ratings & Reviews</div>
      <div className={Styles.more_review_container}>
        {customerData.map(
          ({ name, date, cusromerTitle, rating, description }, index) => (
            <div key={index}>
              <CustomerRatingCard
                name={name}
                date={date}
                cusromerTitle={cusromerTitle}
                rating={rating}
                description={description}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MoreReviews;
