import styles from "../../RejectMessage/RejectMessage.module.css";
import Button from "../../../../../../Components/Button/Button";
import OtpPopupstyles from "./askForOTP.module.css";
import { ICONS } from "../../../../../../../Assets/Icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import axios2 from "../../../../../../../axios2";

const VerifiedOtpPopup = ({
  setScreen,
  setWorkInProgress,
  workInProgress,
  orderItemBookingId,
  onClose,
  useCase,
  userId,
  startResendTimer,
  setStartResendTimer,
}) => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });

  const accessToken = localStorage.getItem('accessToken');

  const [invalidOtp, setInvalidOtp] = useState(false);
  const [missingOtp, setMissingOtp] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleOtp = async (e) => {
    e.preventDefault();

    const allFilled = Object.values(otp).every((value) => value !== "");

    if (allFilled) {
      try {
        let verificationCode = Object.values(otp).join("");
        let url = `${baseUrl}/api/v1/auth/verification/verify`;
        const res = await axios2.post(url, {
          userId,
          useCase,
          verificationCode
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        console.log("OTP Verified :", res);
        setInvalidOtp(false);
        setWorkInProgress([...workInProgress, orderItemBookingId]);
        setScreen("otpSuccessCard");
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
          console.log(error);
        } else {
          console.log(error);
        }
        setInvalidOtp(true);
        setMissingOtp(false);
      }
    } else {
      setMissingOtp(true);
      setInvalidOtp(false);
    }
  };

  const handleChange = (name, event) => {
    const value = event.target.value;
    if (value.length <= 1) {
      setOtp({ ...otp, [name]: value });
    }
  };

  const inputFocus = (elmnt) => {
    // console.log(elmnt, "elmnt");
    if (elmnt.key === "Backspace") {
      const prev = elmnt.target.tabIndex - 2;
      if (prev > -1) {
        elmnt.target.form.elements[prev].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  const resendOtpHandler = async (useCase) => {
    try {
      setStartResendTimer(() => true);
      let url = `${baseUrl}/api/v1/auth/verification/generate`;
      const res = await axios2.post(url, {
        userId,
        userType: "Service Provider",
        useCase,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      console.log("OTP Re Generated :");
      console.log(res.data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const [secondsLeft, setSecondsLeft] = useState(59);

  // const resendTimer = () => {
  //   const timer = setInterval(() => {
  //     setSecondsLeft(() => secondsLeft - 1)
  //   }, 1000)
  // }

  useEffect(() => {
    if (startResendTimer === true) {
      let count = 60;
      var timer = setInterval(() => {
        count = count - 1;
        setSecondsLeft(() => count);
        if (count === 0) {
          setStartResendTimer(false);
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [startResendTimer]);

  // useEffect(() => {
  //   setStartResendTimer(() => true)
  //   console.log('jjjjjjjjjjjjjj')
  // }, [])

  console.log(startResendTimer, "startResendTimer");

  return (
    <>
      <form>
        <div className={styles.popup}>
          <div className={styles.popup_header}>
            <span>Enter the OTP</span>
            <span onClick={() => onClose()}>{ICONS.closeWhiteIcon}</span>
          </div>
          <div className={OtpPopupstyles.VerifiedOtpPopup_body}>
            <div className={OtpPopupstyles.VerifiedOtpPopup_details}>
              <span>{ICONS.infoIcon}</span>
              <p className={OtpPopupstyles.VerifiedOtpPopup_value}>
                An OTP has been sent to your registered mobile number ending
                with
                <span className={OtpPopupstyles.phone_no}> *********90. </span>
                Please check your messages.
              </p>
            </div>
            <div className={OtpPopupstyles.Otp_input_data}>
              <p className={OtpPopupstyles.Otp_input_text}>Enter the OTP</p>

              <div className={OtpPopupstyles.otpContainer}>
                {[1, 2, 3, 4].map((i) => (
                  <input
                    key={i}
                    name={`otp${i}`}
                    type="text"
                    placeholder="_"
                    autoComplete="off"
                    className={OtpPopupstyles.otpInput}
                    value={otp[`otp${i}`]}
                    onChange={(e) => handleChange(`otp${i}`, e)}
                    tabIndex={i}
                    maxLength="1"
                    onKeyUp={inputFocus}
                  />
                ))}
              </div>
              {invalidOtp && (
                <p className={OtpPopupstyles.invalidMsg}>OTP is invalid !</p>
              )}
              {missingOtp && (
                <p className={OtpPopupstyles.invalidMsg}>
                  Please enter all 4 digits !
                </p>
              )}
            </div>
            <p className={OtpPopupstyles.resend}>
              Didn't receive a code?{" "}
              <span
                style={{
                  color: !startResendTimer ? "var(--blue)" : "var(--grey)",
                }}
                onClick={startResendTimer ? null : () => resendOtpHandler(useCase)}
              >
                {`Send again `}
              </span>
              {startResendTimer && (
                <span>
                  in 00:{secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}
                </span>
              )}
            </p>
            <hr className={OtpPopupstyles.line} />
            <div className={OtpPopupstyles.footer_btn}>
              <Button
                onClick={(e) => handleClose(e)}
                padding="10px 18px"
                text="Go back"
                backgroundColor="var(--white)"
                color="var(--smoky-black)"
                borderColor="var(--grey-80)"
              />
              <Button
                type="submit"
                onClick={(e) => handleOtp(e)}
                padding="10px 18px"
                text="Verify OTP"
                backgroundColor="var(--yellow)"
                color="var(--smoky-black)"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default VerifiedOtpPopup;
