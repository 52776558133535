import styles from "./Loader.module.css";

const Loader = ({ isLoading }) => {

    if (!isLoading) {
        return null;
    }

    return (
        <div className={styles.loader_parent}>
            <div className={styles.loader}>
                <img
                    className={styles.loaderImg}
                    src="/Assets/ServiceProviderDashboard/Loader 1.svg"
                    alt="loader"
                />
                <p className={styles.heading}>Loading!</p>
                <p className={styles.subHeading}>
                    Your page is loading. Please wait for a few seconds.
                </p>
            </div>
        </div>
    );
};

export default Loader;
