import { Link } from "react-router-dom";

// import styles from "../Components/ThisYearDropdownList.module.css";
import commonStyle from "../../../ServiceProviderDashboard.module.css";
import mobile_profile_style from "../../styles/MobileProfileStyle.module.css";
import profile_styles from "../../Sections/Profile/Profile.module.css";
import { ICONS } from "../../../../Assets/Icons";
import Button from "../../../Components/Button/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import axios2 from "../../../../axios2";

const Profile = () => {
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const userType = stateData.userType;

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  const mainBaseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_MAIN_DEV
      : process.env.REACT_APP_MAIN_PROD;

  // login states
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const sectorId = stateData.currSectorId;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recievedFile, setRecievedFile] = useState(null);
  const [profilePic, setProfilePic] = useState(
    "/Assets/ServiceProviderDashboard/ProfileSetting/ProfileImages/emptyProfileImage.svg"
  );

  const handleDropdownclose = () => {
    setOpen(false);
  };

  const retriveDocuments = async (docId) => {
    if (docId) {
      try {
        const response = await axios2.post(
          `${baseUrl}/api/v1/auth/document-retrival`,
          {
            documentId: docId,
          }
        );

        if (response.status === 200) {
          const value = response.data.data.url;
          setProfilePic(() => value);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
          console.log(error);
        } else {
          console.log(error);
        }
      }
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      if (userType === "Business") {
        const response = await axios2.post(
          `${baseUrl}/api/v1/profile/your-business-details`,
          {
            userId: userId,
            sectorId: sectorId,
          }
        );

        if (response.status === 200) {
          const businessDetailsRecieved = response?.data?.data?.businessDetails;
          setRecievedFile({
            name: businessDetailsRecieved.companyName,
            emailAddress: businessDetailsRecieved.emailAddress,
          });

          retriveDocuments(businessDetailsRecieved?.profilePictureUploadId);
        }
      } else if (userType === "Individual") {
        const response = await axios2.post(
          `${baseUrl}/api/v1/profile/your-personal-details`,
          {
            userId: userId,
            sectorId: sectorId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          console.log(response, "response");
          const personalDetailsRecieved = response?.data?.data?.personalDetails;

          setRecievedFile({
            name: personalDetailsRecieved.fullName,
            emailAddress: personalDetailsRecieved.emailAddress,
          });

          retriveDocuments(personalDetailsRecieved?.profilePictureUploadId);
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logoutHandler = async () => {
    try {
      const res = await axios2.post(
        `${baseUrl}/api/v1/auth/logout`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("User logged out successfully :", res);

      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");

      handleDropdownclose()
      window.location.href = `${mainBaseUrl}/auth/login`;
    } catch (error) {
      console.log(`Error logging out :`, error);
      alert("Unable to Logout right now");
      handleDropdownclose()
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={mobile_profile_style.parent}>
        <Loader isLoading={isLoading} />
        <div className={commonStyle.mobileNavbar}>Profile</div>
        <Link to="../personalProfile">
          <div className={profile_styles.main_Container}>
            <div className={profile_styles.profile_setting_details}>
              <img
                src={profilePic}
                alt="Profile"
                className={profile_styles.profile_img}
              />
              <div className={profile_styles.Profile_data}>
                <p className={profile_styles.name}>{recievedFile?.name}</p>
                <p className={profile_styles.label}>
                  {recievedFile?.emailAddress}
                </p>
              </div>
            </div>
            <div className={profile_styles.profile_icon}>
              {ICONS.ProfileGreaterIcon}
            </div>
          </div>
        </Link>
        {/* <Link to="../../settings/notification">
          <div className={profile_styles.main_Container}>
            <div className={profile_styles.notification_setting}>
              {ICONS.editNotificationIcon}
              <span className={profile_styles.profile_heading}>
                Notification Settings
              </span>
            </div>
          </div>
        </Link> */}
        {/* <Link to="../../settings/invoiceHistory">
          <div className={profile_styles.main_Container}>
            <div className={profile_styles.notification_setting}>
              {ICONS.invoiceIcon}
              <span className={profile_styles.profile_heading}>Invoice</span>
            </div>
          </div>
        </Link> */}
        <Link to="../../support">
          <div className={profile_styles.main_Container}>
            <div className={profile_styles.notification_setting}>
              {ICONS.ContactSupportIcon}
              <span className={profile_styles.profile_heading}>
                Help & Support
              </span>
            </div>
          </div>
        </Link>
        <Link>
          <div className={profile_styles.main_Container}>
            <div className={profile_styles.notification_setting}>
              {ICONS.logoutIcon}
              <span
                className={profile_styles.profile_Logout}
                onClick={() => setOpen(true)}
              >
                Log out
              </span>
            </div>
          </div>
        </Link>

        <div
          className={commonStyle.DropdownList_parent}
          style={{ display: open ? "block" : "none" }}
          onClick={() => handleDropdownclose()}
        >
          <div className={commonStyle.DropdownList_Container}>
            <div className={commonStyle.logout_container}>
              <div className={commonStyle.logout_popup}>
                <div className={commonStyle.logout_heading}>
                  Logout
                  <p className={commonStyle.logout_text}>
                    Are you sure you want to log out?
                  </p>
                </div>
              </div>
              <div className={commonStyle.logout_popup}>
                <div className={commonStyle.footer_logout_btn}>
                  <Button
                    width="100%"
                    borderColor="var(--grey-80)"
                    padding="14px 18px"
                    text="Cancel"
                    backgroundColor="var(--white)"
                    color="var(--smoky-black)"
                    onClick={() => handleDropdownclose()}
                  />
                  <Button
                    width="100%"
                    padding="14px 18px"
                    text="Log Out"
                    backgroundColor="var(--red)"
                    color="var(--white)"
                    onClick={logoutHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
