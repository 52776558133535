import styles from "../../Styles/styles.module.css";

import { ICONS } from "../../../../Assets/Icons";

import NotifiactionToggle from "../../../Profile_Settings/Components/NotificationToggle/NotificationToggle";
import SearchSection from "../../Components/SearchSection/SearchSection";
import { useEffect, useState } from "react";
import Modal from "../../../../utils/modal/Modal";
import DeleteComponent from "../../Components/DeleteComponent/DeleteComponent";
import Button from "../../../Components/Button/Button";
import Loader from "../../../Components/Loader/Loader";
import axios from "axios";
import EmptyStateComponent from "../../../Components/EmptyStateComponent/EmptyStateComponent";
import axios2 from "../../../../axios2";
import { toast } from "react-toastify";

const InActive = ({ show }) => {

    const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const stateStored = localStorage.getItem("state");
    const state = JSON.parse(stateStored);

    // const mobileNumber = useSelector((state => state.auth.userMobileNumber));
    // const sectorId = useSelector((state => state.auth.currSectorId));

    const [isLoading, setIsLoading] = useState(false);
    const [activeServiceList, setActiveServiceList] = useState(null);
    const [active, setActive] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [serviceToBeDeletedId, setServiceToBeDeletedId] = useState(null);
    const [sectorName, setSectorName] = useState(null);

    const deleteServiceFunction = (serviceId) => {
        setServiceToBeDeletedId(serviceId);
        setShowDeleteModal(true);
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await axios2.post(
                `${baseUrl}/api/v1/order-management/service-provider/service-management/get?isActive=false`, { userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
            );
            console.log(response, "response");
            if (response.status === 200) {
                setActiveServiceList(response.data.data.providedServices);
            } else {
                setActiveServiceList([]);
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
                console.log(error);
            } else {
                console.log(error)
            }
        } finally {
            // setShowModal(true);
            setIsLoading(false);
        }
    };

    const deleteService = async (serviceId) => {
        console.log(serviceId, "serviceId");
        try {
            setIsLoading(true);
            const response = await axios2.delete(
                `${baseUrl}/api/v1/order-management/service-provider/service-management`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                    data: { id: serviceId },
                }
            );
            if (response.status === 204) {
                getData();

                toast.error('Order deleted')
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
                console.log(error);
            } else {
                console.log(error)
            }
        } finally {
            setIsLoading(false);
            setShowDeleteModal(false);

        }
    };

    const updateIsActive = async (serviceId) => {
        try {
            setIsLoading(true);
            const response = await axios2.post(
                `${baseUrl}/api/v1/order-management/service-provider/service-management/add-or-update`,
                {
                    id: serviceId,
                    isActive: true,
                    userId,
                }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
            );

            if (response.status === 201) {
                getData();
                toast.success('Service activated')
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
                console.log(error);
            } else {
                console.log(error)
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSectorName = async () => {
        try {
            const response = await axios2.post(
                `${baseUrl}/api/v1/sectors/me`,
                {
                    sectorId: state?.userDetails?.sectorId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            console.log(response.data.data.sector.sectorName, "fetchSectorName");
            setSectorName(
                () =>
                    response.data.data.sector.sectorName.charAt(0).toUpperCase() +
                    response.data.data.sector.sectorName.slice(1).toLowerCase()
            );
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
                console.log(error);
            } else {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        fetchSectorName();
    }, [state]);

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [show]);

    return (
        <div className={styles.active_page}>
            <Loader isLoading={isLoading} />
            {activeServiceList?.length === 0 && (

                <EmptyStateComponent
                    stateType="emptyService"
                    heading="No Services Added!"
                    subHeading="Please add services that you would like to provide to your customers and those will be shown here"
                />

            )}
            {/* <SearchSection /> */}
            <h1 className={styles.section_heading}>{sectorName} Services</h1>
            <table className={styles.service_table}>
                <thead>
                    <tr>
                        <th>Description</th>
                        <th></th>
                        <th></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        activeServiceList?.map((service, index) => (
                            <tr key={index}>
                                <td>
                                    <p><span className={styles.label}>Sector:</span> {sectorName}</p>
                                    <p><span className={styles.label}>Total bookings recieved:</span> {service.
                                        totalOrderCount
                                    }</p>
                                </td>
                                <td>
                                    <p><span className={styles.label}>Sub-category:</span> {service.subCategory.subCategoryName}</p>
                                    <p>
                                        <span className={styles.label}>Price charged:</span> ₹ {service.price} {service.per}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <span className={styles.label}> Start date:</span>{" "}
                                        {new Date(service?.createdAt)
                                            .toLocaleDateString("en-US")
                                            .replace(/\//g, "-")}
                                    </p>
                                </td>
                                <td className={styles.action_section}>
                                    <div className={styles.notificationToggle}>
                                        <span style={{ color: service.active ? "green" : "red" }}>
                                            Deactive
                                        </span>
                                        <NotifiactionToggle
                                            active={service.isActive}
                                            onClick={() => updateIsActive(service.id)}
                                        />
                                    </div>
                                    <div
                                        className={`${styles.action_button} ${styles.delete_button}`}
                                        onClick={() => deleteServiceFunction(service.id)}
                                    >
                                        {ICONS.deleteIconRed}
                                    </div>
                                </td>
                            </tr>
                        )
                        )}
                    <tr></tr>
                </tbody>
            </table>
            {
                activeServiceList?.map((service, index) => (
                    <div key={service.id} className={styles.service_card}>
                        <div className={styles.service_card_top_container}>
                            <div>
                                <p className={styles.service_name}>
                                    {service.subCategory.subCategoryName}
                                </p>
                                <p className={styles.sector_name}>{"Construction"}</p>
                            </div>
                            <span className={styles.active}>
                                <span style={{ color: "var(--red)" }}>Deactive</span>{" "}
                                <NotifiactionToggle
                                    active={service.isActive}
                                    onClick={() => updateIsActive(service.id)}
                                />
                            </span>
                        </div>
                        <div className={styles.service_card_middle_container}>
                            <div className={styles.text}>
                                <span className={styles.text_label}>Start Date:</span>{" "}
                                <span>
                                    {" "}
                                    {new Date(service?.createdAt)
                                        .toLocaleDateString("en-US")
                                        .replace(/\//g, "-")}
                                </span>
                            </div>
                            <div className={styles.text}>
                                <span className={styles.text_label}>
                                    Total bookings recieved:
                                </span>{" "}
                                <span>{service.
                                    totalOrderCount
                                }</span>
                            </div>
                            <div className={styles.text}>
                                <span className={styles.text_label}>Price charged:</span>{" "}
                                <span>
                                    ₹ {service.price} {service.per}
                                </span>
                            </div>
                        </div>
                        <div className={styles.service_card_bottom_container}>
                            <Button
                                onClick={() => deleteServiceFunction(service.id)}
                                padding="10px 9px"
                                text="Delete"
                                backgroundColor="var(--white)"
                                width="100%"
                                color="var(--red)"
                                borderColor="var(--red)"
                            />
                        </div>
                    </div>
                )
                )}
            <Modal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                children={
                    <DeleteComponent
                        setShow={setShowDeleteModal}
                        onClick={() => deleteService(serviceToBeDeletedId)}
                    />
                }
            />
        </div>
    );
};

export default InActive;
