// import "rsuite/dist/rsuite.min.css";

import Styles from "./filterByAmount.module.css";
import "../../../../../../utils/utilsCss/utilsCss.css";

import { useEffect, useState } from "react";
// import { RangeSlider } from "rsuite";
import { Slider } from "primereact/slider";

import { ICONS } from "../../../../../../Assets/Icons";
import Button from "../../../../../Components/Button/Button";

const FilterByAmount = ({
  onClose,
  mobileView,
  setAmount,
  amount,
  localMobileAmount,
  setLocalMobileAmount,
}) => {
  const [value, setValue] = useState(amount || localMobileAmount);
  const [error, setError] = useState("");

  const handleSlider = (value) => {
    let value1 = value[0];
    let value2 = value[1];

    if (value1 >= value2) {
      setError("try another value");
    } else if (value2 < value1) {
      setError("try another value");
    } else {
      setValue([Number(value1), Number(value2)]);
      setError("");
      if (mobileView) {
        setLocalMobileAmount([Number(value1), Number(value2)]);
      }
    }
  };

  const handleFromChange = (e) => {
    const input = e.target.value;
    let min = 0;
    let max = 10000;
    const [start, end] = value;
    if (input >= min && input <= max) {
      if (input <= end) {
        setValue([Number(input), end]);
        setError("");
      } else {
        setError("value trying to enter is greater than to value");
      }
    } else {
      setValue([min, end]);
    }
  };

  const handleToChange = (e) => {
    const input = e.target.value;
    let min = 0;
    let max = 10000;
    const [start, end] = value;
    if (input >= min && input <= max) {
      if (input >= start) {
        setValue([start, Number(input)]);
        setError("");
      } else {
        setError("value trying to enter is lesser than from value");
      }
    } else {
      setValue([start, max]);
    }
  };

  const handleApplyFilter = () => {
    setAmount(value);
    onClose();
  };

  const handleClear = () => {
    setValue([100, 10000]);
    setAmount([100, 10000]);
    onClose();
  };

  useEffect(() => {
    if (localMobileAmount) {
      setValue(() => localMobileAmount);
    }
  }, [localMobileAmount]);

  return (
    <div
      className={
        mobileView ? Styles.filterByAmountMobile : Styles.filterByAmount
      }
    >
      <div className={Styles.header}>
        <h1>Price Range</h1>
        {!mobileView && (
          <span onClick={() => onClose()}>{ICONS.closeIcon}</span>
        )}
      </div>
      <div className={Styles.input_section}>
        <label>
          From
          <span className={Styles.rupee1}>&#8377;</span>
          <input
            type="number"
            value={value[0]}
            id="from"
            onChange={(e) => handleFromChange(e)}
            className={Styles.input}
            min="0"
            max="9999"
          />
        </label>

        <label>
          To
          <span className={Styles.rupee2}>&#8377;</span>
          <input
            type="number"
            value={value[1]}
            id="to"
            onChange={(e) => handleToChange(e)}
            className={Styles.input}
            min="1"
            max="10000"
          />
        </label>
      </div>
      {error && <p>{error}</p>}

      <Slider
        value={value}
        onChange={(e) => handleSlider(e.value)}
        className={Styles.slider}
        min={100}
        max={10000}
        range
      />

      {!mobileView && <hr className={Styles.line}></hr>}

      {!mobileView && (
        <div className={Styles.footer}>
          <Button
            onClick={() => handleClear()}
            backgroundColor="var(--white)"
            borderColor="var(--grey-80)"
            color="var(--smoky-black)"
            text="Clear"
            width="100%"
            fontSize="1rem"
            padding="10px 18px"
          />
          <Button
            onClick={() => handleApplyFilter()}
            backgroundColor="var(--yellow)"
            color="var(--smoky-black)"
            text="Apply Filter"
            width="100%"
            fontSize="1rem"
            padding="10px 18px"
          />
        </div>
      )}
    </div>
  );
};

export default FilterByAmount;
