import styles from "../RejectMessage/RejectMessage.module.css";
import Button from "../../../../../Components/Button/Button";
import { ICONS } from "../../../../../../Assets/Icons";
import { useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import axios2 from "../../../../../../axios2";

const RejectionPopup = ({ setRenderRejected, fetchService, onClose, orderItemBookingId }) => {

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const accessToken = localStorage.getItem('accessToken');
  const [reason, setReason] = useState("");

  const rejectServiceJob = async () => {
    if (orderItemBookingId && reason !== "") {
      try {
        let url = `${baseUrl}/api/v1/order-management/service-provider/reject`;
        const res = await axios2.post(url, {
          orderItemBookingId,
          reason
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });

        console.log("Rejected Job Service :", res);

        fetchService();
        setRenderRejected((prev) => prev + 1);
        toast.error('order rejected')
        onClose();
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
          console.log(error);
        } else {
          console.log(error)
        }
      }
    }
  }

  return (
    <>
      <div className={styles.popup}>
        <div className={styles.popup_header}>
          <span>Reason for Rejection</span>
          <span onClick={() => onClose()}>{ICONS.closeWhiteIcon}</span>
        </div>
        <div className={styles.popup_body}>
          <textarea placeholder="Describe your reason here..." onChange={(e) => { setReason(e.target.value) }} ></textarea>
          <div className={styles.popup_buttons}>
            <Button
              onClick={() => onClose()}
              padding="10px 18px"
              text="Go back"
              backgroundColor="var(--white)"
              color="var(--smoky-black)"
              borderColor="var(--grey-80)"
            />
            <Button
              onClick={rejectServiceJob}
              padding="10px 18px"
              text="Reject"
              backgroundColor="var(--red)"
              color="var(--white)"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RejectionPopup;
