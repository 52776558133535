import { useEffect, useRef, useState } from "react";
import styles from "./UploadFile.module.css";
import { ICONS } from "../../../Assets/Icons";

const UploadFile = ({
    file,
    handleFileUpload,
    setFile,
    disabledValue,
    acceptedType,
    inputId,
    setEmptyValue,
}) => {
    const inputRef = useRef();
    const [fileSize, handleFileUploadSize] = useState("");

    const handleUrlCancel = () => {
        setFile((file) => ({
            ...file,
            recievedUploadedDocId: null,
            docUrlRecievedFromDB: null,
        }));
        setEmptyValue(() => null);
    };

    const handleFile = (e) => {
        if (e.target.files.length === 0) {
            handleFileUpload(null);
            setEmptyValue(() => null);
        } else {
            handleFileUpload(e.target.files[0]);
            setEmptyValue(() => null);
        }
    };

    const handleRoundCancel = () => {
        handleFileUpload(null);
        inputRef.current.value = null;
        setEmptyValue(() => null);
    };

    useEffect(() => {
        if (file.fileObject) {
            let actualSize = file.fileObject.size;
            let requiredSize = "";
            if (actualSize < 1024) {
                requiredSize = `${actualSize} Bytes`;
            } else if (actualSize >= 1024 && actualSize <= 1000000) {
                requiredSize = `${(actualSize / 1024).toFixed(2)} KB`;
            } else {
                requiredSize = `${(actualSize / (1024 * 1024)).toFixed(2)} MB`;
            }
            handleFileUploadSize(requiredSize);
        }
    }, [file.fileObject]);

    return (
        <div>
            <input
                type="file"
                id={inputId}
                onChange={handleFile}
                className={styles.file_input}
                disabled={disabledValue}
                accept={acceptedType}
                ref={inputRef}
                multiple
            />
            {file.fileObject ? (
                <div className={styles.value}>
                    <label htmlFor={inputId} className={styles.submit_file_upload_label}>
                        <button
                            onClick={handleRoundCancel}
                            className={styles.cancel_img}
                            style={{ display: disabledValue ? "none" : "block" }}
                        >
                            {ICONS.cancelIconRound}
                        </button>
                        {ICONS.pageIcon}
                        <div className={styles.pdf_values}>
                            <span className={styles.first_value}>
                                <b>
                                    {file.fileObject.name?.length > 15
                                        ? `${file.fileObject.name.substring(0, 15)}...`
                                        : file.fileObject.name}
                                </b>
                            </span>
                            <span className={styles.last_value}>
                                {file.fileObject.type?.split("/")[1]} / {fileSize}
                            </span>
                        </div>
                    </label>
                </div>
            ) : file.docUrlRecievedFromDB ? (
                <div className={styles.value}>
                    <label htmlFor={inputId} className={styles.submit_file_upload_label}>
                        <button
                            onClick={() => handleUrlCancel(file.docUrlRecievedFromDB)}
                            className={styles.cancel_img}
                            style={{ display: disabledValue ? "none" : "block" }}
                        >
                            {ICONS.cancelIconRound}
                        </button>

                        <iframe
                            className={styles.received_doc_url_image}
                            src={file.docUrlRecievedFromDB}
                            alt="document recieved"
                        />

                        {/* <div className={styles.pdf_values}>
                            <span className={styles.first_value}>
                                <b>
                                    {file.fileObject.name?.length > 15
                                        ? `${file.fileObject.name.substring(0, 15)}...`
                                        : file.fileObject.name}
                                </b>
                            </span>
                            <span className={styles.last_value}>
                                {file.fileObject.type?.split("/")[1]} / {fileSize}
                            </span>
                        </div> */}
                    </label>
                </div>
            ) : (
                <span claassName={styles.value}>
                    <label htmlFor={inputId} className={styles.file_upload_label}>
                        <span className={styles.icon}>{ICONS.attachFileIcon}</span>
                        Attach file
                    </label>
                </span>
            )}
        </div>
    );
};

export default UploadFile;
