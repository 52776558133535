import React from 'react';
// import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Element, ...rest }) => {

    const mainBaseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_MAIN_DEV : process.env.REACT_APP_MAIN_PROD;
    
    setTimeout(() => {
        const accessToken = localStorage.getItem('accessToken');
        console.log("Protected Route triggered :", accessToken);
        if (!accessToken) {
            window.location.href = `${mainBaseUrl}/auth/login`;
            return null;
        }

    }, 700);

    return <Element {...rest} />;
};

export default ProtectedRoute;
