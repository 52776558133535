import styles from "./AddServiceComponent.module.css";
import commonStyles from "../../../../../Profile_Settings/Styles/ProfileCommonCss.module.css";
import stylesHeader from "../../../../../Orders/Sections/Accepted/Sections/PaymentDetails/PaymentDetails.module.css";
import jobcardstyle from "../../../../../Orders/Sections/Accepted//Sections/JobCard/JobCard.module.css";

import Button from "../../../../../Components/Button/Button";
import DragAndDrop from "../../../../../Profile_Settings/Components/DragAndDropComponent/DragAndDropComponent";
import DropdownList from "../../../../../Profile_Settings/Components/DropdownList/DropdownList";
import Inputbox from "../../../../../Profile_Settings/Components/Inputbox/Inputbox";
import SaveChangesButton from "../../../../../Profile_Settings/Components/SaveChangesButton/SaveChangesButton";
import { ICONS } from "../../../../../../Assets/Icons";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios2 from "../../../../../../axios2";
import { IndividualSectors } from "./Data/IndividualData";
import { BusinessSectors } from "./Data/BusinessData";
import DynamicDropdownList from "../../../../../Profile_Settings/Components/DropdownList/DynamicDropdownList";

const AddServiceComponent = ({
  // setServiceAarray,
  addMoreServiceFunction,
  edit,
  serviceToBeAdded,
  setServiceToBeAdded,
  portfolio,
  setPortfolio,
  fileError,
  setFileError,
  emptyValue,
  serviceToBeEditedId,
  displayBrowseOption,
  clearAllFunction,
  editIndividual,
  formDetails,
  setEmptyValue,
  onClose,
}) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;
  const accessToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sectorName, setSectorName] = useState("");
  const [subCategoryRecievedList, setSubCategoryRecievedList] = useState([]);
  const [subCategoryNameList, setSubCategoryNameList] = useState([]);
  const [servicesRecievedList, setServicesRecievedList] = useState([]);
  const [questionsFetched, setQuestionsFetched] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [servicesList, setServicesList] = useState({
    serviceNames: [],
  });
  const [subCategory, setSubCategory] = useState({
    subCategoryName: "",
  });
  const stateStored = localStorage.getItem('state')
  const state = JSON.parse(stateStored)

  const units = {
    Construction: [
      "Per square foot",
      "Per hour",
      "Per day",
      "% of the project cost",
      "Fixed fee",
      "Per service",
      "Per Km",
      "Per week",
    ],
    Event: [
      "Per hour",
      "Per event",
      "Per plate",
      "Per item/unit",
      "Per guest",
      "Per day",
      "% of the total event cost",
      "Per show",
      "Per person",
      "Per performance",
      "Per service",
    ],
    Homeservice: [
      'Per session/ Hour',
      'Per meal',
      'Per month',
      'Per visit',
      'Per garment',
      'Per hour',
      'Per day',
      'Per event',
      'Per kilogram',
      'Per trip',
      'Per service',
      'Per week',
      'Per kilo',
      'Per piece'
    ],
    Entertainment: [
      'per day',
      'per shoot',
      'per script',
      'per episode',
      'per project',
      'per actor cast',
      'per hour',
      'per project',
      'per outfit',
      'per post',
      'per campaign',
      'per costume',
      'per meal',
      'per song',
      'monthly salary',
      'per performance'
    ]
  }

  console.log(state, "state");

  const onCloseFunction = () => {
    onClose();
    setPortfolio([]);
  };

  const fetchSectorName = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/sectors/me`,
        {
          sectorId: state?.userDetails?.sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response.data.data.sector.sectorName, "fetchSectorName");
      setSectorName(() => response.data.data.sector.sectorName);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/sectors/categories/subCategories`,
        {
          categoryId: state.currCategoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(
        response.data.data.subCategories,
        "response fetchSubCategory"
      );
      if (response.status === 200) {
        setSubCategoryRecievedList(() => response.data.data.subCategories);
        const subCategoryNamesFiltered = response.data.data.subCategories.map(
          (cat) => cat.subCategoryName
        );
        setSubCategoryNameList(subCategoryNamesFiltered);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const fetchServices = async (subCategoryId) => {
    console.log(subCategoryId, "subCategoryId");
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/sectors/categories/subCategories/services-offered`,
        {
          subCategoryId: subCategoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, "response fetchServices");
      if (response.status === 200) {
        if (!editIndividual) {
          setServiceToBeAdded((prev) => ({ ...prev, serviceIds: [] }));
        }
        setServicesRecievedList(() => response.data.data.servicesOffered);
        const servicesNamesFiltered = response.data.data.servicesOffered?.map(
          (cat) => cat.name
        );
        // setServicesNameList(servicesNamesFiltered);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const uploadDoc = async (file) => {
    try {
      setIsLoading(true);
      const generateUrl = await axios2.post(
        `${baseUrl}/api/v1/auth/generate-upload-url`,
        {
          bucketName: "connect-dev-test",
          key: file.key,
          expDate: 15,
          contentType: file.fileObject?.type,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { url } = generateUrl.data.data;
      console.log(url, "url");

      const uploadFile = await axios.put(url, file.fileObject, {
        headers: {
          "Content-Type": file.fileObject.type,
        },
      });
      console.log(uploadFile, "uploadFile");
      if (uploadFile.status === 200) {
        const uploadDocDetails = await axios2.post(
          `${baseUrl}/api/v1/auth/list-uploaded-document`,
          {
            bucketName: "connect-dev-test",
            key: file.key,
            contentType: file.fileObject.type,
            documentType: file.documentType,
            documentName: file.fileObject.name,
            documentDescription: file.documentDescription,
            documentSize: file.fileObject.size,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log(uploadDocDetails, "uploadDocDetails");
        if (uploadDocDetails.status === 201) {
          const docDetails = uploadDocDetails.data.data.document;
          setServiceToBeAdded((prev) => ({
            ...prev,
            uploadedImageIds: [...prev?.uploadedImageIds, docDetails.id],
          }));
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFullData = async () => {
    try {
      setIsLoading(true);
      console.log(serviceToBeAdded, "serviceToBeAdded");
      console.log(userId, "userId");
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/service-management/add-or-update`,
        {
          userId: userId,
          ...serviceToBeAdded,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, "upload full data");
      navigate("../../../serviceManagement");
      editIndividual
        ? toast.success("Service edited")
        : toast.success("Service added");
      setEmptyValue(null);
      onClose();
      setPortfolio([]);
    } catch (error) {
      setEmptyValue(null);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const retriveDocuments = async (docId) => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/auth/document-retrival`,
        {
          documentId: docId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      console.log(response, "retrieved Document");

      if (response.status === 200) {
        const docFile = {
          docId: docId,
          docType: response.data.data.type,
          docName: response.data.data.name,
          docUrl: response.data.data.url,
        };

        setPortfolio((prev) => {
          const repeatedDoc = prev.find(
            (photo) => photo.docId === docFile.docId
          );
          if (repeatedDoc) {
            return prev;
          } else {
            return [...prev, docFile];
          }
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    }
  };

  const getData = async () => {
    try {
      console.log("lllllll");
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/service-management/get`,
        {
          id: serviceToBeEditedId,
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, " getData response");
      if (response.status === 200) {
        setServiceToBeAdded(() => response.data.data.providedService);
        // setServicesRecievedList(() => response.data.data.providedService);
        console.log(
          response.data.data.providedService.uploadedImageIds,
          "uploadedImageIds"
        );
        if (response.data.data.providedService.uploadedImageIds.length > 0) {
          response.data.data.providedService.uploadedImageIds.forEach((id) =>
            retriveDocuments(id)
          );
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      // setShowModal(true);
      setIsLoading(false);
    }
  };

  const getQuestions = async () => {
    try {
      console.log("lllllll", state.userDetails.userId, state.currCategoryId, state.userType);
      setIsLoading(true);
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/service-management/questions`,
        {
          serviceProviderId: state.userDetails.userId,
          categoryId: state.currCategoryId,
          userType: state.userType,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(response, "getQuestions response");

      setQuestionsFetched(response.data.data.questions);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      // setShowModal(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(emptyValue, "11");
    if (
      emptyValue === false &&
      questionsFetched.length === serviceToBeAdded.answers.length
    ) {
      const newDocAddedToUpload = portfolio.filter((photo) => photo.fileObject);
      console.log(newDocAddedToUpload, "newDocAddedToUpload");
      if (newDocAddedToUpload.length > 0) {
        if (!Object.keys(serviceToBeAdded).includes("uploadedImageIds")) {
          setServiceToBeAdded((prev) => ({ ...prev, uploadedImageIds: [] }));
        }
        newDocAddedToUpload.forEach((photo) => uploadDoc(photo));
      }
      if (newDocAddedToUpload.length === 0) {
        if (!Object.keys(serviceToBeAdded).includes("uploadedImageIds")) {
          setServiceToBeAdded((prev) => ({ ...prev, uploadedImageIds: [] }));
        }
        console.log("in if condition newDocAddedToUpload.length === 0");
        uploadFullData();
        setEmptyValue(() => null);
      }
    } else if (
      emptyValue === false &&
      questionsFetched.length !== serviceToBeAdded.answers.length
    ) {
      console.log(emptyValue, "11");

      setEmptyValue(() => true);
    }
  }, [emptyValue]);

  useEffect(() => {
    if (
      emptyValue === false &&
      serviceToBeAdded?.uploadedImageIds?.length === portfolio?.length
    ) {
      console.log("in if condition serviceToBeAdded?.uploadedImageIds");
      uploadFullData();
    }
  }, [serviceToBeAdded?.uploadedImageIds]);

  useEffect(() => {
    const existingDocIds = portfolio.reduce(
      (acc, crr) => (crr.docId ? [...acc, crr.docId] : acc),
      []
    );
    console.log(existingDocIds, "existingDocIds");
    if (existingDocIds.length > 0) {
      setServiceToBeAdded((prev) => ({
        ...prev,
        uploadedImageIds: existingDocIds,
      }));
    }
  }, [portfolio]);

  useEffect(() => {
    servicesRecievedList?.forEach((obj) => {
      servicesList?.serviceNames?.includes(obj.name) &&
        !serviceToBeAdded?.serviceIds?.includes(obj.id)
        ? setServiceToBeAdded((prev) => ({
          ...prev,
          serviceIds: [...prev?.serviceIds, obj.id],
        }))
        : !servicesList?.serviceNames?.includes(obj.name) &&
        serviceToBeAdded?.serviceIds?.includes(obj.id) &&
        setServiceToBeAdded((prev) => ({
          ...prev,
          serviceIds: prev?.serviceIds?.filter((id) => id !== obj.id),
        }));
    });
  }, [servicesList]);

  useEffect(() => {
    // if (serviceToBeAdded?.subCategory?.id) {
    //   console.log('bmw')
    //   fetchServices(serviceToBeAdded?.subCategory?.id);
    // }
    if (serviceToBeAdded?.subCategory?.subCategoryName) {
      setSubCategory({
        subCategoryName: serviceToBeAdded?.subCategory?.subCategoryName,
      });
    }
    if (
      !serviceToBeAdded?.category?.imageKey ||
      !serviceToBeAdded?.subCategory?.imageKey ||
      !serviceToBeAdded?.subCategory?.subCategoryDescription
    )
      setServiceToBeAdded((prev) => ({
        ...prev,
        category: { ...prev?.category, imageKey: "qwerty" },
        subCategory: {
          ...prev?.subCategory,
          imageKey: "qwerty",
          subCategoryDescription: "qwerty",
        },
      }));
  }, [serviceToBeAdded?.subCategory]); // this executes when data is recieved from getData fetchcall for edit mode

  useEffect(() => {
    fetchServices(serviceToBeAdded?.subCategoryId);
  }, [serviceToBeAdded?.subCategoryId]);

  useEffect(() => {
    const selectedSubCategory = subCategoryRecievedList.find(
      (cat) => cat.subCategoryName === subCategory.subCategoryName
    );

    if (selectedSubCategory?.id) {
      setServiceToBeAdded({
        ...serviceToBeAdded,
        subCategoryId: selectedSubCategory.id,
      });
    }
  }, [subCategory?.subCategoryName]);

  useEffect(() => {
    setServiceToBeAdded((prev) => ({ ...prev, answers: answersList }));
  }, [answersList]);

  useEffect(() => {
    if (editIndividual) {
      const answersListRequired = questionsFetched.map((ques) => ({
        answerTexts: ques.answer[0].answerTexts,
        questionTemplateId: ques.answer[0].questionTemplateId,
      }));
      setAnswersList(answersListRequired);
    }
  }, [questionsFetched]);

  useEffect(() => {
    const selectedServices = servicesRecievedList?.reduce((acc, crr) => {
      return serviceToBeAdded?.serviceIds?.includes(crr.id)
        ? [...acc, crr.name]
        : acc;
    }, []);

    setServicesList({ serviceNames: selectedServices });
  }, [serviceToBeAdded?.serviceIds, servicesRecievedList]);

  const [essentialValues, setEssentialValues] = useState({});

  useEffect(() => {
    if (editIndividual) {
      let result = {};
      for (let key in serviceToBeAdded) {
        console.log(key, 'keyy')
        if (key !== 'uploadedImageIds')
          result = { ...result, [key]: serviceToBeAdded[key] }
      }
      setEssentialValues(result);
    }
  }, [serviceToBeAdded])

  console.log(essentialValues, 'essentialValues')

  // useEffect(() => {
  //   const keys =
  //     sectorName &&
  //     state.currCategoryName &&
  //     (state?.userType === "Individual" && sectorName
  //       ? IndividualSectors[sectorName][state.currCategoryName]
  //       : state?.userType === "Business"
  //         ? BusinessSectors[sectorName][state.currCategoryName]
  //         : []
  //     )?.reduce(
  //       (acc, crr) =>
  //       (acc = {
  //         ...acc,
  //         [crr.keyName]: crr.selectType === "multiple" ? [] : "",
  //       }),
  //       {}
  //     );
  //   console.log(keys, "keys");
  //   // let requiredKeys = {}

  //   // keys.forEach((key) => {
  //   // requiredKeys = {...requiredKeys, [key] : ''}
  //   // })

  //   setServiceToBeAdded((prev) => ({ ...prev, ...keys }));
  // }, [sectorName, state.currCategoryName]);

  useEffect(() => {
    console.log("initial useEffect");
    fetchSubCategories();
    fetchSectorName();

    if (serviceToBeEditedId) {
      console.log("inside serviceToBeEditedI");
      getData();
    }
    getQuestions();

    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   console.log('heelo')

  // }, [state?.userDetails?.sectorId]);

  console.log(serviceToBeAdded, "serviceToBeAdded");
  // console.log(subCategoryNameList, "subCategoryNameList");
  console.log(sectorName, "sectorName");

  // console.log(emptyValue, "emptyValue");

  // console.log(servicesNameList, 'ServicesNameList');
  // console.log(servicesRecievedList, "servicesRecievedList");
  // console.log(selectedServices, 'selectedServices');
  // console.log(selectedSubCategory, "selectedSubCategory");
  // console.log(portfolio, "portfolio");
  // console.log(emptyValue, "emptyvalue");
  // console.log(edit, "edit");
  // console.log(servicesList, "servicesList");
  //console.log(serviceToBeEditedId, 'inside serviceToBeEditedI')
  // console.log(BusinessSectors);

  console.log(servicesList, "serviceList");

  console.log(answersList, "answersList");

  return (
    <div
      className={
        !editIndividual
          ? `${commonStyles.Main_Container} ${styles.component_container}`
          : jobcardstyle.main_body
      }
    >
      <Loader isLoading={isLoading} />
      {editIndividual && (
        <div className={stylesHeader.modelBox_header}>
          <span>Edit Service</span>
          <span onClick={() => onCloseFunction()}>{ICONS.closeWhiteIcon}</span>
        </div>
      )}
      <div className={editIndividual && jobcardstyle.modelBox}>
        <div className={styles.body_container}>
          <section className={commonStyles.main_container_form}>
            <div className={commonStyles.form_data}>
              <label className={commonStyles.form_label}>
                <Link to="../../../serviceManagement"> Sector</Link>
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={true}
                emptyValue={emptyValue}
                value={sectorName}
                color="var(--dark-grey)"
                placeholder="Sector"
              />
            </div>
            <div className={commonStyles.form_data}>
              <label className={commonStyles.form_label}>
                Category
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={true}
                emptyValue={emptyValue}
                value={state.currCategoryName}
                color="var(--dark-grey)"
                placeholder="Category"
              />
            </div>
            <div className={commonStyles.form_data}>
              <label className={commonStyles.form_label}>
                Choose sub-category
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={editIndividual ? !edit : edit}
                emptyValue={emptyValue}
                optionSelected={subCategory.subCategoryName}
                setOptionSelected={setSubCategory}
                keyName="subCategoryName"
                options={subCategoryNameList}
                selectType="single"
              />
            </div>
          </section>
          <div className={styles.main_container_form}>
            <div className={commonStyles.form_data}>
              <label className={commonStyles.form_label}>
                Services offered
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={edit}
                emptyValue={emptyValue}
                optionSelected={servicesList.serviceNames}
                setOptionSelected={setServicesList}
                //serviceToBeAdded={serviceToBeAdded}
                keyName="serviceNames"
                options={servicesRecievedList}
                //placeholder="Services Offered"
                selectType="multiple"
              />
            </div>

            {questionsFetched?.map((component) => {
              const currentSelectedAnswers =
                answersList?.length > 0 &&
                answersList?.find(
                  (obj) => obj?.questionTemplateId === component?.question[0].id
                );
              return component?.questionOptions?.length > 0 ? (
                <div className={commonStyles.form_data}>
                  <label className={commonStyles.form_label}>
                    {component?.question[0]?.questionText}
                    <span className={commonStyles.star_mark}>*</span>
                  </label>
                  {/* <DropdownList
                    edit={edit}
                    emptyValue={emptyValue}
                    optionSelected={serviceToBeAdded[component.keyName]}
                    setOptionSelected={setServiceToBeAdded}
                    serviceToBeAdded={serviceToBeAdded}
                    keyName={component.keyName}
                    options={component.options}
                    // placeholder={component.placeholder}
                    selectType={component.selectType}
                  /> */}
                  <DynamicDropdownList
                    edit={edit}
                    emptyValue={emptyValue}
                    setEmptyValue={setEmptyValue}
                    optionSelected={
                      currentSelectedAnswers?.answerTexts
                        ? currentSelectedAnswers?.answerTexts
                        : []
                    }
                    setOptionSelected={setAnswersList}
                    answersList={answersList}
                    questionId={component?.question[0]?.id}
                    options={component.questionOptions}
                    // placeholder={component.placeholder}
                    selectType={component?.question[0]?.questionType}
                  />
                </div>
              ) : (
                <div className={commonStyles.form_data}>
                  <label className={commonStyles.form_label}>
                    {component.title}
                    <span className={commonStyles.star_mark}> *</span>
                  </label>
                  <Inputbox
                    readOnly={!edit}
                    emptyValue={emptyValue}
                    value={serviceToBeAdded[component.keyName]}
                    onChange={(e) =>
                      setServiceToBeAdded({
                        ...serviceToBeAdded,
                        [component.keyName]: e.target.value,
                      })
                    }
                    type={component.type}
                    placeholder={component.placeholder}
                  />
                </div>
              );
            })}

            <div className={commonStyles.form_data}>
              <label className={commonStyles.form_label}>
                How much do you charge?
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <div className={styles.amount_section}>
                <Inputbox
                  readOnly={!edit}
                  emptyValue={emptyValue}
                  value={serviceToBeAdded?.price}
                  onChange={(e) =>
                    setServiceToBeAdded({
                      ...serviceToBeAdded,
                      price: e.target.value,
                    })
                  }
                  type="number"
                  placeholder="Amount"
                />
                <DropdownList
                  edit={edit}
                  emptyValue={emptyValue}
                  optionSelected={serviceToBeAdded?.per}
                  serviceToBeAdded={serviceToBeAdded}
                  setOptionSelected={setServiceToBeAdded}
                  keyName="per"
                  options={
                    units[sectorName]
                  }
                  placeholder="Per"
                  defaultValue="Per"
                />
              </div>
            </div>
          </div>
          <div className={commonStyles.main_container_form}>
            <div className={commonStyles.form_data_Bio}>
              <label className={commonStyles.form_label}>
                Add service description{" "}
                <span className={commonStyles.star_mark}>*</span>
              </label>
              <textarea
                className={styles.textarea}
                style={{
                  backgroundColor: edit
                    ? "var(--lavender-blue)"
                    : "var(--light-grey)",
                  color: edit ? " var(--smoky-black)" : "var(--grey)",
                }}
                value={serviceToBeAdded?.bio}
                onChange={(e) =>
                  setServiceToBeAdded({
                    ...serviceToBeAdded,
                    bio: e.target.value,
                  })
                }
                disabled={edit ? false : true}
                placeholder="Expert in planning, executing, and closing projects efficiently.Committed to maintaining high standards of quality and compliance with industry regulations."
              ></textarea>
              {emptyValue && serviceToBeAdded.bio === "" && (
                <p className={commonStyles.error}>* required</p>
              )}
            </div>
          </div>
          <div
            className={commonStyles.form_data}
            style={{
              width: "100%",
            }}
          >
            <label className={commonStyles.form_label}>
              Upload Photos
              {/* <span className={commonStyles.star_mark}>*</span> */}
            </label>
            <DragAndDrop
              disabled={!edit}
              file={portfolio}
              setFile={setPortfolio}
              setFileError={setFileError}
              displayBrowseOption={displayBrowseOption}
              acceptedType={["image/*"]}
              keyTitle="portfolio"
              documentType="Portfolio"
              documentDescription="service related photos"
            />
            {fileError && <p className={commonStyles.error}>{fileError}</p>}
          </div>
        </div>
      </div>
      <section
        style={{ display: edit ? "flex" : "none" }}
        // className={}
        className={
          editIndividual
            ? jobcardstyle.modelBox_footer
            : styles.bottom_button_section
        }
      >
        {editIndividual ? (
          <SaveChangesButton
            width="100%"
            formDetails={essentialValues}
            edit={edit}
            setEmptyValue={setEmptyValue}
          />
        ) : null}
      </section>
    </div>
  );
};

export default AddServiceComponent;
