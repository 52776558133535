import React, { useEffect, useRef, useState } from "react";
import styles from "../DropdownList/DropdownList.module.css";
import { ICONS } from "../../../../Assets/Icons";

const DynamicDropdownList = ({
  edit,
  setOptionSelected,
  answersList,
  optionSelected,
  questionId,
  emptyValue,
  options,
  defaultValue,
  from,
  minWidth,
  padding,
  selectType,
  keyName,
}) => {
  // console.log(optionSelected, 'optionSelected')
  const initialvalue = defaultValue || "Choose an option";
  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState(initialvalue);
  const ref = useRef(null);

  const requiredAnswer = answersList.find(
    ({ questionTemplateId }) => questionTemplateId === questionId
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(!showOptions);
    }
  };
  // console.log(setOptionSelected, " setOptionSelected");
  //console.log(optionSelected, "optionSelected");

  const handleToggle = (value) => {
    console.log(value, "value");
    // setSelected(value);
    setShowOptions(false);

    if (requiredAnswer) {
      const updatedArray = answersList.map((ans) =>
        ans.questionTemplateId === questionId
          ? { ...ans, answerTexts: [value.optionText] }
          : ans
      );
      setOptionSelected(updatedArray);
    } else {
      setOptionSelected((prev) => [
        ...prev,
        {
          questionTemplateId: value.questionId,
          answerTexts: [value.optionText],
        },
      ]);
    }
    // const result = optionSelected.map(())
  };

  const handleCheck = (e) => {

    const { value, checked, id } = e.target;

    let result = null;

    if (value.toLowerCase() === "all of the above" && checked) {

      let optionValues = [...options].map(({ optionText }) => optionText)

      result = optionValues.filter((value) => value.toLowerCase() !== 'none')

    } else if (value.toLowerCase() === "all of the above" && !checked) {

      result = []

    } else if (value === "none" && checked) {

      result = ["none"]

      setShowOptions(false);

    } else if (checked) {

      result = [...optionSelected, value]

    } else {

      const updatedValue = [...optionSelected].filter(
        (option) => option !== value && option.toLowerCase() !== 'all of the above'
      );

      result = updatedValue
    }

    if (requiredAnswer) {
      const updatedArray = answersList.map((ans) =>
        ans.questionTemplateId === questionId
          ? { ...ans, answerTexts: result }
          : ans
      );
      setOptionSelected(updatedArray);
    } else {
      setOptionSelected((prev) => [
        ...prev,
        {
          questionTemplateId: id,
          answerTexts: result,
        },
      ]);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(optionSelected) &&
      optionSelected?.includes("none") &&
      optionSelected.length > 1
    ) {
      const updatedAnswerArray = [...optionSelected].filter(
        (option) => option !== "none"
      );
      const updatedFullArray = answersList.map((ans) =>
        ans.questionTemplateId === questionId
          ? { ...ans, answerTexts: updatedAnswerArray }
          : ans
      );
      setOptionSelected(updatedFullArray);

    }
  }, [optionSelected]);

  // useEffect(() => {
  //   if (selected !== initialvalue) {
  //     console.log(selected, 'selected')
  //     console.log(keyName, 'keyName')
  //     setOptionSelected({
  //       ...serviceToBeAdded,
  //       [keyName]: selected,
  //     });
  //   }
  // }, [selected]);

  useEffect(() => {
    if (showOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  // console.log(initialvalue, 'initialvalue')

  return (
    <div
      style={{ minWidth: minWidth }}
      // className={styles.DropdownList_parent}
      ref={ref}
      className={
        emptyValue &&
          ((Array.isArray(optionSelected) && optionSelected.length === 0) ||
            optionSelected === "")
          ? `${styles.emptyInput} ${styles.DropdownList_parent}`
          : styles.DropdownList_parent
      }
    >
      <div
        className={styles.dropdownList}
        // style={{ padding: padding }}
        style={{
          backgroundColor: edit ? "var(--lavender-blue)" : "var(--light-grey)",
          color: edit ? " var(--smoky-black)" : "var(--grey)",
        }}
        onClick={() => (edit ? setShowOptions(!showOptions) : null)}
      >
        <div className={styles.dropdownList_text}>
          {Array.isArray(optionSelected) && optionSelected.length > 0
            ? optionSelected?.map((option) => <span> {option}, </span>)
            : (optionSelected?.length === 0 && initialvalue) ||
            optionSelected ||
            initialvalue}
        </div>
        <div className={showOptions ? styles.DropdownList_Cursor : null}>
          {ICONS.cursorDownGrey}
        </div>
      </div>
      {showOptions && (
        <div className={styles.DropdownList_Data}>
          {options?.map((item, index) =>
            selectType === "multiple" ? (
              <label className={styles.DropdownList_childData}>
                <input
                  type="checkbox"
                  value={item.optionText}
                  id={item.questionId}
                  onChange={(e) => handleCheck(e)}
                  checked={optionSelected?.includes(item.optionText)}
                  className={styles.checkbox}
                />
                {item.optionText}
              </label>
            ) : (
              <p
                className={styles.DropdownList_childData}
                key={index}
                onClick={() => handleToggle(item)}
              >
                {item.optionText}
              </p>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DynamicDropdownList;
