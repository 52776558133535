import { ICONS } from "../../../Assets/Icons";
import commonStyle from "../../../commonCss.module.css";
import Button from "../../Components/Button/Button";
import Styles from "./OrderManagement.module.css";
import "../../../utils/utilsCss/utilsCss.css";
import { Link } from "react-router-dom";
import SortByYear from "../Components/SortByYear/SortByYear";
import { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import axios2 from "../../../axios2";
import { useSelector } from "react-redux";

const OrderManagement = () => {

  const [selectedYear, setSelectedYear] = useState(2024);
  const [dropdownOption, setDropdownOption] = useState("This year");
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredInfo, setHoveredInfo] = useState(false);

  const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

  const [orderTotalCount, setOrderTotalCount] = useState(null);
  const [orderDifferences, setOrderDifferences] = useState({
    pending: null,
    completed: null,
    cancelled: null,
    inProcess: null,
  });

  const [orders, setOrders] = useState(null);

  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  const stateStored = localStorage.getItem('state')
  const stateData = JSON.parse(stateStored)
  const sectorId = stateData.currSectorId;

  const timeCalculator = (createdDate) => {
    const current = new Date();

    const difference = current - new Date(createdDate);

    const diffInseconds = Math.floor(difference / 1000);
    const diffInMinutes = Math.floor(diffInseconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInMinutes < 1) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour`;
    } else if (diffInHours < 48) {
      return "yesterday";
    } else {
      return new Date(createdDate).toLocaleString();
    }
  };

  const valueCalculator = (dataObject) => {
    const currentPeriod = dataObject.currentPeriod;
    const previousPeriod = dataObject.previousPeriod;

    setOrderTotalCount(() => currentPeriod);

    setOrderDifferences(() => ({
      ...orderDifferences,
      pending:
        ((currentPeriod[0].count - previousPeriod[0].count) /
          (previousPeriod[0].count ===
            0
            ? 1
            : previousPeriod[0].count) * 100),
      completed:
        ((currentPeriod[1].count - previousPeriod[1].count) /
          (previousPeriod[1].count ===
            0
            ? 1
            : previousPeriod[1].count)) * 100,
      cancelled:
        ((currentPeriod[2].count - previousPeriod[2].count) /
          (previousPeriod[2].count ===
            0
            ? 1
            : previousPeriod[2].count)) * 100,
      inProcess:
        ((currentPeriod[3].count - previousPeriod[3].count) /
          (previousPeriod[3].count ===
            0
            ? 1
            : previousPeriod[3].count)) * 100,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider/by_year_month_week`,
        {
          year: selectedYear,
          period: dropdownOption.split(" ")[1],
          userId,
          sectorId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      valueCalculator(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrders = async () => {
    try {
      const res = await axios2.post(
        `${baseUrl}/api/v1/order-management/service-provider?status=Pending`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setOrders(res.data.data.services);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId && sectorId) {
      fetchData();
    }
  }, [selectedYear, dropdownOption, userId, sectorId]);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <SortByYear
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        dropdownOption={dropdownOption}
        setDropdownOption={setDropdownOption}
      />
      <div className={Styles.container}>
        <div className={Styles.left_container}>
          <div className={Styles.left_box}>
            <div className={`${Styles.left_detail} center-vertical`}>
              {/* <span className={Styles.left_img}>{ICONS.pendingOrderIcon}</span> */}
              <img
                src="/Assets/dashboard/OrderManagement/pendingOrderIcon.svg"
                className={Styles.left_img}
                alt="..."
              />
              <div className={`column`}>
                <h1>{orderTotalCount && orderTotalCount[0]?.count}</h1>
                <p>Pending Orders</p>
              </div>
            </div>
            <span
              className={
                orderDifferences.pending < 0
                  ? `${Styles.change} ${Styles.negative}`
                  : `${Styles.change} ${Styles.positive}`
              }
            >
              {orderDifferences.pending ? orderDifferences.pending : '-'}%
            </span>
          </div>
          <div className={Styles.left_box}>
            <div className={`${Styles.left_detail} center-vertical`}>
              {/* <span className={Styles.left_img}>{ICONS.inProcessIcon}</span> */}
              <img
                src="/Assets/dashboard/OrderManagement/inProcessIcon.svg"
                className={Styles.left_img}
                alt="..."
              />
              <div className={`column`}>
                <h1>{orderTotalCount && orderTotalCount[3]?.count}</h1>
                <p>In Process Orders</p>
              </div>
            </div>

            <div className={
              orderDifferences.inProcess < 0
                ? `${Styles.change} ${Styles.negative}`
                : `${Styles.change} ${Styles.positive}`
            }>{orderDifferences.inProcess ? orderDifferences.inProcess : '-'} %</div>
          </div>
          <div className={Styles.left_box}>
            <div className={`${Styles.left_detail} center-vertical`}>
              {/* <span className={Styles.left_img}>{ICONS.completedIcon}</span> */}
              <img
                src="/Assets/dashboard/OrderManagement/completedIcon.svg"
                className={Styles.left_img}
                alt="..."
              />
              <div className={`column`}>
                <h1>{orderTotalCount && orderTotalCount[1]?.count}</h1>
                <p>Completed Orders</p>
              </div>
            </div>
            <span className={
              orderDifferences.completed < 0
                ? `${Styles.change} ${Styles.negative}`
                : `${Styles.change} ${Styles.positive}`
            }>
              {orderDifferences.completed ? orderDifferences.completed : '-'} %
            </span>
          </div>
          <div className={Styles.left_box}>
            <div className={`${Styles.left_detail} center-vertical`}>
              {/* <span className={Styles.left_img}>{ICONS.canceledIcon}</span> */}
              <img
                src="/Assets/dashboard/OrderManagement/cancelledIcon.svg"
                className={Styles.left_img}
                alt="..."
              />
              <div className={`column`}>
                <h1>{orderTotalCount && orderTotalCount[2]?.count}</h1>
                <p>Cancelled Orders</p>
              </div>
            </div>
            <span className={
              orderDifferences.cancelled < 0
                ? `${Styles.change} ${Styles.negative}`
                : `${Styles.change} ${Styles.positive}`
            }>
              {orderDifferences.cancelled ? orderDifferences.cancelled : '-'} %
            </span>
          </div>
        </div>
        <div className={Styles.headerOnMobile}>
          <h2>
            New Orders ({orders?.length}) &nbsp;&nbsp;
            <span
              onClick={() => setHoveredInfo(!hoveredInfo)}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{ display: hoveredInfo ? "flex" : "none" }}
              >
                View a detailed breakdown of new orders, including counts,
                statuses, and total value.
              </span>
            </span>
          </h2>
          {/* <a href="#">View all</a> */}
        </div>
        <div className={Styles.right_container}>
          <div className={Styles.header}>
            <h2>
              New Orders ({orders?.length})&nbsp;
              <span
                onMouseEnter={() => setHoveredInfo(true)}
                onMouseLeave={() => setHoveredInfo(false)}
                className={commonStyle.expanInfo}
              >
                {ICONS.infoIcon}
                <span
                  className={commonStyle.informationContent}
                  style={{ display: hoveredInfo ? "flex" : "none" }}
                >
                  View a detailed breakdown of new orders, including counts,
                  statuses, and total value.
                </span>
              </span>
            </h2>
            {/* <a href="#">View all</a> */}
          </div>
          {orders?.length > 0 ? (
            orders?.map((item) => (
              <div key={item?.id} className={Styles.right_box}>
                <div className={Styles.right_data}>
                  <p>{item?.serviceCategory}</p>
                  <p className={Styles.order_text}>
                    Work Location: <span>{item?.deliveryAddress?.city}</span>
                  </p>
                  <p className={Styles.order_text}>
                    Order Date:{" "}
                    <span>
                      {new Date(item?.createdAt).toLocaleDateString()}
                    </span>
                  </p>
                </div>
                <div className={Styles.footer_btn}>
                  <Link to="../order">
                    <div className={`${Styles.actions} ${Styles.hideOnMobile}`}>
                      <Button
                        padding="10px 24px"
                        backgroundColor="var(--blue)"
                        color="var(--white)"
                      >
                        View {ICONS.visibilityWhiteIcon}
                      </Button>
                    </div>
                    <div
                      className={`${Styles.actions} ${Styles.hideOnDesktop}`}
                    >
                      <Button
                        fontSize="14px"
                        padding="5px 12px"
                        backgroundColor="var(--blue)"
                        color="var(--white)"
                      >
                        View {ICONS.visibilityWhiteIcon}
                      </Button>
                    </div>
                  </Link>
                  <div className={Styles.right_bottom_data}>
                    {timeCalculator(item?.createdAt)}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={Styles.emptybox}>New orders are coming soon !</div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderManagement;
