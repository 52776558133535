import { ICONS } from "../../../Assets/Icons/index";
import Styles from "./Overview.module.css";
import commonStyle from "../../../commonCss.module.css";
import "../../../utils/utilsCss/utilsCss.css";
import OrdersBrekoutChart from "./Charts/OrdersBreakout";
import RevenueBreakoutChart from "./Charts/RevenueBreakout";
import AverageOrderMixChart from "./Charts/AverageOrderValueMixChart";
import SalesProgressBar from "./Charts/SalesProgressBar";
import MyMixedChart from "./Charts/MixChart";
import SortByYear from "../Components/SortByYear/SortByYear";
import { useRef, useState } from "react";

const information = {
  orderBreakout:
    "View detailed order breakout, including accepted, rejected, and canceled orders.",
  revenueBreakout:
    "View detailed revenue breakout, including total earnings, refunds, and adjustments.",
  averageOrderValue:
    "View detailed average order value, including overall average, highest, and lowest order values.",
};

const Overview = () => {
  const [selectedYear, setSelectedYear] = useState(2024);
  const [dropdownOption, setDropdownOption] = useState("This year");
  const [currentMonthData, setCurrentMonthData] = useState(null);
  const [hoveredInfo, setHoveredInfo] = useState(null);

  const showInformation = (section) => {

    if (hoveredInfo !== null && section !== hoveredInfo) {
      setHoveredInfo(section);
    } else if (hoveredInfo) {
      setHoveredInfo(null);
    } else {
      setHoveredInfo(section);
    }
  };

  return (
    <>
      <SortByYear
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        dropdownOption={dropdownOption}
        setDropdownOption={setDropdownOption}
      />
      <div className={` ${Styles.overview_page}`}>
        <div className={Styles.overview_top_section}>
          <p
            className={`center-vertical ${Styles.title}  ${Styles.outer_title}`}
          >
            Orders Breakout  <span
              onClick={() => showInformation('orderBreakout')}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{ display: hoveredInfo === 'orderBreakout' ? "flex" : "none" }}
              >
                {information.orderBreakout}
              </span>
            </span>
          </p>
          <section
            className={`${Styles.overview_section} ${Styles.overview_top_section1} `}
          >
            <p className={`center-vertical ${Styles.title}`}>
              Orders Breakout
              <span
                onMouseEnter={() => setHoveredInfo("orderBreakout")}
                onMouseLeave={() => setHoveredInfo(null)}
                className={commonStyle.expanInfo}
              >
                {ICONS.infoIcon}
                <span
                  className={commonStyle.informationContent}
                  style={{ display: hoveredInfo === 'orderBreakout' ? "flex" : "none" }}
                >
                  {information.orderBreakout}
                </span>
              </span>
            </p>
            <OrdersBrekoutChart
              selectedYear={selectedYear}
              dropdownOption={dropdownOption}
            />
          </section>
          <p
            className={`center-vertical ${Styles.title} ${Styles.outer_title}`}
          >
            Revenue Breakout
            <span
              onClick={() => showInformation('revenueBreakout')}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{ display: hoveredInfo === 'revenueBreakout' ? "flex" : "none" }}
              >
                {information.revenueBreakout}
              </span>
            </span>
          </p>
          <section
            className={`${Styles.overview_section} ${Styles.overview_top_section2}`}
          >
            <p className={`center-vertical ${Styles.title}`}>
              Revenue Breakout
              <span
                onMouseEnter={() => setHoveredInfo("revenueBreakout")}
                onMouseLeave={() => setHoveredInfo(null)}
                className={commonStyle.expanInfo}
              >
                {ICONS.infoIcon}
                <span
                  className={commonStyle.informationContent}
                  style={{ display: hoveredInfo === 'revenueBreakout' ? "flex" : "none" }}
                >
                  {information.revenueBreakout}
                </span>
              </span>
            </p>

            <RevenueBreakoutChart
              selectedYear={selectedYear}
              dropdownOption={dropdownOption}
            />
          </section>
        </div>

        <p
          className={`center-vertical ${Styles.title} ${Styles.outer_title} ${Styles.average_order_value_heading}`}
        >
          Average Order Value
          <span
            onClick={() => showInformation("averageOrderValue")}
            className={commonStyle.expanInfo}
          >
            {ICONS.infoIcon}
            <span
              className={commonStyle.informationContent}
              style={{ display: hoveredInfo === "averageOrderValue" ? "flex" : "none" }}
            >
              {information.orderBreakout}
            </span>
          </span>
        </p>
        <section className={`${Styles.overview_section2}`}>
          <p className={`center-vertical ${Styles.title}`}>
            Average Order Value
            <span
              onMouseEnter={() => setHoveredInfo("averageOrderValue")}
              onMouseLeave={() => setHoveredInfo(null)}
              className={commonStyle.expanInfo}
            >
              {ICONS.infoIcon}
              <span
                className={commonStyle.informationContent}
                style={{ display: hoveredInfo === 'averageOrderValue' ? "flex" : "none" }}
              >
                {information.averageOrderValue}
              </span>
            </span>
          </p>
          <div className={Styles.overview_bottom_section}>
            <section className={Styles.mixed_chart_container}>
              <MyMixedChart
                selectedYear={selectedYear}
                dropdownOption={dropdownOption}
                setCurrentMonthData={setCurrentMonthData}
              />
            </section>
            <section className={Styles.progressbar_container}>
              <SalesProgressBar currentMonthData={currentMonthData} />
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default Overview;
