import styles from "../Styles/ProfileCommonCss.module.css";
import commonStyle from "../../ServiceProviderDashboard.module.css";
import Button from "../../Components/Button/Button";
import Inputbox from "../Components/Inputbox/Inputbox";
import DropdownList from "../Components/DropdownList/DropdownList";
import mobile_profile_style from "../../Mobile_Profile/styles/MobileProfileStyle.module.css";
import { ICONS } from "../../../Assets/Icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import EditButton from "../Components/EditButton/EditButton";
import SaveChangesButton from "../Components/SaveChangesButton/SaveChangesButton";
import DragAndDrop from "../Components/DragAndDropComponent/DragAndDropComponent";
import "../../../utils/utilsCss/utilsCss.css";
import axios2 from "../../../axios2";

const ServicesDropdownList = [
  "Option A",
  "Option B",
  "Option C",
  "Option D",
  "Option E",
  "Option F",
  "Other",
];

const PerDropdownList = ["Per hour", "Per day", "per sq. feet", "per project"];

const servicesListArray = [
  {
    serviceName: "Interior Desiging",
    serviceRate: 10000,
    per: "project",
  },

  {
    serviceName: "Flooring",
    serviceRate: 700,
    per: "sq foot",
  },
  {
    serviceName: "Architect",
    serviceRate: 1000,
    per: "hour",
  },
];

const ProfessionalDetails = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [edit, setEdit] = useState(false);
  const [emptyValue, setEmptyValue] = useState(false);
  const [discard, setDiscard] = useState(null);
  const [editService, setEditService] = useState(false);
  const [recievedFile, setRecievedFile] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);
  const [fileKey, setFileKey] = useState("");
  const [fileError, setFileError] = useState("");
  const [otherService, setOtherService] = useState(false);
  const [serviceToBeAdded, setServiceToBeAdded] = useState({
    serviceName: "",
    serviceRate: "",
    per: "",
  });

  const [serviceToBeEdited, setServiceToBeEdited] = useState({
    serviceName: "",
    serviceRate: "",
    per: "",
  });

  const [professionalDetails, setProfessionalDetails] = useState({
    servicesOffered: [...servicesListArray],
    portfolioDocument: "",
    exp: "",
    comments: "",
    prefferedWorkType: "",
    preferredLocation: "",
  });

  const handleEditDiscard = () => {
    if (edit) {
      setEdit(false);
      setDiscard(true);
    } else {
      setEdit(true);
      setDiscard(false);
    }
  };

  const handleAdd = () => {
    setProfessionalDetails({
      ...professionalDetails,
      servicesOffered: [
        ...professionalDetails.servicesOffered,
        serviceToBeAdded,
      ],
    });

    // setServiceList([...servicesList,])

    setServiceToBeAdded({
      serviceName: "",
      serviceRate: "",
      per: "",
    });

    setOtherService(false);
  };

  const emptyServiceToBeAdded = Object.values(serviceToBeAdded).includes("");

  const handleServiceEdit = (index) => {
    const selectedService = professionalDetails.servicesOffered.find(
      (service, i) => i === index
    );
    setEditService(index);
    setServiceToBeEdited(selectedService);
  };

  const handleRemoveService = (index) => {
    const data = [...professionalDetails.servicesOffered];

    const updatedArray = data.filter((service, i) => i !== index);

    setProfessionalDetails({
      ...professionalDetails,
      servicesOffered: updatedArray,
    });
  };

  const handleEditedServiceSave = (index) => {
    const data = [...professionalDetails.servicesOffered];

    const updatedServices = data.map((service, i) =>
      i === index ? serviceToBeEdited : service
    );

    setProfessionalDetails({
      ...professionalDetails,
      servicesOffered: updatedServices,
    });
    setEditService(-1);
  };

  useEffect(() => {
    if (serviceToBeAdded.serviceName === "Other") {
      setOtherService(true);
      setServiceToBeAdded({ ...serviceToBeAdded, serviceName: "" });
    }
  }, [serviceToBeAdded]);

  useEffect(() => {
    setProfessionalDetails({
      ...professionalDetails,
      portfolioDocument: portfolio,
    });
  }, [portfolio]);

  useEffect(() => {
    if (discard) {
      setProfessionalDetails({
        servicesOffered: [...servicesListArray],
        portfolioDocument: "",
        exp: "",
        comments: "",
        prefferedWorkType: "",
        preferredLocation: "",
      });
    }
  }, [discard]);

  useEffect(() => {
    //api call;
    setRecievedFile();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={mobile_profile_style.parent}>
        <div className={`${styles.mobileNavbar} ${styles.hideOnDesktop}`}>
          <Link
            to="../../mobileProfile/personalProfile"
            className={styles.backButton}
          >
            {ICONS.ArrowBackIcon}
          </Link>
          <span>Professional Details</span>
          <span
            className={commonStyle.filter_icon_mobile}
            onClick={handleEditDiscard}
          >
            {edit ? `Discard` : ICONS.EditDataIcon}
          </span>
        </div>
      </div>
      <div className={styles.Profile_Container}>
        <div className={styles.edit_save_button_section}>
          <EditButton edit={edit} onClick={handleEditDiscard} />
          <SaveChangesButton
            formDetails={professionalDetails}
            edit={edit}
            setEmptyValue={setEmptyValue}
          />
        </div>

        <div className={`${styles.Profile_heading} ${styles.hideOnMobile}`}>
          <p className={styles.text}>Professional Details</p>
          <p className={styles.mandatory_data}>
            (* Indicates required question)
          </p>
        </div>
        <div className={styles.heading}>Specializations & Services</div>
        <div className={styles.Main_Container}>
          <div
            className={`${styles.main_container_form} ${styles.pick_services_container}`}
          >
            <div style={{ width: "100%" }} className={styles.form_data}>
              <label className={styles.form_label}>
                Pick your services <span className={styles.star_mark}>*</span>
              </label>
              {otherService ? (
                <div className={styles.add_other_input}>
                  <Inputbox
                    readOnly={!edit}
                    value={serviceToBeAdded?.serviceName}
                    onChange={(e) =>
                      setServiceToBeAdded({
                        ...serviceToBeAdded,
                        serviceName: e.target.value,
                      })
                    }
                    emptyValue={emptyValue}
                    placeholder="Add other service"
                    minWidth="12rem"
                  />
                  <span
                    className={styles.cancel_add_other}
                    onClick={() => setOtherService(false)}
                  >
                    {ICONS.cancelIconRound}
                  </span>
                </div>
              ) : (
                <DropdownList
                  edit={edit}
                  optionSelected={serviceToBeAdded.serviceName}
                  setOptionSelected={(option) =>
                    setServiceToBeAdded({
                      ...serviceToBeAdded,
                      serviceName: option,
                    })
                  }
                  options={ServicesDropdownList}
                  minWidth="12rem"
                />
              )}
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Service Amount
                <span className={styles.star_mark}>*</span>
              </label>
              <Inputbox
                readOnly={!edit}
                type="number"
                value={serviceToBeAdded?.serviceRate}
                onChange={(e) =>
                  setServiceToBeAdded({
                    ...serviceToBeAdded,
                    serviceRate: e.target.value,
                  })
                }
                emptyValue={false}
                placeholder="Enter amount"
                minWidth="9rem"
              />
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Per <span className={styles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={edit}
                optionSelected={serviceToBeAdded.per}
                setOptionSelected={(option) =>
                  setServiceToBeAdded({
                    ...serviceToBeAdded,
                    per: option,
                  })
                }
                options={PerDropdownList}
                defaultValue="unit"
              />
            </div>
            <div className={styles.form_data}>
              <div className={styles.add_button_container}>
                <Button
                  onClick={() => handleAdd()}
                  text="Add"
                  backgroundColor="rgba(254, 212, 98, 1)"
                  width="100%"
                  padding="10px 12px"
                  disabled={emptyServiceToBeAdded}
                />
              </div>
            </div>
          </div>
          <div className={styles.table_container}>
            <table className={styles.service_list_table}>
              <thead className={styles.service_list_headings}>
                <tr className={styles.data_heading}>
                  <th>Services provided</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {professionalDetails?.servicesOffered?.length === 0 ? (
                  <tr>
                    <td>Add service</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                ) : (
                  professionalDetails.servicesOffered?.map(
                    ({ serviceName, serviceRate, per }, index) => (
                      <tr className={styles.data_row} key={serviceName}>
                        <td>{serviceName}</td>
                        {editService === index ? (
                          <td className={styles.edit_service_inputs}>
                            <Inputbox
                              readOnly={!edit}
                              type="number"
                              value={serviceToBeEdited?.serviceRate}
                              onChange={(e) =>
                                setServiceToBeEdited({
                                  ...serviceToBeEdited,
                                  serviceRate: e.target.value,
                                })
                              }
                              emptyValue={emptyValue}
                              placeholder="Enter amount"
                              padding="0.2em"
                              maxWidth="4rem"
                              minWidth="3rem"
                            />

                            <DropdownList
                              edit={edit}
                              optionSelected={serviceToBeEdited.per}
                              setOptionSelected={(option) =>
                                setServiceToBeEdited({
                                  ...serviceToBeEdited,
                                  per: option,
                                })
                              }
                              options={PerDropdownList}
                              defaultValue="unit"
                              padding="0.2em 0.5em"
                            />
                          </td>
                        ) : (
                          <td>
                            &#8377; {serviceRate} {per}
                          </td>
                        )}

                        {editService === index ? (
                          <td>
                            <Button
                              onClick={() => handleEditedServiceSave(index)}
                              text="Save"
                              padding="0px 10px"
                              fontSize="12px"
                              backgroundColor="#FED462"
                              width="fit-content"
                            />
                          </td>
                        ) : (
                          <td>
                            <Button
                              width="fit-content"
                              backgroundColor="#fff"
                              disabled={!edit}
                              onClick={() => handleServiceEdit(index)}
                            >
                              {ICONS.editIconBlack}
                            </Button>
                            <Button
                              width="fit-content"
                              backgroundColor="#fff"
                              disabled={!edit}
                              onClick={() => handleRemoveService(index)}
                            >
                              {ICONS.deleteIconBlack}
                            </Button>
                          </td>
                        )}
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.heading}>Experience</div>
        <div className={styles.Main_Container}>
          <div className={styles.main_container_form}>
            <div className={styles.Profile_heading}>
              <p className={styles.professional_text}>Past Projects</p>
              <p className={styles.professional_textlink}>
                Upload Portfolio/ Samples
              </p>
            </div>
          </div>
          <DragAndDrop
            disabled={!edit}
            file={portfolio}
            setFile={setPortfolio}
            setFileError={setFileError}
            displayBrowseOption={true}
          />

          <div className={styles.professional_form_data}>
            <label className={styles.form_label}>
              Total years in industry
              <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              value={professionalDetails?.exp}
              onChange={(e) =>
                setProfessionalDetails({
                  ...professionalDetails,
                  exp: e.target.value,
                })
              }
              emptyValue={emptyValue}
              placeholder="2"
            />
          </div>
          <div className={styles.professional_form_data}>
            <label className={styles.form_label}>
              Comments <span className={styles.star_mark}>*</span>
            </label>
            <Inputbox
              readOnly={!edit}
              emptyValue={emptyValue}
              value={professionalDetails.comments}
              onChange={(e) =>
                setProfessionalDetails({
                  ...professionalDetails,
                  comments: e.target.value,
                })
              }
              placeholder="Figma ipsum component variant main layer. Figma ipsum component variant main layer. Figma ipsum component variant main layer. Subtract link project image figma hand..."
            />
          </div>
        </div>

        <div className={styles.heading}>Preferences & Locations</div>
        <div className={styles.Main_Container}>
          <div className={styles.main_container_form}>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Preferred Work Type <span className={styles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={edit}
                optionSelected={professionalDetails?.prefferedWorkType}
                setOptionSelected={(option) =>
                  setProfessionalDetails({
                    ...professionalDetails,
                    prefferedWorkType: option,
                  })
                }
                options={ServicesDropdownList}
              />
            </div>
            <div className={styles.form_data}>
              <label className={styles.form_label}>
                Preferred Location <span className={styles.star_mark}>*</span>
              </label>
              <DropdownList
                edit={edit}
                optionSelected={professionalDetails?.preferredLocation}
                setOptionSelected={(option) =>
                  setProfessionalDetails({
                    ...professionalDetails,
                    preferredLocation: option,
                  })
                }
                options={ServicesDropdownList}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.footer_data} ${styles.hideOnMobile}`}>
          <Link to="../educational">
            <Button
              padding="10px 18px"
              text="Next >"
              backgroundColor="var(--yellow)"
              color="var(--smoky-black)"
            />
          </Link>
        </div>
      </div>
      <div className={`${styles.mobileView_footerBtn} ${styles.hideOnDesktop}`}>
        <SaveChangesButton
          width="100%"
          formDetails={professionalDetails}
          edit={edit}
          setEmptyValue={setEmptyValue}
        />
      </div>
    </>
  );
};

export default ProfessionalDetails;
