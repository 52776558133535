import styles from "./OrderSearchSection.module.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import { useEffect, useRef, useState } from "react";
import { addDays } from "date-fns";
import { Calendar } from "primereact/calendar";

import { ICONS } from "../../../../Assets/Icons";
import FilterByAmount from "./SearchComponents/filterByAmount/filterByAmount";
import FilterByOrder from "./SearchComponents/filterByOrder/filterByOrder";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const OrderSearchSection = (
  {
    amount,
    orderTypes,
    dateRange,
    setAmount,
    setOrderTypes,
    setDateRange,
    setSearch
  }
) => {

  const calendarRef = useRef(null);
  const [appendToElement, setAppendToElement] = useState(null);

  useEffect(() => {
    setAppendToElement(calendarRef.current);
  }, []);

  const [open, setOpen] = useState("");
  const [reset, setReset] = useState(false);
  const [searchData, setSearchData] = useState();
  const [dates, setDates] = useState([]);

  // const [dates, setDates] = useState([addDays(new Date(), -7), new Date()]);

  const handleFilterByCategoryClick = () => {
    open !== "filterByCategory" ? setOpen("filterByCategory") : setOpen(null);
  };

  const handleFilterByAmountClick = () => {
    open !== "filterByAmount" ? setOpen("filterByAmount") : setOpen(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleReset = () => {
    setDateRange([
      new Date("2020-03-11"),
      new Date("9999-01-01"),
    ]);
    setDates([]);
    setOrderTypes([]);
    setAmount([0, 10000]);
  };

  const changeDate = (e) => {
    setDates(e.target.value);
    setDateRange(e.target.value);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <div className={styles.left_container_data_one}>
            {ICONS.filterListIcon}
            <span>Filter by</span>
          </div>
          <div className={` ${styles.left_container_data_first} `}>
            <div ref={calendarRef}>
              <Calendar
                placeholder="Select dates"
                className={styles.calendar}
                inputClassName={styles.calendarInput}
                panelClassName={styles.pickerStyle}
                value={dates}
                onChange={changeDate}
                appendTo={appendToElement} // to make calendar child of its immdediate div
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
              />
            </div>
            <span style={{ display: "none" }}> {ICONS.expandMore}</span>
          </div>
          <div
            className={`${styles.left_container_data} ${styles.left_container_data_middle}`}
            onClick={handleFilterByCategoryClick}
          >
            <span>Order Category</span>
            <span
              className={
                open === "filterByCategory" ? styles.icon_rotate : styles.icon
              }
            >
              {ICONS.expandMore}
            </span>
            {open === "filterByCategory" && (
              <div
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "50px",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <FilterByOrder
                  onClose={handleClose}
                  setOrderTypes={setOrderTypes}
                  orderTypes={orderTypes}
                />
              </div>
            )}
          </div>
          <div
            className={`${styles.left_container_data} ${styles.left_container_data_middle}`}
            onClick={handleFilterByAmountClick}
          >
            <span>Amount</span>
            <span
              className={
                open === "filterByAmount" ? styles.icon_rotate : styles.icon
              }
            >
              {ICONS.expandMore}
            </span>

            {open === "filterByAmount" && (
              <div
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "50px",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <FilterByAmount
                  onClose={handleClose}
                  amount={amount}
                  setAmount={setAmount}
                />
              </div>
            )}
          </div>
          <div
            onClick={() => handleReset()}
            className={`${styles.left_container_data} ${styles.left_container_data_last}`}
          >
            <span className={reset ? styles.resetIcon : null}>
              {ICONS.deviceResetIcon}
            </span>
            <span>Reset Filters</span>
          </div>
        </div>
        {/* <div className={styles.search_bar_container}>
          <SearchBar
            height="46px"
            value={searchData}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search order no...."
          />
        </div> */}
      </div>
    </>
  );
};

export default OrderSearchSection;
