import Star from "./Star";

const StarRating = ({ rating, totalStars = 5,scale }) => {
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    let fill = 0;
    if (i <= rating) {
      fill = 1;
    } else if (i === Math.ceil(rating)) {
      fill = rating % 1;
    }

    stars.push(<Star key={i} fill={fill} scale={scale}/>);
  }

  return <div>{stars}</div>;
};

export default StarRating;
